import { showMessage } from "../koolio-workspace";
import { exportHighQualityAudio, showStatText } from "../utils";
import { castosSettings } from "./ApiIntegrationAuth";
import axios from "axios";
const castosPodcasts = "https://app.castos.com/api/v2/podcasts?token=";

export async function getUserPodcastsFromCastos() {
  let userPodcasts = [];
  let accessToken = castosSettings.accessToken;
  if (castosSettings.accessToken === "") {
    const apiKey = document.getElementById("api-key").value.trim();
    accessToken = apiKey;
  }
  await axios
    .get(castosPodcasts + accessToken)
    .then((res) => {
      const podcasts = res.data;
      userPodcasts = podcasts;
    })
    .catch((e) => {
      showMessage(
        "No podcast found!",
        "Sorry, couldn't find the podcast for user. Please try again later.",
        2000,
        "error",
        "more-options",
      );
    });
  return userPodcasts;
}

export async function publishAudioToCastos(
  showTitleOrPodcastID,
  episodeTitle,
  fileToUpload,
  episodeContent,
  options,
) {
  let podcastsIDs = [];
  let podcastsTitles = [];
  let podcastTitlePosition = 0;
  let podcastId = 0;
  const podcasts = await getUserPodcastsFromCastos();
  podcastsIDs = Object.keys(podcasts.data.podcast_list);
  podcastsTitles = Object.values(podcasts.data.podcast_list);
  if (
    podcastsIDs.length >= 1 &&
    podcastsTitles.length >= 1 &&
    podcastsIDs.length === podcastsTitles.length
  ) {
    podcastTitlePosition = podcastsTitles.indexOf(showTitleOrPodcastID);
    if (podcastTitlePosition === -1) {
      showStatText(
        "Sorry, your podcast title is wrong. Pleaser enter a valid one.",
      );
    } else {
      podcastId = podcastsIDs[podcastTitlePosition];
      await uploadhAudioToPodcastInCastos(
        podcastId,
        episodeTitle,
        fileToUpload,
        episodeContent,
        options,
      );
    }
  } else {
    showStatText(
      "You don't have any podcast to publish your episode. Please create one before publishing",
    );
  }
}
export async function uploadhAudioToPodcastInCastos(
  podcastId,
  episodeTitle,
  fileToUpload,
  episodeContent,
  options,
) {
  const publishInfo = {
    titleOrPodcastId: podcastId,
    episodeTitle,
    episodeContent,
    apiProvider: "castos",
  };
  return exportHighQualityAudio(options, false, true, publishInfo).then(
    (res) => {
      Promise.resolve(res.data);
    },
  );
}
export async function getCastosPodcastList() {
  const formattedPodcasts = [];
  const podcasts = await getUserPodcastsFromCastos();
  const podcastsIDs = Object.keys(podcasts.data.podcast_list);
  const podcastsTitles = Object.values(podcasts.data.podcast_list);

  if (
    podcastsIDs.length > 0 &&
    podcastsTitles.length > 0 &&
    podcastsIDs.length === podcastsTitles.length
  ) {
    for (let i = 0; i < podcastsIDs.length; i++) {
      const podcastObj = {
        id: podcastsIDs[i],
        title: podcastsTitles[i],
      };
      formattedPodcasts.push(podcastObj);
    }
  }
  castosSettings.shows = formattedPodcasts;
  return formattedPodcasts;
}
