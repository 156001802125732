import React from "react";
import "../../../../css/dark/navbar.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function helpbutton() {
  const helpClick = () => {
    // Implement your helpClick logic here
  };
  return (
    <div
      className="d-inline-block"
      onClick={helpClick}
      style={{ cursor: "pointer", position: "relative" }}
    >
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="help-tooltip">Help</Tooltip>}
      >
        <span className="circle-badge">
          <span>?</span>
        </span>
      </OverlayTrigger>
    </div>
  );
}
