import { createSlice } from "@reduxjs/toolkit";

const initatialFlag = {
  flagsList: [],
  flagShowModal: false,
  currFlagId: "",
};
const flagListSlice = createSlice({
  name: "flag",
  initialState: initatialFlag,
  reducers: {
    uploadFlagList(state, action) {
      state.flagsList = action.payload;
    },
    updateFlagShowModal(state, action) {
      state.flagShowModal = action.payload;
    },
    updateFlagId(state, action) {
      state.currFlagId = action.payload;
    },
  },
});
export const flagListActions = flagListSlice.actions;
export default flagListSlice.reducer;
