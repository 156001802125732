import React, { useEffect, useState } from "react";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import "../../../../css/dark/PublishModal.css"; // Import the CSS file
import { koolioModals } from "../../../../utils/Res";
import {
  apiProviderBuzzsprout,
  apiProviderCastos,
  apiProviderHeadliner,
  apiProviderPodbean,
  apiProviderSpreaker,
  apiProviderTransistor,
  buzzsproutSettings,
  castosSettings,
  getShows,
  loadShowsDropDown,
  podbeanSettings,
  spreakerSettings,
  transistorSettings,
} from "../../../../utils/publish-api/ApiIntegrationAuth";
import { createPortal } from "react-dom";
import store from "../../../../redux/Store";
import { closeExportToPodcastApiSliceActions } from "../../../../redux/slice/CloseExportToPodcastApiSlice";
import { downloadFromURL, isNativeExport } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import axios from "axios";
import { Koolioai, _config } from "../../../../utils/cognitoAuth";
import { SelectedApiProviderSliceActions } from "../../../../redux/slice/SelectedApiProviderSlice";
import { getFormat, setTitleList } from "../../../../utils/koolio-workspace";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
import { prepareModalForHeadliner } from "../../../../utils/publish-api/ApiHeadliner";
import $ from "jquery";

export let selectedFileName = "";
export let selectedFileFormat = "";
function PublishIndividualUIModal(props) {
  // for publish part
  const [apiProvider, setApiProvider] = useState("");
  const [podcastId, setPodcastId] = useState("");
  const [episodeTitle, setEpisodeTitle] = useState("");
  const [episodeContent, setEpisodeContent] = useState("");
  const [publishing, setPublishing] = useState(false);
  useBootstrapTooltips();
  useEffect(() => {
    setApiProvider(props.apiProvider);
    initiatePopUpForEpisodeInfo();
  }, []);

  function initiatePopUpForEpisodeInfo() {
    koolioModals();

    document
      .getElementById("podcast-id")
      .setAttribute("apiProvider", props.apiProvider);
    if (props.apiProvider === apiProviderBuzzsprout) {
      document.getElementById("api-header").innerHTML = "Buzzsprout Settings";
      // document.getElementById("publish-radio-holder").style.display = 'none'
      document.getElementById("episode-content").style.display = "none";
      document.getElementById("api-key").style.marginTop = "0px";
      document.getElementById("api-key").style.display = "block";
      document.getElementById("podcast-id").placeholder = "Enter Podcast ID";
      if (buzzsproutSettings.accessToken !== "") {
        document.getElementById("api-key").style.display = "none";
        document.getElementById("publish-api-logout").style.display = "block";
      }
      document.getElementById("podcast-id").style.background = "none";
      document.getElementById("podcast-id").readOnly = false;
      document.getElementById("showList").style.visibility = "hidden";
    } else if (props.apiProvider === apiProviderSpreaker) {
      document.getElementById("api-header").innerHTML = "Spreaker Settings";
      // document.getElementById("publish-radio-holder").style.display = 'none'
      document.getElementById("episode-content").style.display = "none";
      document.getElementById("api-key").style.display = "none";
      if (spreakerSettings.accessToken !== "") {
        document.getElementById("publish-api-logout").style.display = "block";
      }
      document.getElementById("podcast-id").style.marginTop = "0px";
      document.getElementById("podcast-id").placeholder = "Select Show Title";
      // document.getElementById("podcast-id").disabled = true;
      document.getElementById("episode-title").disabled = true;
      document.getElementById("episode-content").disabled = true;
    } else if (props.apiProvider === apiProviderCastos) {
      document.getElementById("api-header").innerHTML = "Castos Settings";
      // document.getElementById("publish-radio-holder").style.display = 'none'
      document.getElementById("episode-content").style.display = "block";
      document.getElementById("podcast-id").placeholder =
        "Select Podcast Title";
      document.getElementById("api-key").style.marginTop = "0px";
      document.getElementById("api-key").style.display = "block";
      if (castosSettings.accessToken !== "") {
        document.getElementById("api-key").style.display = "none";
        document.getElementById("publish-api-logout").style.display = "block";
      }
      // document.getElementById("podcast-id").disabled = true;
      document.getElementById("episode-title").disabled = true;
      document.getElementById("episode-content").disabled = true;
    } else if (props.apiProvider === apiProviderTransistor) {
      document.getElementById("api-header").innerHTML = "Transistor Settings";
      // document.getElementById("publish-radio-holder").style.display = 'none'
      document.getElementById("api-key").style.marginTop = "0px";
      document.getElementById("episode-content").style.display = "block";
      document.getElementById("api-key").style.display = "block";
      document.getElementById("podcast-id").placeholder = "Select Show Title";
      if (transistorSettings.accessToken !== "") {
        document.getElementById("api-key").style.display = "none";
        document.getElementById("publish-api-logout").style.display = "block";
      }
      // document.getElementById("podcast-id").disabled = true;
      document.getElementById("episode-title").disabled = true;
      document.getElementById("episode-content").disabled = true;
    } else if (props.apiProvider === apiProviderHeadliner) {
      prepareModalForHeadliner();
    } else if (props.apiProvider === apiProviderPodbean) {
      document.getElementById("api-header").innerHTML = "Podbean Settings";
      // document.getElementById("publish-radio-holder").style.display = 'none'
      document.getElementById("api-key").style.marginTop = "0px";
      document.getElementById("episode-content").style.display = "block";
      document.getElementById("api-key").style.display = "block";
      document.getElementById("podcast-id").placeholder = "Select Show Title";
      if (podbeanSettings.accessToken !== "") {
        document.getElementById("api-key").style.display = "none";
        document.getElementById("podcast-id").style.display = "none";
        document.getElementById("publish-api-logout").style.display = "block";
        document.getElementById("buzzError").style.display = "block";
      }
      // document.getElementById("podcast-id").disabled = true;
      document.getElementById("episode-title").disabled = false;
      document.getElementById("episode-content").disabled = false;
    }
  }

  function validatePodcastId() {
    let apiProvider = document
      .getElementById("podcast-id")
      .getAttribute("apiProvider");
    if (apiProvider === apiProviderBuzzsprout) {
      let input = document.getElementById("podcast-id").value;
      if (isNaN(input)) {
        document.getElementById("buzzError").style.visibility = "visible";
      } else {
        document.getElementById("buzzError").style.visibility = "hidden";
      }
    }
  }

  function handleEpisodeTitle(e) {
    setEpisodeTitle(e.target.value);
  }

  function handlePodcastId(e) {
    setPodcastId(e.target.value);
  }

  const publishProject = (event, apiProvider = "") => {
    store.dispatch(
      SelectedApiProviderSliceActions.updateIsNativeExport({
        isNativeExport: false,
      }),
    );

    if (apiProvider === "" && "btn-invoke-headliner" === event.target.id)
      apiProvider = apiProviderHeadliner;
    if (event) event.preventDefault();
    // const state = store.getState().exportOptionSlice.options;
    // const format = state.format;
    // const filename = state.filename.trim().replaceAll(" ", "_");
    // const bitDepthorRate = parseInt(
    //   document.getElementById(format + "-bit-depth").getAttribute("value"),
    // );
    // const sampleRate = state.sampleRate;
    // const channels = state.channels;
    // const normalize = state.normalize;
    // const options = {
    //   format,
    //   filename,
    //   sampleRate: parseInt(sampleRate),
    //   channels: parseInt(channels),
    //   normalize,
    // };
    // // selectedFileName = filename
    // // selectedFileFormat = format
    // if (format === "wav") options.bitDepth = bitDepthorRate;
    // else options.bitRate = bitDepthorRate;
    // if (apiProviderPodbean === apiProvider) {
    //   if (document.getElementById("wav").checked) options.format = "mp3";
    // } else options.format = "wav";
    const format = getFormat();
    const filename = document
      .getElementById("exportprojectname")
      .innerText.trim()
      .replaceAll(" ", "_");
    const bitDepthorRate = parseInt(
      document.getElementById(format + "-bit-depth").getAttribute("value"),
    );
    const sampleRate = document.getElementById(format + "-sample-rate").value;
    const channels = document.getElementById(format + "-channel").value;
    const normalize = $("#normalize-checkbox-uo").hasClass("checked");
    const options = {
      format,
      filename,
      sampleRate: parseInt(sampleRate),
      channels: parseInt(channels),
      normalize,
    };
    selectedFileName = filename;
    selectedFileFormat = format;
    if (format === "wav") options.bitDepth = bitDepthorRate;
    else options.bitRate = bitDepthorRate;
    if (apiProviderPodbean === apiProvider) {
      if (document.getElementById("wav").checked) options.format = "mp3";
    } else options.format = "wav";
    downloadFromURL("", options);
  };

  function showList() {
    var apiProvider = document
      .getElementById("podcast-id")
      .getAttribute("apiProvider");
    loadShowsDropDown(apiProvider)
      .then(function () {
        var list = getShows(apiProvider);
        if (list.length) {
          setTitleList(list, apiProvider);
          var screenHeight = window.screen.height;
          var screenWidth = window.screen.width;
          var showListMaxHeight =
            ((80 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
          document.getElementById("showList").style.maxHeight =
            showListMaxHeight;
          var showListFontSize =
            ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
          document.querySelector("#showList ul").style.fontSize =
            showListFontSize;
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }

  function canPublish() {
    let inputFields = document.querySelectorAll(
      ".half-modals-input-field .api-input",
    );

    let flag = false;

    inputFields.forEach(function (inputField) {
      if (inputField.style.display !== "none" && inputField.value) {
        flag = true;
      }
    });

    let publishButton = document.getElementById("publish-podcast-button");

    if (flag) {
      publishButton.style.opacity = "1";
    } else {
      publishButton.style.opacity = "0.4";
    }
  }

  function closeHalfModal() {
    props.onClose();
  }

  function closeExportToPodcastApiModal() {
    props.closeExportToPodcastApiModal();
  }

  function handleLogout() {
    publishApiLogout(props.apiProvider);
  }

  async function publishApiLogout(apiProvider) {
    document
      .getElementById("btn-publish-api-logout")
      .classList.add("btn-active");
    const params = {
      apiProvider,
      username: Koolioai.username,
    };
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        return axios
          .get(_config.api + "/api-logout", {
            params,
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              if (apiProvider === apiProviderBuzzsprout) {
                buzzsproutSettings.accessToken = "";
                document.getElementById("publish-api-logout").style.display =
                  "none";
                document.getElementById("api-key").style.display = "block";
              } else if (apiProvider === apiProviderSpreaker) {
                spreakerSettings.accessToken = "";
                spreakerSettings.shows = [];
                // closeExportToPodcastApiModal()
              } else if (apiProvider === apiProviderCastos) {
                castosSettings.accessToken = "";
                castosSettings.shows = [];
                document.getElementById("publish-api-logout").style.display =
                  "none";
                document.getElementById("api-key").style.display = "block";
              } else if (apiProvider === apiProviderTransistor) {
                transistorSettings.accessToken = "";
                transistorSettings.shows = [];
                document.getElementById("publish-api-logout").style.display =
                  "none";
                document.getElementById("api-key").style.display = "block";
              } else if (apiProvider === apiProviderPodbean) {
                podbeanSettings.accessToken = "";
                podbeanSettings.shows = [];
                // closeExportToPodcastApiModal()
              }
              document
                .getElementById("btn-publish-api-logout")
                .classList.remove("btn-active");
            } else {
              // console.log(res.data)
            }
          });
      },
    );
  }

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p style={{ margin: 0 }} id="api-header"></p>
            <img
              title="close"
              className="img-fluid"
              onClick={closeHalfModal}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div className="half-modals-sub-header"></div>
          <form
            onSubmit={(e) => {
              publishProject(e, props.apiProvider);
            }}
          >
            <div
              className="half-modals-sub-header mb-3 d-none"
              id="headliner-msg"
            >
              Preparing the file to publish. Please wait...
            </div>
            <div className="half-modals-input-field">
              <input
                className="api-input"
                type="text"
                id="api-key"
                placeholder="Enter API Key"
                onBlur={validatePodcastId}
                onInput={canPublish}
              />
              {/* <input className="api-input" type="text" id="podcast-id" placeholder="Enter podcast Key" onBlur={validatePodcastId} onInput={canPublish} /> */}
              <input
                className="api-input"
                type="text"
                id="podcast-id"
                readOnly
                onBlur={validatePodcastId}
                onClick={showList}
                autoComplete="off"
                onInput={canPublish}
                onChange={handlePodcastId}
                value={podcastId}
                placeholder="Select Podcast Title"
              />
              <span className="buzzError" id="buzzError">
                Please enter a valid Podcast ID
              </span>
              <div className="suggestion" id="showList">
                <ul className="searchList" id="showTitleList"></ul>
              </div>
              <input
                className="api-input"
                type="text"
                id="episode-title"
                placeholder="Enter Episode Title"
                onInput={handleEpisodeTitle}
                value={episodeTitle}
              />
              <input
                className="api-input"
                type="text"
                id="episode-content"
                placeholder="Enter Episode Content"
                onInput={(e) => setEpisodeContent(e.target.value)}
                value={episodeContent}
              />
            </div>
            <div
              className="d-none"
              id="btn-invoke-headliner"
              onClick={publishProject}
            ></div>
            <div className="headliner-share" id="btn-headliner"></div>

            <div className="half-modals-action">
              <div
                className="half-modals-actions text-center"
                id="publish-api-logout"
                style={{ display: "none" }}
              >
                <button
                  type="button"
                  id="btn-publish-api-logout"
                  onClick={handleLogout}
                  className="half-modals-action-button"
                >
                  Logout
                </button>
              </div>
              <div className="half-modals-actions text-center">
                <button
                  type="button"
                  onClick={closeExportToPodcastApiModal}
                  className="half-modals-action-button"
                  id="cancel-podcast-button"
                >
                  Cancel
                </button>
              </div>
              <div className="half-modals-actions text-center">
                <button
                  type="submit"
                  className="half-modals-action-button"
                  id="publish-podcast-button"
                  style={{ opacity: publishing ? 0.4 : 1 }}
                >
                  Publish
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default PublishIndividualUIModal;
