import { Koolioai, _config } from "../utils/cognitoAuth";
import axios from "axios";
import { editFeatureTitle, showStatText } from "../utils/utils";
import store from "../redux/Store";
import { shareAndCollabActions } from "../redux/slice/ShareAndCollableSlice";
import { OperationsCountHandlerSliceActions } from "../redux/slice/OperationsCountHandler";
import { retrieveAudioFileByJobname } from "../utils/indexedDButils";
import { ShowProjectModalActions } from "../redux/slice/ShowProjectShareModal";
import { showMessage } from "../utils/koolio-workspace";

export function shareProject(jobname, username, email, project_title) {
  const mode = document.getElementById("share-modes").innerText.toLowerCase();
  Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.post(
        _config.api + "/share-job",
        { jobname, username, email, mode, project_title },
        {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        },
      ),
    )
    .catch(Koolioai.handleError);
  if (username) {
    let message =
      "An invitation has been sent to " +
      username +
      "'s registered email address.";
    store.dispatch(
      ShowProjectModalActions.updateModalState({ showModal: true, message }),
    );
  } else {
    let message = "An invitation has been sent to " + email;
    store.dispatch(
      ShowProjectModalActions.updateModalState({ showModal: true, message }),
    );
  }

  // $('#half-modals').html(shareProjectModal)
  // koolioModals()
  // // document.getElementById('share-project-modal-header').innerHTML = 'Project shared with ' + username ? username : email
  // document.getElementById('share-project-modal-header').innerHTML = username ? 'An invitation has been sent to ' + username + '\'s registered email address.' : 'An invitation has been sent to ' + email
  // $('#half-modals').css('visibility', 'visible')
  // document.getElementById('share-email-list-msg').value = '';
}

export function getSharedUser() {
  return Promise.all([Koolioai.authToken()]).then((tokens) => {
    return axios
      .get(_config.api + "/shared-users-list", {
        headers: {
          Authorization: `${tokens[0]}`,
        },
        params: {
          username: Koolioai.username,
        },
      })
      .then((res) => Promise.resolve(res.data));
  });
}

export function checkIsTheProjectShared(jobname) {
  if (!jobname) {
    jobname = store.getState().projectData.resultStat.jobname;
  }
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/is-the-project-shared", {
          params: {
            jobname: jobname,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          if (response.data && response.data.shareholdersCount) {
            if (response.data.shareholdersCount > 1)
              store.dispatch(
                shareAndCollabActions.updateISProjectShared({
                  isTheProjectShared: true,
                }),
              );
            else
              store.dispatch(
                shareAndCollabActions.updateISProjectShared({
                  isTheProjectShared: false,
                }),
              );
          }
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export function lockFeatureForOtherCollaborators(featureToLock) {
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  const jobname = resultStat.jobname;

  const params = {
    jobname: jobname,
    username: Koolioai.username,
    featureToLock: featureToLock,
  };
  let shareAndCollab = state.shareAndCollab;
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .post(_config.api + "/handle-feature-lock-for-collaborators", params, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          if (response.data && response.data.lockedBy) {
            if (Koolioai.username !== response.data.lockedBy) {
              store.dispatch(
                shareAndCollabActions.updateEditFeatureLockedBy({
                  editFeatureLockedBy: response.data.lockedBy,
                }),
              );
              store.dispatch(
                shareAndCollabActions.updateEditFeatureEnable({
                  isEditFeatureEnabled: false,
                }),
              );
              if (shareAndCollab.editFeatureLockedBy.startsWith("google_")) {
                const url = _config.api + "/get-email";
                const params = {
                  username: shareAndCollab.editFeatureLockedBy,
                };
                const response = axios.get(url, {
                  params,
                  headers: {
                    "Content-Type": "application/json",
                  },
                });

                if (response.status === 200) {
                  store.dispatch(
                    shareAndCollabActions.updateEditFeatureLockedBy({
                      editFeatureLockedBy: response.data.email.split("@")[0],
                    }),
                  );
                }
              }
              const message =
                "Another collaborator, " +
                shareAndCollab.editFeatureLockedBy +
                ", is currently working on the project. Please wait or request access from them to edit";
              showMessage(
                "Edit mode disabled",
                message,
                8000,
                "error",
                "workspace",
              );
            } else {
              store.dispatch(
                shareAndCollabActions.updateEditFeatureLockedBy({
                  editFeatureLockedBy: "",
                }),
              );
              store.dispatch(
                shareAndCollabActions.updateEditFeatureEnable({
                  isEditFeatureEnabled: true,
                }),
              );
            }
          }
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export async function getLiveShareholders(jobname, username, type) {
  // type should be either LIST or LIVE
  let params = {
    jobname,
    username,
    type,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/share-job", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then(async (res) => {
          showStatText("", false);
          return Promise.resolve(res.data);
        })
        .catch((e) => {
          //showStatText('Sorry! could not get the collaborators.', false)
          return Promise.reject(e);
        });
    },
  );
}

export function updateOnlineOfflineStatus(isOnline) {
  let status = "offline";
  if (isOnline) status = "online";

  const jobname = store.getState().projectData.resultStat.jobname;

  const params = {
    jobname: jobname,
    username: Koolioai.username,
    isOnline: status,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .post(_config.api + "/setting-user-online-offline-status", params, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export async function getOperationCount(jobname) {
  // const jobname = store.getState().projectData.resultStat.jobname;
  const params = {
    jobname,
    username: Koolioai.username,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/operations", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((res) => {
          const responseObj = res.data;
          if (responseObj.count)
            store.dispatch(
              OperationsCountHandlerSliceActions.updateTotalOperation({
                totalOperation: responseObj.count,
              }),
            );
        })
        .catch((e) => {
          // console.error(e)
        });
    },
  );
}

export async function setModeDependingOnFeatureLockStatus(featureTitle) {
  getFeatureLockData(editFeatureTitle)
    .then((data) => {
      if (data.lockedFeature === featureTitle) {
        if (data.lockStatus === "unlocked") {
          store.dispatch(
            shareAndCollabActions.updateEditFeatureLockedBy({
              editFeatureLockedBy: "",
            }),
          );
          store.dispatch(
            shareAndCollabActions.updateEditFeatureEnable({
              isEditFeatureEnabled: true,
            }),
          );
          showStatText("edit mode is enabled");
        } else {
          store.dispatch(
            shareAndCollabActions.updateEditFeatureLockedBy({
              editFeatureLockedBy: data.lockedBy,
            }),
          );
          store.dispatch(
            shareAndCollabActions.updateEditFeatureEnable({
              isEditFeatureEnabled: false,
            }),
          );
          showStatText("edit mode restricted");
        }
      }
      return Promise.resolve(data);
    })
    .catch((e) => {
      store.dispatch(
        shareAndCollabActions.updateEditFeatureEnable({
          isEditFeatureEnabled: false,
        }),
      );
      return Promise.reject(e);
    });
}

export async function getFeatureLockData(featureToCheck) {
  const jobname = store.getState().projectData.resultStat.jobname;

  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/handle-feature-lock-for-collaborators", {
          params: {
            jobname: jobname,
            username: Koolioai.username,
            featureToLockCheck: featureToCheck,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export function unlockFeatureForOtherCollaborators(featureToUnlock) {
  const jobname = store.getState().projectData.resultStat.jobname;

  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .delete(_config.api + "/handle-feature-lock-for-collaborators", {
          data: {
            jobname: jobname,
            username: Koolioai.username,
            featureToUnlock: featureToUnlock,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

export async function checkTimeoutOfFeatureLockData(featureToCheck) {
  const jobname = store.getState().projectData.resultStat.jobname;

  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/check-feature-lock-timeout", {
          params: {
            jobname: jobname,
            username: Koolioai.username,
            featureToLockCheck: featureToCheck,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}
