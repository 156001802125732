import { createSlice } from "@reduxjs/toolkit";
const initialProjectData = {
  resultStat: {},
  projectLoadedFromKooliospace: false,
};

const projectDataSlice = createSlice({
  name: "projectData",
  initialState: initialProjectData,
  reducers: {
    projectReducerData(state, action) {
      state.resultStat = action.payload.resultStat;
      console.trace(state.resultStat);
    },
    updateAnnotaion(state, action) {
      state.resultStat.speakers_segments = action.payload.playlist;
    },
    updateAnnotationsforCut(state, action) {
      state.resultStat.speakers_segments[0].annotations =
        action.payload.newAnnotations;
    },
    updateProjectLoadedFromKooliospace(state, action) {
      state.projectLoadedFromKooliospace =
        action.payload.projectLoadedFromKooliospace;
    },
    clearProjectReducerData(state) {
      state.resultStat = "";
    },
  },
});

export const projectDataSliceActions = projectDataSlice.actions;
export default projectDataSlice.reducer;
