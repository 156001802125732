import React, { useEffect } from "react";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { createPortal } from "react-dom";
import { koolioModals } from "../../../../utils/Res";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

const ProjectShareModal = (props) => {
  useBootstrapTooltips();
  useEffect(() => {
    koolioModals();
  }, []);
  const handleClose = () => {
    return props.onClose();
  };

  return createPortal(
    <div id="half-modals-container">
      <div className="half-modals-content" id="half-modals-content">
        <div className="half-modals-header">
          <p style={{ margin: 0 }} id="share-project-modal-header">
            {props.message}
          </p>
          <img
            title="close"
            className="img-fluid"
            onClick={handleClose}
            id="closeWorkspaceModalIcon"
            style={{ cursor: "pointer" }}
            src="/image/exit-workspace.svg"
            alt=""
            width="13px"
          />
        </div>
        <div
          className="half-modals-sub-header"
          id="share-project-modal-sub-header"
        ></div>
        <div className="half-modals-action">
          <div className="half-modals-actions text-center">
            <button
              type="button"
              onClick={handleClose}
              className="half-modals-action-button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body, // Ensure the correct target element for the portal
  );
};

export default ProjectShareModal;
