import React, { useEffect } from "react";
import "../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { koolioModals } from "../../utils/Res";
import { createPortal } from "react-dom";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

export default function DeleteModal({ projectName, onClose, onDeleteYes }) {
  useEffect(() => {
    koolioModals();
  }, []);
  useBootstrapTooltips();
  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="full-modals-content" id="full-modals-content">
          <div className="close-btn-container">
            <img
              title="close"
              className="img-fluid top-right-button"
              onClick={onClose}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="18px"
            />
          </div>

          <div className="full-modals-actions">
            <p className="full-modal-title">{projectName} will be deleted?</p>
            <div className="full-modals-button-section">
              <button
                type="button"
                onClick={onDeleteYes}
                className="full-modal-btn"
              >
                Delete
              </button>
              <button
                type="button"
                onClick={onClose}
                className="full-modal-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}
