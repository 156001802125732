import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import "../../css/dark/SfxLengthModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

const GenaiLengthModal = ({ sfxcloseModal, env, setGenaiLengthFunction }) => {
  useBootstrapTooltips();
  const [length, setLength] = useState(4.0);
  const [inputValue, setInputValue] = useState(4.0);
  const handleInputChange = (value) => {
    setInputValue(value);
    setLength(value);
  };

  const handleRangeChange = (value) => {
    setLength(value);
    setInputValue(value);
  };

  const handleSetLength = () => {
    setGenaiLengthFunction(length);
  };

  return createPortal(
    <>
      <div className="sfxLength-wrapper" onClick={sfxcloseModal}></div>
      <div className="sfxLength-container">
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p>
              {env === "sfx"
                ? "Generative SFX Length Setting"
                : "Generative MUSIC Length Setting"}
            </p>
            <img
              title="close"
              className="img-fluid"
              onClick={sfxcloseModal}
              id="closeWorkspaceModalIcon"
              src="/image/exit-workspace.svg"
              alt=""
            />
          </div>

          <div className="half-modals-speaker">
            Length in seconds:
            <input
              type="number"
              className="sfx-min-length sfx-length-input"
              min="1.00"
              max="100.00"
              step=".01"
              id="sfx-min-length"
              value={inputValue}
              onInput={(e) => handleInputChange(e.target.value)}
              onChange={() => {}}
            />
            <input
              id="len-control"
              className="sfxLength-slider"
              type="range"
              min="1"
              max="30"
              value={length}
              title="GenAi SFX/Music Length"
              step=".01"
              onInput={(e) => handleRangeChange(e.target.value)}
              onChange={() => {}}
            />
            <span className="sfx-max-length" id="sfx-max-length">
              {30}
            </span>
          </div>

          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="sfx-length-button"
                className="half-modals-action-button"
                onClick={handleSetLength}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body,
  );
};

export default GenaiLengthModal;
