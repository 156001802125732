import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../../css/dark/TranscriptionModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
// import "../../css/dark/ShowNotesModal.css";
// import { koolioModals } from "../../utils/Res";

function MultiselectReplaceModal({
  searchWord,
  replaceWord,
  onClose,
  onReplaceYes,
}) {
  // useEffect(() => {
  //   koolioModals();
  // }, []);
  useBootstrapTooltips();
  return createPortal(
    <>
      {/* Use the same classname as the transcription modal because both are same just different content. */}
      <div className="transcriptionModal-wrapper">
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p style={{ margin: 0 }} id="annotation-modal-header">
              Do you want to replace all
              <span
                id="replace-select-word"
                style={{ color: "#E2522B", margin: 0 }}
              >
                {" " + searchWord}
              </span>
              <span style={{ margin: 0 }}> with </span>
              <span id="replace-word" style={{ color: "#E2522B", margin: 0 }}>
                {replaceWord}
              </span>
              <span style={{ margin: 0 }}>? </span>
            </p>
            <img
              title="close"
              className="img-fluid"
              onClick={onClose}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                onClick={onReplaceYes}
                className="half-modals-action-button"
              >
                Yes
              </button>
            </div>
            <div className="half-modals-actions text-center">
              <button
                type="button"
                onClick={onClose}
                className="half-modals-action-button"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default MultiselectReplaceModal;
