import axios from "axios";
import { Koolioai, _config } from "../cognitoAuth";
import { showStatText } from "../utils";
import { getCastosPodcastList, publishAudioToCastos } from "./ApiCastos";
import { getSpreakerShowList, publishAudioToSpreaker } from "./ApiSpreaker";
import {
  getUserShowsFromTransistor,
  publishAudioToTransistor,
} from "./ApiTransistor";
import { setTitleList, showMessage } from "../koolio-workspace";
import store from "../../redux/Store";
import { closeExportToPodcastApiSliceActions } from "../../redux/slice/CloseExportToPodcastApiSlice";
import { publishAudioToBuzzsprout } from "./ApiBuzzsprout";
import { initiatePopUpForEpisodeSliceActions } from "../../redux/slice/InitiatePopUpForEpisodeInfoSlice";
import { publishAudioToPodbean } from "./ApiPodbean";
import { publishAudioToHeadliner } from "./ApiHeadliner";
export const apiProviderSpreaker = "spreaker";
export const apiProviderBuzzsprout = "buzzsprout";
export const apiProviderHeadliner = "headliner";
export const apiProviderBlubrry = "blubrry";
export const apiProviderPodbean = "podbean";
export const apiProviderCastos = "castos";
export const apiProviderTransistor = "transistor";

export let noSpreakerShow = false;
let API_ENV = "";
if (
  window.location.host.includes("localhost") ||
  window.location.host.includes("127.0.0.1")
) {
  API_ENV = "http://" + window.location.host + "/app.koolio.ai";
} else if (
  window.location.host.includes("development") ||
  window.location.host.includes("qa")
) {
  API_ENV = "https://" + window.location.host.split(".")[0] + ".app.koolio.ai";
} else {
  API_ENV = "https://app.koolio.ai";
}

export const spreakerSettings = {
  baseUrl: "https://www.spreaker.com/oauth2/authorize?",
  redirectUri: API_ENV + "/redirect.html", // 'https://development.app.koolio.ai/redirect.html'
  clientId: "2803", // '2672',
  responseType: "code",
  state: "koolio-spreaker-integration",
  scope: "basic",
  accessToken: "",
  code: "",
  shows: [],
  getAuthUrl() {
    return (
      this.baseUrl +
      "client_id=" +
      this.clientId +
      "&response_type=" +
      this.responseType +
      "&state=" +
      this.state +
      "&scope=" +
      this.scope +
      "&redirect_uri=" +
      this.redirectUri
    );
  },
};

export const blubrrySettings = {
  baseUrl: "https://api.blubrry.com/oauth2/authorize?",
  redirectUri: API_ENV + "/redirect.html", // 'https://development.app.koolio.ai/redirect.html'
  clientId: "3123646787",
  responseType: "code",
  state: "random",
  accessToken: "",
  getAuthUrl() {
    return (
      this.baseUrl +
      "client_id=" +
      this.clientId +
      "&response_type=" +
      this.responseType +
      "&state=" +
      this.state +
      "&redirect_uri=" +
      this.redirectUri
    );
  },
};

export const podbeanSettings = {
  baseUrl: "https://api.podbean.com/v1/dialog/oauth?",
  redirectUri: API_ENV + "/redirect.html", // 'https://development.app.koolio.ai',
  clientId: "862e101472a1b615e3adb",
  responseType: "code",
  state: "koolio-podbean-integration",
  scope: "podcast_read+podcast_update+episode_publish+episode_read",
  accessToken: "",
  code: "",
  shows: [],
  getAuthUrl() {
    return (
      this.baseUrl +
      "client_id=" +
      this.clientId +
      "&response_type=" +
      this.responseType +
      "&scope=" +
      this.scope +
      "&redirect_uri=" +
      this.redirectUri
    );
  },
};

export const buzzsproutSettings = {
  baseUrl: "https://www.buzzsprout.com/api/",
  podcastId: "",
  accessToken: "",
  postUrl: function () {
    return (
      this.baseUrl +
      this.podcastId +
      "/episodes.json?api_token=" +
      this.accessToken
    );
  },
};

export const castosSettings = {
  baseUrl: "https://app.castos.com/api/v2/podcasts/",
  podcastId: "",
  accessToken: "",
  shows: [],
  postUrl: function () {
    return (
      this.baseUrl +
      this.podcastId +
      "/episodes.json?api_token=" +
      this.accessToken
    );
  },
};
export const transistorSettings = {
  baseUrl: "https://api.transistor.fm/v1/",
  accessToken: "",
  shows: [],
};

export let authenticationWindow = null;

export function initiateLoginInApiProvider(apiProvider) {
  switch (apiProvider.toLowerCase()) {
    case apiProviderSpreaker:
      loginIntoApiProvider(spreakerSettings.getAuthUrl(), "spreaker-auth");
      break;
    case apiProviderBlubrry:
      loginIntoApiProvider(blubrrySettings.getAuthUrl(), "blubrry-auth");
      break;
    case apiProviderPodbean:
      loginIntoApiProvider(podbeanSettings.getAuthUrl(), "podbean-auth");
      break;
    case apiProviderHeadliner:
      break;
    default:
      showStatText(
        "Sorry, the platform you are choosing to export your audio, isn't integrated yet.",
      );
  }
}

export async function startPublishingProcess(apiProvider) {
  await getApiAccessToken(apiProvider, "");
}

export function setAccessToken(apiProvider, accessToken) {
  switch (apiProvider.toLowerCase()) {
    case apiProviderSpreaker:
      spreakerSettings.accessToken = accessToken;
      break;
    case apiProviderBlubrry:
      blubrrySettings.accessToken = accessToken;
      break;
    case apiProviderPodbean:
      podbeanSettings.accessToken = accessToken;
      break;
    case apiProviderBuzzsprout:
      buzzsproutSettings.accessToken = accessToken;
      break;
    case apiProviderCastos:
      castosSettings.accessToken = accessToken;
      break;
    case apiProviderTransistor:
      transistorSettings.accessToken = accessToken;
      break;
    case apiProviderHeadliner:
      break;
    default:
      showStatText(
        "Sorry, the platform you are choosing to export your audio, isn't integrated yet.",
      );
  }
  return Promise.resolve();
}

export async function getApiAccessToken(apiProvider, code) {
  let params;
  if (code === undefined || code === "") {
    params = {
      provider: apiProvider,
    };
  } else {
    params = {
      provider: apiProvider,
      code,
    };
  }
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/api-integration", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((res) => {
          const responseObj = res.data;
          if (
            responseObj.status === 200 ||
            (responseObj.access_token !== undefined &&
              responseObj.access_token !== "")
          ) {
            return setAccessToken(apiProvider, responseObj.access_token).then(
              () => {
                if (
                  authenticationWindow !== undefined &&
                  authenticationWindow !== null
                ) {
                  authenticationWindow.close();
                }
                if (
                  apiProvider === apiProviderTransistor ||
                  apiProvider === apiProviderCastos ||
                  apiProvider === apiProviderSpreaker
                ) {
                  return loadShowsDropDown(apiProvider).then(() => {
                    // Open up the form to take user input.
                    store.dispatch(
                      initiatePopUpForEpisodeSliceActions.updateApiProvider({
                        apiProvider: apiProvider,
                      }),
                    );
                    // initiatePopUpForEpisodeInfo(apiProvider)
                    const shows = getShows(apiProvider);
                    setTitleList(shows, apiProvider);
                    return Promise.resolve();
                  });
                } else {
                  // Open up the form to take user input.
                  store.dispatch(
                    initiatePopUpForEpisodeSliceActions.updateApiProvider({
                      apiProvider: apiProvider,
                    }),
                  );
                  // initiatePopUpForEpisodeInfo(apiProvider)
                  return Promise.resolve();
                }
              },
            );
          } else if (responseObj.status === 404) {
            if (
              apiProvider === apiProviderBuzzsprout ||
              apiProvider === apiProviderCastos ||
              apiProvider === apiProviderTransistor
            ) {
              // Open up the form to take user input.
              store.dispatch(
                initiatePopUpForEpisodeSliceActions.updateApiProvider({
                  apiProvider: apiProvider,
                }),
              );
              // initiatePopUpForEpisodeInfo(apiProvider)
            } else {
              showStatText(
                "authenticating user in " + apiProvider + ", please wait...",
              );
              initiateLoginInApiProvider(apiProvider);
            }
          } else if (responseObj.status === 502) {
            showStatText(
              "Sorry, code expired for " +
                apiProvider +
                ". Please try again later.",
            );
          } else {
            showStatText(
              "Sorry, retrieving access token for " + apiProvider + " failed.",
            );
          }
        })
        .catch((e) => {
          // console.error(e)
        });
    },
  );
}

export function publishApiLogout(apiProvider) {
  // $('#btn-publish-api-logout').addClass('btn-active')
  document.getElementById("btn-publish-api-logout").classList.add("btn-active");
  const params = {
    apiProvider,
    username: Koolioai.username,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .get(_config.api + "/api-logout", {
          params,
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (apiProvider === apiProviderBuzzsprout) {
              buzzsproutSettings.accessToken = "";
              document.getElementById("publish-api-logout").style.display =
                "none";
              document.getElementById("api-key").style.display = "block";
            } else if (apiProvider === apiProviderSpreaker) {
              spreakerSettings.accessToken = "";
              spreakerSettings.shows = [];
              store.dispatch(
                closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
                  value: true,
                }),
              );
            } else if (apiProvider === apiProviderCastos) {
              castosSettings.accessToken = "";
              castosSettings.shows = [];
              document.getElementById("publish-api-logout").style.display =
                "none";
              document.getElementById("api-key").style.display = "block";
            } else if (apiProvider === apiProviderTransistor) {
              transistorSettings.accessToken = "";
              transistorSettings.shows = [];
              document.getElementById("publish-api-logout").style.display =
                "none";
              document.getElementById("api-key").style.display = "block";
            } else if (apiProvider === apiProviderPodbean) {
              podbeanSettings.accessToken = "";
              podbeanSettings.shows = [];
              // closeExportToPodcastApiModal()
              store.dispatch(
                closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
                  value: true,
                }),
              );
            }
            // $('#btn-publish-api-logout').removeClass('btn-active')
            document
              .getElementById("btn-publish-api-logout")
              .classList.remove("btn-active");
          }
        });
    },
  );
}
const handleAuth = () => {
  const url = window.location.href;
  let apiProvider = "";

  if (window.name.includes("spreaker-auth")) {
    apiProvider = "spreaker";
  } else if (window.name.includes("blubrry-auth")) {
    apiProvider = "blubrry";
  } else if (window.name.includes("podbean-auth")) {
    apiProvider = "podbean";
  }
  if (apiProvider === "") return;
  const code = getToken(url, apiProvider);
  if (code) {
    window.sendTokenToBackend = sendTokenToBackend(code, apiProvider);
    // setToken(code); // Store the token in state
    // Assuming opener exists and sendTokenToBackend is a valid function
    if (
      window.opener &&
      typeof window.opener.sendTokenToBackend === "function"
    ) {
      window.opener.sendTokenToBackend(code, apiProvider);
    }
  }

  // Close the current window
  window.close();
};
window.onload = handleAuth();
const parseParams = (str) => {
  const params = new URLSearchParams(str.split("?")[1]);
  return Object.fromEntries(params);
};

const getToken = (url, apiProvider) => {
  const params = parseParams(url);
  // Handle extracting the token based on API provider logic
  switch (apiProvider) {
    case "spreaker":
      return params.code || null;
    case "blubrry":
      return params.token || null;
    case "podbean":
      return params.access_token || null;
    default:
      return null;
  }
};

export function loginIntoApiProvider(url, target) {
  authenticationWindow = window.open(url, target, "width=400,height=400");
  setTimeout(() => {
    if (
      !authenticationWindow ||
      authenticationWindow.closed ||
      typeof authenticationWindow.closed === "undefined"
    ) {
      showMessage(
        "Popup blocked!",
        "Please allow pop ups on your browser to Authorize Spreaker",
        10000,
        "error",
        "more-options",
      );
    } else {
      showMessage(
        "Authorizing",
        "Please wait...",
        5000,
        "success",
        "more-options",
      );
    }
  }, 100);
}

export async function sendTokenToBackend(code, apiProvider) {
  if (code !== undefined || code !== "") {
    // Initiate the getApiAccessToken process.
    switch (apiProvider.toLowerCase()) {
      case apiProviderSpreaker:
        spreakerSettings.code = code;
        await getApiAccessToken(apiProvider, code);
        break;
      case apiProviderBlubrry:
        blubrrySettings.code = code;
        await getApiAccessToken(apiProvider, code);
        break;
      case apiProviderPodbean:
        podbeanSettings.code = code;
        await getApiAccessToken(apiProvider, code);
        break;
      case apiProviderBuzzsprout:
        break;
      case apiProviderHeadliner:
        break;
      default:
        showStatText("Sorry, couldn't retrieve the token.");
    }
  }
}
// call this method in form submit.
export async function publishAudio(
  apiProvider,
  showTitleOrPodcastID,
  episodeTitle,
  fileToUpload,
  apiKey,
  episodeContent,
  options,
) {
  if (
    document.getElementById("publish-podcast-button").style.opacity < 1 &&
    apiProvider !== apiProviderHeadliner
  )
    return false;
  switch (apiProvider.toLowerCase()) {
    case apiProviderSpreaker:
      if (spreakerSettings.accessToken !== "") {
        store.dispatch(
          closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
            value: true,
          }),
        );
        await publishAudioToSpreaker(
          showTitleOrPodcastID,
          episodeTitle,
          fileToUpload,
          options,
        );
      }
      break;
    // case apiProviderBlubrry:
    //     if (blubrrySettings.accessToken !== '') {
    //         // await publishAudioToBlubrry(showTitleOrPodcastID, episodeTitle, fileToUpload)
    //     }
    // break
    case apiProviderPodbean:
      if (podbeanSettings.accessToken !== "") {
        store.dispatch(
          closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
            value: true,
          }),
        );
        await publishAudioToPodbean(
          episodeTitle,
          episodeContent,
          fileToUpload,
          options,
        );
      }
      break;
    case apiProviderBuzzsprout:
      if (buzzsproutSettings.accessToken === "") {
        getApiAccessToken(apiProviderBuzzsprout, apiKey)
          .then(async () => {
            // call method to publish episode in buzz
            store.dispatch(
              closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
                value: true,
              }),
            );
            await publishAudioToBuzzsprout(
              showTitleOrPodcastID,
              episodeTitle,
              fileToUpload,
              options,
            );
          })
          .catch((e) => {
            // console.error(e)
            showStatText("Sorry, couldn't publish the file.");
          });
      } else {
        // call method to publish episode in buzz
        store.dispatch(
          closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
            value: true,
          }),
        );
        await publishAudioToBuzzsprout(
          showTitleOrPodcastID,
          episodeTitle,
          fileToUpload,
          options,
        );
      }
      break;
    case apiProviderTransistor:
      if (transistorSettings.accessToken === "") {
        getApiAccessToken(apiProviderTransistor, apiKey)
          .then(() => {
            // call method to publish episode in buzz
            store.dispatch(
              closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
                value: true,
              }),
            );
            publishAudioToTransistor(
              apiKey,
              showTitleOrPodcastID,
              episodeTitle,
              fileToUpload,
              episodeContent,
              options,
            );
          })
          .catch((e) => {
            // console.error(e)
            showStatText("Sorry, couldn't publish the file.");
          });
      } else {
        // call method to publish episode in buzz
        store.dispatch(
          closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
            value: true,
          }),
        );
        await publishAudioToTransistor(
          apiKey,
          showTitleOrPodcastID,
          episodeTitle,
          fileToUpload,
          episodeContent,
          options,
        );
      }
      break;
    case apiProviderCastos:
      if (castosSettings.accessToken === "") {
        getApiAccessToken(apiProviderCastos, apiKey)
          .then(() => {
            // call method to publish episode in buzz
            store.dispatch(
              closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
                value: true,
              }),
            );
            publishAudioToCastos(
              showTitleOrPodcastID,
              episodeTitle,
              fileToUpload,
              episodeContent,
              options,
            );
          })
          .catch((e) => {
            // console.error(e)
            showStatText("Sorry, couldn't publish the file.");
          });
      } else {
        // call method to publish episode in buzz
        store.dispatch(
          closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
            value: true,
          }),
        );
        await publishAudioToCastos(
          showTitleOrPodcastID,
          episodeTitle,
          fileToUpload,
          episodeContent,
          options,
        );
      }
      break;
    case apiProviderHeadliner:
      await publishAudioToHeadliner(fileToUpload, options);
      break;
  }
}

export async function loadShowsDropDown(apiProvider) {
  switch (apiProvider.toLowerCase()) {
    case apiProviderSpreaker:
      await getSpreakerShowList();
      break;
    case apiProviderCastos:
      await getCastosPodcastList();
      break;
    case apiProviderTransistor:
      await getUserShowsFromTransistor();
      break;
  }
}

export function getShows(apiProvider) {
  switch (apiProvider.toLowerCase()) {
    case apiProviderSpreaker:
      return spreakerSettings.shows;
    case apiProviderCastos:
      return castosSettings.shows;
    case apiProviderTransistor:
      return transistorSettings.shows;
    case apiProviderPodbean:
      return podbeanSettings.shows;
    default:
      return [];
  }
}

// export function canPublish () {
//     let inputFields = $('.half-modals-input-field .api-input')
//     inputFields = inputFields.filter(item => inputFields[item].style.display !== 'none')

//     let flag = false

//     for (let i = 0; i < inputFields.length; i++) inputFields[i].value ? flag = true : flag = false
//     flag ? $('#publish-podcast-button').css({ opacity: '1' }) : $('#publish-podcast-button').css({ opacity: '0.4' })
// }
