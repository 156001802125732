import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../../css/dark/TranscriptionModal.css";
import "../../css/dark/ShowNotesModal.css";
import { koolioModals } from "../../utils/Res";
import { useSelector } from "react-redux";
import { create_UUID, loadSlickSliders, restThings } from "../../utils/utils";
import store from "../../redux/Store";
import { showMessage } from "../../utils/koolio-workspace";

function UpdateSpeakerModal(props) {
  let resultstat = { ...store.getState().projectData.resultStat };
  const trackNumber = useSelector(
    (state) => state.speakerBtnClicked.speakerType,
  );
  const [trackName, setTrackName] = useState(
    trackNumber.split("-")[0] == "sfx"
      ? resultstat.sfx[trackNumber].name
      : resultstat.speakers[trackNumber].name,
  );
  useEffect(() => {
    koolioModals();
  }, []);
  function deleteTrack() {
    if (Object.keys(resultstat.speakers).length === 1) {
      showMessage(
        "Operation restricted!",
        "cannot delete last remainig track.",
        2000,
        "error",
        "workspace",
      );
      return;
    }
    props.handleCut(null, null, null, trackNumber);
    props.onClose();
    document.getElementById(`${trackNumber}-slider`).remove();
  }
  function handleTrackNameChange(e) {
    setTrackName(e.target.value);
  }

  function saveChanges() {
    let key = trackNumber;
    let operationId = create_UUID();
    if (trackNumber.split("-")[0] === "speakers")
      resultstat = {
        ...resultstat,
        speakers: {
          ...resultstat.speakers,
          [key]: {
            ...resultstat.speakers[key],
            name: trackName,
          },
        },
      };
    else
      resultstat = {
        ...resultstat,
        sfx: {
          ...resultstat.sfx,
          [key]: {
            ...resultstat.sfx[key],
            name: trackName,
          },
        },
      };
    let params = {
      jobname: resultstat.jobname,
      refid: operationId,
      parentRefid: resultstat.refid,
      worker: "editTrackInfo",
      inputs: {
        customClass: key,
        imgURL:
          "https://ui-avatars.com/api/?name=" +
          trackName +
          "&background=BB417C&rounded=true&size=31&color=fff",
        name: trackName,
      },
    };
    resultstat.refid = operationId;
    loadSlickSliders(resultstat);
    restThings(params, resultstat);

    props.onClose();
  }
  return createPortal(
    <>
      <div className="transcriptionModal-wrapper">
        <div
          className="half-modals-content"
          style={{ height: "25vh" }}
          id="half-modals-content"
        >
          <div className="half-modals-header">
            <p style={{ margin: 0 }}>Track Settings</p>
            <img
              title="close"
              className="img-fluid"
              onClick={props.onClose}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>

          <div className="half-modals-speaker">
            Track name:
            <input
              className="speaker-input"
              type="text"
              id="speaker-name"
              value={trackName}
              onChange={handleTrackNameChange}
              placeholder="Enter track name"
              autoComplete="off"
            />
          </div>

          <div className="half-modals-action">
            {trackNumber.split("-")[0] === "speakers" && (
              <div className="half-modals-actions text-center">
                <button
                  type="button"
                  id="confirm-delete-all-segments-button"
                  className="half-modals-action-button"
                  onClick={deleteTrack}
                >
                  Delete
                </button>
              </div>
            )}
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="update-speaker-name-button"
                className="half-modals-action-button"
                onClick={saveChanges}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
}

export default UpdateSpeakerModal;
