import { useNavigate } from "react-router-dom";
import "../../../../css/dark/navbar.css";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch } from "react-redux";
import { recordSpaceBtnActions } from "../../../../redux/slice/RecordspaceBtnTriggerSlice";
import { multiSelectActions } from "../../../../redux/slice/MultiselectSlice";
import { multiSelectWordActions } from "../../../../redux/slice/MultiSelectWordList";
import { projectDataSliceActions } from "../../../../redux/slice/ProjectDataSlice";
import { applyOperationsInBackend } from "../../../../services/globalServices";
import {
  clearBackendApplyScheduler,
  clearOperationCountHandlerScheduler,
  editFeatureTitle,
} from "../../../../utils/utils";
import {
  getKSFromLocal,
  hideplaylistloader,
  showplaylistloader,
  updateKooliospace,
} from "../../kooliospace/kooliospaceUtils";
import store from "../../../../redux/Store";
import { shareAndCollabActions } from "../../../../redux/slice/ShareAndCollableSlice";
import {
  updateIndexDBoperationsData,
  updateResultstatByJobname,
} from "../../../../utils/indexedDButils";
import { unlockFeatureForOtherCollaborators } from "../../../../services/ShareAndCollabeServices";
import { projectTitleSliceActions } from "../../../../redux/slice/ProjectTitleSlice";
import { frontendApplySliceActions } from "../../../../redux/slice/FrontendApplySlice";

export default function RightPanel(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileModal = (event) => {
    // Handle user profile modal event
  };
  const closeKoolioWorkspace = async () => {
    if (props.env === "workspace") {
      dispatch(
        recordSpaceBtnActions.storeBtnClickedEnv({
          env: "recordspaceToWorkspace",
        }),
      );
      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
      );
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
      props.onClose();
    } else {
      if (document.getElementById("playlist-transcript")) {
        document.getElementById("playlist-transcript").style.display = "none";
      }
      showplaylistloader(true);
      if (props.env !== "recordspace") {
        const resultStat = store.getState().projectData.resultStat;
        const jobname = resultStat.jobname;
        const refid = store.getState().projectData.resultStat.refid;
        getKSFromLocal()
          .then(async (koolioJobs) => {
            let job = koolioJobs.filter((job) => job.jobname === jobname)[0];
            if (job) {
              job.duration = resultStat.duration;
              job.updated_at = new Date();
              await updateKooliospace(job);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        // job.duration = playlist.duration
        // job.updated_at = new Date()
        clearBackendApplyScheduler();
        clearOperationCountHandlerScheduler();
        await applyOperationsInBackend(jobname);
        await updateResultstatByJobname(jobname, refid);
        await unlockFeatureForOtherCollaborators(editFeatureTitle);
        await updateIndexDBoperationsData(
          "jobname, frontend_apply_status, deleted",
          [jobname, "pending", "false"],
          "frontend_apply_status",
          "completed",
        );
        store.dispatch(
          shareAndCollabActions.updateEditFeatureLockedBy({
            editFeatureLockedBy: "",
          }),
        );
        store.dispatch(
          shareAndCollabActions.updateEditFeatureEnable({
            isEditFeatureEnabled: true,
          }),
        );
        store.dispatch(
          shareAndCollabActions.updateISProjectShared({
            isTheProjectShared: false,
          }),
        );
        store.dispatch(projectDataSliceActions.clearProjectReducerData());
        store.dispatch(projectTitleSliceActions.clearProjectData());
        sessionStorage.removeItem("aiShowNotes");
        sessionStorage.setItem("prevstate", "workspace");
      }
      hideplaylistloader(true);
      store.dispatch(frontendApplySliceActions.frontendApplyReducerData(false));
      navigate("/kooliospace", { replace: true });
    }
  };
  return (
    // <div className="">
    // {/* <img src={analyticsimg} className="d-none" alt="" /> */}

    // {/* <div className="offset-2 avatars" id="shareholder-profile-cont"></div> */}

    <div className=" d-inline-flex">
      {/* <span onClick={userProfileModal} id="user-profile-cont" style={{ position: "relative" }}>
                    <img className="user-profile img-fluid" id="user-profile" src="" alt="" />
                </span> */}

      <span
        id="kooliospace-close"
        style={{ cursor: "pointer", display: "flex" }}
      >
        <img
          className="img-fluid close-workspace"
          id="close-workspace"
          src="../../image/exit-project.png"
          alt=""
          title="close-workspace"
          onClick={closeKoolioWorkspace}
        />
      </span>
    </div>
    // </div>
  );
}
