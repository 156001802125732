import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "../../css/dark/exportTranscriptionsModal.css";
import axios from "axios";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import "../../css/dark/ShowNotesModal.css";
import "../../css/dark/subscriptionModal.css";
import { showStatText } from "../../utils/utils";
import ShowMessageModal from "./ShowMessageModal";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

const SubscriptionModal = ({
  annualProSubscription,
  monthlyProSubscription,
  freePlan,
  closeModal,
}) => {
  useBootstrapTooltips();
  const monthlyAndAnnualPlanData = [
    { monthly: monthlyProSubscription },
    { annual: annualProSubscription },
  ];
  const [showMsgModal, setShowMsgModal] = useState(false);

  const getSubscriptionStatus = async (params) => {
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      async (tokens) => {
        const subscriptionStatus = await axios.post(
          _config.api + "/subscription-status-confirmation",
          params,
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );
        return subscriptionStatus.data;
      },
    );
  };

  const getPriceText = (plan, promocode) => {
    if (plan?.price === 0) {
      return "Free (Subscribed)";
    } else if (plan[0]?.monthly?.price === 20) {
      return `$${plan[0]?.monthly?.price}/month`;
    } else {
      return `$${plan.price}/year`;
    }
  };

  const moveToSubscription = async (planId, planName, promocode) => {
    const planWithCustomerDetails = {
      plan: {
        id: planId,
        name: planName,
      },
      username: Koolioai.username,
      email: Koolioai.email,
      // promo_code: promocode || "", // Set promocode to an empty string if not provided
    };

    if (promocode) {
      planWithCustomerDetails["promo_code"] = promocode;
    }
    const tokens = await Promise.all([
      Koolioai.authToken(),
      Koolioai.accessToken(),
    ]);

    try {
      const checkoutRequest = await axios.post(
        _config.api + "/create-checkout-session",
        planWithCustomerDetails,
        {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        },
      );
      window.location.href = checkoutRequest.data.redirect_url;
    } catch (err) {
      setShowMsgModal(true);
      const message =
        "Failed to create checkout session. Please try again after some time.";
      showMsgModal && <ShowMessageModal message={message} />;
    }
  };

  const SubscriptionCard = ({ plan }) => {
    const length = plan.length;
    let monthlyPlan = false;
    if (length > 1) {
      monthlyPlan = true;
    }
    return (
      <div
        className="card my-4"
        style={{
          border: "1px solid #000",
          borderRadius: "10px",
          color: "#c4c4c4",
          backgroundColor: "#181818",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          maxWidth: "330px",
          height: "600px",
          // maxWidth: "300px",
        }}
      >
        {monthlyPlan ? (
          <>
            <h2
              className="card-header"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "1.1rem",
                backgroundColor: "rgb(138, 43, 226)",
              }}
            >
              {/* {plan?.name} */}
              Pro Subscription
            </h2>
            {/* <p><strong>Duration:</strong> {plan.duration} days</p>
            <p><strong>Price:</strong> ${plan.price}</p> */}
            <div className="card-body-cus h-100 d-flex flex-column">
              <ul>
                {plan[0]?.monthly?.details?.map((detail, index) =>
                  detail === "" ? null : <li key={index}>{detail}</li>,
                )}
              </ul>
              <p id="trial-month-tagline">
                Experience More, Pay Less! <br /> Enjoy 2 Months FREE of Pro
                Features!
              </p>
              <button
                className="full-modal-btn mx-auto my-auto mb-4 mt-4"
                style={{
                  width: "85%",
                  // margin: "10px",
                }}
                onClick={() =>
                  moveToSubscription(
                    plan[0]?.monthly?.id,
                    plan[0]?.monthly?.name,
                    plan[0]?.monthly?.promocode,
                  )
                }
              >
                {getPriceText(plan, plan?.promo)}
              </button>
              <button
                className="full-modal-btn mx-auto my-auto mb-4"
                style={{
                  width: "85%",
                }}
                onClick={() =>
                  moveToSubscription(
                    plan[1]?.annual?.id,
                    plan[1]?.annual?.name,
                    plan[1]?.annual?.promocode,
                  )
                }
              >
                {getPriceText(plan[1]?.annual, plan?.promo)}
              </button>
              <p className="mx-auto my-auto pb-4">
                Save 16% on yearly subscription
              </p>
            </div>
          </>
        ) : (
          <>
            <h2
              className="card-header"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "1.1rem",
                backgroundColor: "#9C26F6",
              }}
            >
              {plan?.name}
            </h2>
            {/* <p><strong>Duration:</strong> {plan.duration} days</p>
            <p><strong>Price:</strong> ${plan.price}</p> */}
            <div className="card-body-cus d-flex flex-column">
              <ul>
                {plan?.details?.map((detail, index) =>
                  detail === "" ? null : <li key={index}>{detail}</li>,
                )}
              </ul>
              <button
                className="full-modal-btn mx-auto my-auto mb-4"
                style={{
                  width: "85%",
                }}
                onClick={() =>
                  moveToSubscription(plan?.id, plan?.name, plan?.promocode)
                }
              >
                {getPriceText(plan, plan?.promo)}
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  return createPortal(
    <>
      <div
        className="half-modals-content"
        id="half-modals-content"
        style={{ width: "55%", height: "100vh", overflowY: "scroll" }}
      >
        <p className="half-modals-header" style={{ fontSize: "1.5rem" }}>
          Subscribe to one of the following plans
        </p>
        <img
          title="close"
          className="img-fluid top-right-button"
          onClick={closeModal}
          id="closeWorkspaceModalIcon"
          style={{ cursor: "pointer" }}
          src="/image/exit-workspace.svg"
          alt="close"
        />
        {/* style={{ display: "flex", justifyContent: "space-around" }} */}
        {/* style={{ maxWidth: "620px" }} */}
        {/* style={{ display: "flex", maxWidth: "620px" }} */}
        <div className="row row-cols-1 row-cols-sm-2 mb-3 gx-5">
          <div className="col d-flex align-items-stretch">
            <SubscriptionCard plan={freePlan} />
          </div>
          <div className="col d-flex align-items-stretch">
            {/* <div className="flex-col"> */}
            <SubscriptionCard plan={monthlyAndAnnualPlanData} />
            {/* <SubscriptionCard plan={annualProSubscription} /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>,
    document.body,
  );
};

export default SubscriptionModal;
