import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "../../css/dark/exportTranscriptionsModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

const Export = ({ closeModal }) => {
  const [transcriptions, setTranscriptions] = useState([]);
  const resultStat = useSelector((state) => state.projectData.resultStat);
  useBootstrapTooltips();

  useEffect(() => {
    if (resultStat && resultStat.speakers_segments) {
      let tcs = [];
      resultStat.speakers_segments.forEach((track) => {
        track.annotations.forEach((annot) => {
          tcs.push(annot);
        });
      });
      setTranscriptions(tcs);
    }
  }, [resultStat]);

  const handleDownload = () => {
    if (!transcriptions.length) {
      console.error("Transcriptions are undefined or empty");
      return;
    }

    const pdf = new jsPDF();
    let lines = [];
    let currentLine = "";
    const maxWidth = 450; // Adjust this value as needed based on your page width

    // Add Logo at the center of the top
    const logoImg = new Image();
    logoImg.src = "/img/logo.png"; // Replace with your logo path
    logoImg.onload = function () {
      const imgWidth = 50; // Adjust logo width as needed
      const imgHeight = (logoImg.height * imgWidth) / logoImg.width;
      const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      pdf.addImage(logoImg, "PNG", x, 10, imgWidth, imgHeight); // Centered at top

      // const projectNameWidth = pdf.getStringUnitWidth('Project-Name') * 17; // Font size 12
      // const projectNameX = (pdf.internal.pageSize.getWidth() - projectNameWidth) / 2;

      // pdf.setFontSize(17);
      // pdf.text('Project-Name', projectNameX, 20 + imgHeight + 10);

      let startY = 20 + imgHeight + 20;
      transcriptions.forEach((annotation, index) => {
        let text = "";
        if (typeof annotation === "string") {
          text = annotation;
        } else if (typeof annotation === "object" && annotation.content) {
          text = annotation.content;
        } else {
          console.error(`Invalid annotation at index ${index}`);
          return; // Skip invalid annotation
        }

        let words = text.split(" ");
        words.forEach((word) => {
          let width = pdf.getStringUnitWidth(currentLine + " " + word) * 10;
          if (width < maxWidth) {
            currentLine += (currentLine ? " " : "") + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        });
      });

      // Add the last line to lines array
      if (currentLine) {
        lines.push(currentLine);
      }

      // Render the lines as paragraphs with customized font properties
      lines.forEach((line, index) => {
        pdf.setFontSize(12); // Set font size to 12
        // pdf.setFontStyle('normal'); // Set font style to normal (you can use 'bold', 'italic', etc.)
        pdf.text(line, 10, startY + index * 12); // Adjust x and y coordinates as needed
      });

      pdf.save(`${resultStat.project_title}.pdf`);
    };
  };

  return createPortal(
    <>
      <div className="recordingName-wrapper-transcription" onClick={closeModal}>
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            Export transcript
            <img
              title="close"
              className="img-fluid"
              onClick={closeModal}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/img/exit-workspace.svg"
              alt=""
            />
          </div>
          <div className="half-modals-sub-header">
            Please select the type of document you want to download from the
            dropdown
          </div>
          <div className="export-transcript-filename-cont">
            <div style={{ marginRight: "0.5vw" }}>Transcript filename:</div>
            <div>{resultStat.project_title}</div>
          </div>
          <div
            className="export-transcript-select half-modals-action"
            id="export-transcript-select"
            style={{ position: "relative" }}
          >
            <ul
              className="dropdown dropdown-format-options export-transcript-selected"
              id="export-trans-parent"
            >
              <li className="dd-selected" value="pdf" id="export-trans">
                PDF (PDF Document)
                <i
                  className="fas fa-caret-down"
                  style={{ position: "absolute", top: "22%", right: "9%" }}
                ></i>
              </li>
            </ul>
          </div>
          <div className="half-modals-action download-transcript">
            <div
              className="half-modals-actions text-center"
              style={{ width: "15%" }}
            >
              <button
                type="button"
                className="half-modals-action-button handlebutton"
                onClick={handleDownload}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body,
  );
};

export default Export;
