import React, { useEffect } from "react";
import { create_UUID } from "../../../../utils/utils";
import $ from "jquery";
import "../../../../css/dark/flagModal.css"; // Import the CSS file
import { createPortal } from "react-dom";
import { useState } from "react";
import { koolioModals } from "../../../../utils/Res";
import {
  deleteFlagToBackend,
  sendFlagToBackend,
} from "../../../../services/FlagServices";
import store from "../../../../redux/Store";
import { flagListActions } from "../../../../redux/slice/Flagslice";
import { Koolioai } from "../../../../utils/cognitoAuth";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

const FlagModal = (props) => {
  useBootstrapTooltips();
  const [comment, setComment] = useState("");
  useEffect(() => {
    koolioModals();
    let flagsList = store.getState().flag.flagsList;
    let flagID = store.getState().flag.currFlagId;
    flagsList.forEach((flag) => {
      if (flag.id == flagID) {
        setComment(flag.flagContent.comment);
      }
    });
  }, []);
  const closeHalfModal = () => {
    // Implement the logic to close the modal
    store.dispatch(flagListActions.updateFlagShowModal(false));
    props.onClose();
  };
  function editFlag() {
    let flagsList = store.getState().flag.flagsList;
    let updateFlag;
    let flagID = store.getState().flag.currFlagId;
    flagsList.forEach((flag) => {
      if (flag.id == flagID) {
        updateFlag = JSON.parse(JSON.stringify(flag));
      }
    });
    flagsList = flagsList.filter((flag) => flag.id !== flagID);
    updateFlag.flagContent.id = create_UUID();
    updateFlag.flagContent.comment = $("#comment").val();
    updateFlag.flagContent.userName = Koolioai.getDisplayName();
    updateFlag.flagContent.timestamp = Date.now();
    flagsList.push(updateFlag);
    const params = {
      task: "edit",
      inputs: updateFlag,
    };
    store.dispatch(flagListActions.uploadFlagList(flagsList));
    document.getElementById("flag-" + flagID).title =
      `${Koolioai.getDisplayName()}: ${updateFlag.flagContent.comment}`;
    document.getElementById("global-flag-" + flagID).title =
      `${Koolioai.getDisplayName()}: ${updateFlag.flagContent.comment}`;
    sendFlagToBackend(params);
    closeHalfModal();
    // indicateTranscriptSpecials()
  }
  function deleteFlag() {
    let flagID = store.getState().flag.currFlagId;
    let flagsList = store.getState().flag.flagsList;
    let todeleteflag;
    flagsList.forEach((flag) => {
      if (flag.id == flagID) {
        todeleteflag = flag;
      }
    });
    flagsList = flagsList.filter((flag) => flag.id !== flagID);
    if (todeleteflag) {
      const params = {
        task: "delete",
        inputs: todeleteflag,
      };
      document.getElementById("flag-" + flagID).remove();
      document.getElementById("global-flag-" + flagID).remove();
      deleteFlagToBackend(params);
      store.dispatch(flagListActions.uploadFlagList(flagsList));
      closeHalfModal();
    } else {
      console.error("invalid falg data");
    }
  }
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  return createPortal(
    <>
      <div id="half-modals-container">
        <div
          className="half-modals-content"
          id="half-modals-content"
          style={{
            height: "calc((211 / 1440) * 100vw) !important",
            width: "calc((756 / 1440) * 100vw) !important",
          }}
        >
          <div className="half-modals-header">
            <p style={{ margin: 0 }} id="api-header">
              Add Comment
            </p>
            <img
              title="close"
              className="img-fluid"
              id="closeWorkspaceModalIcon"
              src="/image/exit-workspace.svg"
              onClick={closeHalfModal}
              alt=""
              width="13px"
            />
          </div>
          <div className="half-modals-sub-header"></div>
          <input
            className="api-input"
            id="comment"
            type="text"
            placeholder="Type something here"
            value={comment}
            onChange={handleCommentChange}
          />
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="add-comment"
                className="half-modals-action-button"
                onClick={editFlag}
              >
                Comment
              </button>
            </div>
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="delete"
                className="half-modals-action-button"
                onClick={deleteFlag}
              >
                Remove flag
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};
export default FlagModal;
