import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { _config, Koolioai } from "../../../../utils/cognitoAuth";
// import { apiProviderBuzzsprout, apiProviderSpreaker, apiProviderCastos, apiProviderTransistor, apiProviderPodbean } from './apiProviders';
import { createPortal } from "react-dom";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import "../../../../css/dark/PublishModal.css"; // Import the CSS file
import {
  apiProviderBuzzsprout,
  apiProviderCastos,
  apiProviderHeadliner,
  apiProviderPodbean,
  apiProviderSpreaker,
  apiProviderTransistor,
  authenticationWindow,
  buzzsproutSettings,
  getShows,
  initiateLoginInApiProvider,
  loadShowsDropDown,
  noSpreakerShow,
  sendTokenToBackend,
  setAccessToken,
} from "../../../../utils/publish-api/ApiIntegrationAuth";
import { showStatText } from "../../../../utils/utils";
import store from "../../../../redux/Store";
import { closeExportToPodcastApiSliceActions } from "../../../../redux/slice/CloseExportToPodcastApiSlice";
import PublishIndividualUIModal from "./PublishIndividualUIModal";
import { setTitleList, showMessage } from "../../../../utils/koolio-workspace";
import { SelectedApiProviderSliceActions } from "../../../../redux/slice/SelectedApiProviderSlice";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
import topbar from "topbar";
import { screenHeight, screenWidth } from "../../../../utils/Res";

const PublishModalContent = (props) => {
  const [initiatePopUpForEpisode, setInitiatePopUpForEpisode] = useState(false);
  const [apiProvider, setApiProvider] = useState("");
  const closeExportToPodcastApivariable = useSelector(
    (state) => state.closeExportToPodcastApiSlice,
  );
  const initiatePopUpForEpisodeVariable = useSelector(
    (state) => state.initiatePopUpForEpisodeSlice,
  );

  useBootstrapTooltips();
  const [token, setToken] = useState(null);
  const handleAuth = () => {
    const url = window.location.href;
    let apiProvider = "";

    if (window.name.includes("spreaker-auth")) {
      apiProvider = "spreaker";
    } else if (window.name.includes("blubrry-auth")) {
      apiProvider = "blubrry";
    } else if (window.name.includes("podbean-auth")) {
      apiProvider = "podbean";
    }
    if (apiProvider === "") return;
    const code = getToken(url, apiProvider);
    if (code) {
      window.sendTokenToBackend = sendTokenToBackend(code, apiProvider);
      setToken(code); // Store the token in state
      // Assuming opener exists and sendTokenToBackend is a valid function
      if (
        window.opener &&
        typeof window.opener.sendTokenToBackend === "function"
      ) {
        window.opener.sendTokenToBackend(code, apiProvider);
      }
    }

    // Close the current window
    window.close();
  };
  // window.onload = handleAuth();
  const parseParams = (str) => {
    const params = new URLSearchParams(str.split("?")[1]);
    return Object.fromEntries(params);
  };

  const getToken = (url, apiProvider) => {
    const params = parseParams(url);
    // Handle extracting the token based on API provider logic
    switch (apiProvider) {
      case "spreaker":
        return params.code || null;
      case "blubrry":
        return params.token || null;
      case "podbean":
        return params.access_token || null;
      default:
        return null;
    }
  };

  const closeExportToPodcastApiModal = () => {
    setInitiatePopUpForEpisode(false);
    store.dispatch(
      closeExportToPodcastApiSliceActions.updateStateOfPodastClicked({
        value: "",
      }),
    );
    closeHalfModal();
  };
  /******************************** useEffect conditions ***************************************/
  useEffect(() => {
    setInitiatePopUpForEpisode(false);
  }, []);

  useEffect(() => {
    if (closeExportToPodcastApivariable.returnToPodcastClicked == true) {
      closeExportToPodcastApiModal();
    }
  }, [closeExportToPodcastApivariable.returnToPodcastClicked]);

  useEffect(() => {
    if (
      initiatePopUpForEpisodeVariable.apiProvider != "" &&
      initiatePopUpForEpisodeVariable.apiProvider != undefined
    ) {
      // initiatePopUpForEpisodeInfo();
    }
  }, [initiatePopUpForEpisodeVariable]);
  /********************************useEffect conditions ***********************************/

  function initiatePopUpForEpisodeInfo(apiProvider) {
    setApiProvider(apiProvider);
    setInitiatePopUpForEpisode(true);
  }

  const closeHalfModal = () => {
    // close publish modal and move to export page
    props.onClose();
  };

  async function setExportToPodcastApi(apiProvider) {
    // Setting isNativeExport to false
    store.dispatch(
      SelectedApiProviderSliceActions.updateIsNativeExport({
        isNativeExport: false,
      }),
    );

    // Retrieving DOM elements
    let buzzsprout = document.getElementById("btn-buzzsprout");
    let spreaker = document.getElementById("btn-spreaker");
    let castos = document.getElementById("btn-castos");
    let transistor = document.getElementById("btn-transistor");
    let podbean = document.getElementById("btn-podbean");
    let blubrry = document.getElementById("btn-blubrry");

    // Checking if any of the buttons are already active
    if (
      buzzsprout?.classList.contains("btn-active") ||
      spreaker?.classList.contains("btn-active") ||
      castos?.classList.contains("btn-active") ||
      transistor?.classList.contains("btn-active") ||
      podbean?.classList.contains("btn-active")
    ) {
      return false;
    }

    // Showing topbar
    topbar.show();
    (function step() {
      setTimeout(function () {
        if (topbar.progress("+.01") < 1) step();
      }, 30);
    })();

    // Setting selected API provider
    store.dispatch(
      SelectedApiProviderSliceActions.updateApiProvider({
        selectedApiProvider: apiProvider,
      }),
    );

    // Adding 'btn-active' class to the selected API provider button
    if (apiProvider === apiProviderBuzzsprout) {
      buzzsprout.classList.add("btn-active");
    } else if (apiProvider === apiProviderSpreaker) {
      spreaker.classList.add("btn-active");
    } else if (apiProvider === apiProviderCastos) {
      castos.classList.add("btn-active");
    } else if (apiProvider === apiProviderTransistor) {
      transistor.classList.add("btn-active");
    } else if (apiProvider === apiProviderPodbean) {
      podbean.classList.add("btn-active");
    }

    // Processing based on API provider
    if (apiProvider !== apiProviderHeadliner) {
      await getApiAccessToken(apiProvider, "");
    } else {
      initiatePopUpForEpisodeInfo(apiProvider);
    }

    // Hiding topbar
    topbar.hide();

    // Removing 'btn-active' class from all buttons
    buzzsprout.classList.remove("btn-active");
    spreaker.classList.remove("btn-active");
    castos.classList.remove("btn-active");
    transistor.classList.remove("btn-active");
    podbean.classList.remove("btn-active");

    // Setting max height for showList
    if (document.getElementById("showList")) {
      let showListMaxHeight =
        ((80 / screenHeight) * 100 * window.innerHeight) / 100 + "px";
      document.getElementById("showList").style.maxHeight = showListMaxHeight;

      // // Setting font size for showList
      let showListFontSize =
        ((14 / screenWidth) * 100 * window.innerWidth) / 100 + "px";
      document.getElementById("showList").querySelector("ul").style.fontSize =
        showListFontSize;

      // // Hiding showList
      document.getElementById("showList").style.visibility = "hidden";
    }
    // Handling no show found for Spreaker
    if (noSpreakerShow) {
      showMessage(
        "No show found!",
        '<a href="https://www.spreaker.com/" target="_blank" id="spreakerLink">Click here to create a show on spreaker</a>',
        7000,
        "error",
        "more-options",
      );
      document.getElementById("half-modals").style.visibility = "hidden";
      document.getElementById("half-modals").innerHTML = "";
      noSpreakerShow = false;
    }
  }

  async function getApiAccessToken(apiProvider, code) {
    let params;
    if (code === undefined || code === "") {
      params = {
        provider: apiProvider,
      };
    } else {
      params = {
        provider: apiProvider,
        code,
      };
    }
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) => {
        return axios
          .get(_config.api + "/api-integration", {
            params,
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          })
          .then((res) => {
            const responseObj = res.data;
            if (
              responseObj.status === 200 ||
              (responseObj.access_token !== undefined &&
                responseObj.access_token !== "")
            ) {
              // console.log('Access token: ' + responseObj.access_token)
              return setAccessToken(apiProvider, responseObj.access_token).then(
                () => {
                  if (
                    authenticationWindow !== undefined &&
                    authenticationWindow !== null
                  ) {
                    authenticationWindow.close();
                  }
                  if (
                    apiProvider === apiProviderTransistor ||
                    apiProvider === apiProviderCastos ||
                    apiProvider === apiProviderSpreaker
                  ) {
                    return loadShowsDropDown(apiProvider).then(() => {
                      // Open up the form to take user input.
                      initiatePopUpForEpisodeInfo(apiProvider);
                      const shows = getShows(apiProvider);
                      setTitleList(shows, apiProvider);
                      return Promise.resolve();
                    });
                  } else {
                    // Open up the form to take user input.
                    initiatePopUpForEpisodeInfo(apiProvider);
                    return Promise.resolve();
                  }
                },
              );
            } else if (responseObj.status === 404) {
              if (
                apiProvider === apiProviderBuzzsprout ||
                apiProvider === apiProviderCastos ||
                apiProvider === apiProviderTransistor
              ) {
                // Open up the form to take user input.
                initiatePopUpForEpisodeInfo(apiProvider);
              } else {
                showStatText(
                  "authenticating user in " + apiProvider + ", please wait...",
                );

                initiateLoginInApiProvider(apiProvider);
              }
            } else if (responseObj.status === 502) {
              showStatText(
                "Sorry, code expired for " +
                  apiProvider +
                  ". Please try again later.",
              );
            } else {
              showStatText(
                "Sorry, retrieving access token for " +
                  apiProvider +
                  " failed.",
              );
            }
          })
          .catch((e) => {
            // console.error(e)
          });
      },
    );
  }

  const moveToPublishOptions = () => {
    // Adlogic to logout from API
    setInitiatePopUpForEpisode(false);
  };

  return createPortal(
    <>
      {!initiatePopUpForEpisode && (
        <div id="half-modals-container">
          <div className="half-modals-content" id="half-modals-content">
            <div className="half-modals-header">
              Publishing Platforms
              <img
                title="close"
                className="img-fluid"
                onClick={closeHalfModal}
                id="closeWorkspaceModalIcon"
                style={{ cursor: "pointer" }}
                src="/image/exit-workspace.svg"
                alt=""
                width="13px"
              />
            </div>
            <div className="half-modals-sub-header">
              List of different API's for publishing your project
            </div>
            <div className="half-modals-action-inputs">
              <div className="half-modals-actions-inputs d-flex">
                <button
                  type="button"
                  id="btn-buzzsprout"
                  onClick={() => setExportToPodcastApi(apiProviderBuzzsprout)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Buzzsprout
                </button>
                <button
                  type="button"
                  id="btn-spreaker"
                  onClick={() => setExportToPodcastApi(apiProviderSpreaker)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Spreaker
                </button>
                <button
                  type="button"
                  id="btn-castos"
                  onClick={() => setExportToPodcastApi(apiProviderCastos)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Castos
                </button>
                <button
                  type="button"
                  id="btn-transistor"
                  onClick={() => setExportToPodcastApi(apiProviderTransistor)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Transistor
                </button>
                <button
                  type="button"
                  title="Headliner"
                  id="btn-headliner-mock"
                  onClick={() => setExportToPodcastApi(apiProviderHeadliner)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Headliner
                </button>
                <button
                  type="button"
                  id="btn-podbean"
                  onClick={() => setExportToPodcastApi(apiProviderPodbean)}
                  className="half-modals-action-button"
                >
                  <img
                    src="/image/workspace/podcast-icon.png"
                    className="podcast-icon"
                  />{" "}
                  Podbean
                </button>
                {/*<div className="headliner-share" id="btn-headliner"></div>*/}
              </div>
            </div>
          </div>
        </div>
      )}
      {initiatePopUpForEpisode && (
        <PublishIndividualUIModal
          apiProvider={apiProvider}
          closeExportToPodcastApiModal={moveToPublishOptions}
          onClose={closeHalfModal}
        ></PublishIndividualUIModal>
      )}
    </>,
    document.querySelector("body"),
  );
};

export default PublishModalContent;
