import "../../css/dark/profileModal.css";
import axios from "axios";
import { Koolioai, _config, logout } from "../../utils/cognitoAuth";
import SubscriptionModal from "./subscriptionModal";
import { useEffect, useReducer, useState } from "react";
import BlurModal from "./BlurModal";
import {
  ACM,
  checkAccountDetails,
  getRemainingDays,
  initiateACM,
} from "../../utils/utils";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

// Assuming the objects are defined and populated

const ProfileModal = ({ closeModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

  const [freePlan, setFreePlan] = useState({});
  const [monthlyProSubscription, setMonthlyProSubscription] = useState({});
  const [annualProSubscription, setAnnualProSubscription] = useState({});
  const [planExist, setPlanExist] = useState(false);
  const [planName, setPlanName] = useState("Loading...");
  const [planValidity, setPlanValidity] = useState("");
  const [cancelSubscriptionBtn, setCancelSubscriptionBtn] = useState(
    "Cancel Subscription",
  );
  const [isLoading, setIsLoading] = useState(true);
  // useBootstrapTooltips();

  useEffect(() => {
    let planName = JSON.parse(localStorage.getItem("planName"));
    if (planName) {
      setPlanName(ACM.planName);
      setPlanValidity(`(Renew before ${ACM.planExpiry})`);
      setIsLoading(false);
    }
    if (
      planName === "Monthly Pro Subscription" ||
      planName === "Annual Pro Subscription"
    ) {
      setPlanExist(true);
      setIsLoading(false);
    } else if (planName === "Free" || planName === "Trial") {
      setPlanName("Free Account");
      setIsLoading(false);
      setPlanValidity("");
    }

    //Check Subscription Status
    let subscription_status = localStorage.getItem("subscription_status");
    if (subscription_status === '"inactive"') {
      setCancelSubscriptionBtn("");
    }
  }, []);

  useEffect(() => {
    setIsMounted(true);
    const username = Koolioai.getDisplayName();
    const email = Koolioai.email;
    const profileImageUrl = `https://ui-avatars.com/api/?name=${username}&background=181818&color=fff&rounded=true&border=ffa500&size=56`;

    setUserName(username);
    setEmail(email);
    setProfileUrl(profileImageUrl);

    return () => setIsMounted(false);
  }, []);

  // const closeModal = () => {
  //     setShowModal(false); // Close the modal
  // };

  // const handlePromocodeChange = (event) => {
  //   setPromocodeValue(event.target.value);
  // };

  // ... other functions
  // fetch plan from backend
  const getPlansFromBackend = async () => {
    try {
      const response = await axios.get(_config.api + "/plan");
      return response.data.plans;
    } catch (error) {
      console.error("Error fetching plans:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };

  const showPlanSubscriptionModal = async () => {
    try {
      const fetchedPlans = await getPlansFromBackend();
      const result = fetchedPlans?.forEach((plan) => {
        let detailsArray = plan.details?.split(";");
        if (plan?.name === "Annual Pro Subscription") {
          setAnnualProSubscription({ ...plan, details: detailsArray });
        } else if (plan?.name === "Monthly Pro Subscription") {
          setMonthlyProSubscription({ ...plan, details: detailsArray });
        } else if (plan?.name === "Free") {
          setFreePlan({ ...plan, details: detailsArray });
        }
      });
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };
  if (!isMounted) {
    return null;
  }
  const showBlurModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="profileModal-wrapper"></div>
      {!showModal && (
        <div id="annotmodalprofile" className="modal" onClick={closeModal}>
          <span
            className="col-12"
            id="annotCloseModal"
            style={{
              height: "auto",
              width: "1.4343923865300148vw", // 19.5938px
              cursor: "pointer",
              position: "fixed",
              top: "1.7569546120058566vw", // 24px
              right: "1.7569546120058566vw", // 24px
              margin: "0",
              zIndex: "999px",
            }}
          >
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="close-profile-modal-tooltip">
                  close-profile
                </Tooltip>
              }
            >
              <img
                id="close-profile-modal-window"
                src="/image/exit-workspace.svg"
                onClick={closeModal}
                alt=""
              />
            </OverlayTrigger>
          </span>

          <div
            id="modal_8"
            className="user-profile-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="d-flex user-details">
              <div className="user-profile-picture">
                <img
                  id="user-profile-image"
                  src={profileUrl}
                  alt="User Profile"
                />
              </div>

              <div className="col-9 user-profile-details text-left">
                <div className="profile-username" id="profile-username">
                  {userName}
                </div>
                <div className="profile-email" id="profile-email">
                  {email}
                </div>
              </div>
            </div>

            <hr className="user-profile-separator" />

            <div className="profile-options">
              <div
                className="text-left profile-option account-settings"
                id="account-extension"
              >
                <span id="account-role">{planName}</span>
                <span id="account-validity">{planValidity}</span>
              </div>

              {isLoading ? (
                <></>
              ) : (
                <>
                  {" "}
                  {planExist ? (
                    <>
                      <div
                        className="text-left profile-option account-settings"
                        id="cancel-subscription-btn"
                        onClick={() => setShowCancelSubscriptionModal(true)}
                      >
                        {cancelSubscriptionBtn}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="text-left profile-option sign-out"
                        id="subscription-btn"
                        onClick={() => showPlanSubscriptionModal()}
                      >
                        Subscribe to pro plan
                      </div>
                    </>
                  )}{" "}
                </>
              )}

              <div
                className="text-left profile-option sign-out"
                onClick={logout}
              >
                {" "}
                Sign out{" "}
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <SubscriptionModal
          annualProSubscription={annualProSubscription}
          monthlyProSubscription={monthlyProSubscription}
          freePlan={freePlan}
          closeModal={closeModal}
        />
      )}
      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal
          message1={"Cancel Subscription"}
          message2={"Are you sure to cancel Koolio subscription"}
          onCancelSubscriptionNo={closeModal}
        />
      )}
    </>
  );
};

export default ProfileModal;
