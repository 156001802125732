import React, { useState, useRef, useEffect, useContext } from "react";
import Navbar from "../../../pages/workspace/navbarWorkspace/Navbar";
import WaveSurfer from "wavesurfer.js";

import "../../../../css/dark/RecordingSpace.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertTimeToPixelForRecording,
  create_UUID,
  formatTime,
  handleHorizontalScrollForRecordspace,
  showStatText,
  timeout,
} from "../../../../utils/utils";
import { recordSpaceBtnActions } from "../../../../redux/slice/RecordspaceBtnTriggerSlice";
import { useDispatch, useSelector } from "react-redux";
import { recordActions } from "../../../../redux/slice/RecordSlice";
import { Koolioai } from "../../../../utils/cognitoAuth";
import { ProjectUploadStatus } from "../kooliospaceUtils";
import { projectDataSliceActions } from "../../../../redux/slice/ProjectDataSlice";
import { initiateJobForRecordedFile } from "../../../../services/uploadService";
import TranscriptionModal from "../../../modals/TranscriptionModal";
import DiarizationModal from "../../../modals/DiarizationModal";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
import store from "../../../../redux/Store";
import { utilsSliceActions } from "../../../../redux/slice/utilsSlice";
import DeleteRecordingModal from "../../../modals/DeleteRecordingModal";
import { multiSelectWordActions } from "../../../../redux/slice/MultiSelectWordList";
import { multiSelectActions } from "../../../../redux/slice/MultiselectSlice";

//functions for timer in recordspace
let timerInterval;
let startTime;
let elapsedTime = 0;
let isTimerRunning = false;
let isTimerPaused = false;
function startPauseTimer() {
  if (isTimerRunning) {
    if (isTimerPaused) {
      // Resume the timer
      startTime = new Date() - elapsedTime;
      timerInterval = setInterval(updateTimer, 10);
      isTimerPaused = false;
    } else {
      // Pause the timer 00:00:000
      const { minutes, seconds } = calculateMinutesAndSeconds(elapsedTime);
      const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
      clearInterval(timerInterval);
      isTimerPaused = true;
      return formattedTime;
    }
  } else {
    // Start the timer
    startTime = new Date();
    isTimerRunning = true;
    timerInterval = setInterval(updateTimer, 10);
  }
}

function resetTimer() {
  // Reset the timer to 00:00:000
  clearInterval(timerInterval);
  isTimerRunning = false;
  isTimerPaused = false;
  elapsedTime = 0;
  displayTime(elapsedTime);
}

function updateTimer() {
  const currentTime = new Date();
  elapsedTime = calculateElapsedTime(startTime, currentTime);
  displayTime(elapsedTime);
}

function calculateElapsedTime(start, end) {
  return end - start;
}
function calculateMinutesAndSeconds(totalMilliseconds) {
  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return { minutes, seconds };
}

function displayTime(elapsedTime) {
  const minutes = Math.floor(elapsedTime / (60 * 1000));
  const seconds = Math.floor((elapsedTime % (60 * 1000)) / 1000);
  const milliseconds = elapsedTime % 1000;

  const formattedTime = `${padZero(minutes)}:${padZero(seconds)}:${padZero(milliseconds, 3)}`;
  document.getElementById("record-duration").textContent = formattedTime;
}
/***************functions related to timer in recordspace ends here **********************************************/

function padZero(number, length = 2) {
  return number.toString().padStart(length, "0");
}

const Recordingspace = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioPosition, setAudioPosition] = useState(0);
  const [fileName, setFileName] = useState("");
  const [transcriptionOption, setTranscriptionOption] = useState("");
  const [diarizationOption, setDiarizationOption] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const wavesurferRef = useRef(null);
  const wavesurferContainerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [workspaceCall, setWorkspaceCall] = useState("");
  // const [showDeleteModal, setDeleteModal] = useState(false);
  useBootstrapTooltips();

  /************************** useEffect conditions start here****************************/

  useEffect(() => {
    if (props.env == "workspace") {
      setFileName("RecordSpace");
    } else {
      setFileName(location.state.fileName);
      setTranscriptionOption(location.state.transcriptionOption);
      // setFile("");
    }

    // Initialize WaveSurfer instance
    if (!wavesurferRef.current) {
      let containerWidth = document.querySelector(
        ".waveurfer-recording-parentDiv",
      ).offsetWidth;
      let desiredDuration = 5;
      let minPxPerSec = containerWidth / desiredDuration;
      wavesurferRef.current = WaveSurfer.create({
        container: "#wavesurfer-recording",
        scrollParent: true,
        waveColor: "#8A2BE2",
        progressColor: "#8A2BE2",
        loopSelection: true,
        cursorColor: "#E2522B",
        minPxPerSec: minPxPerSec,
        responsive: true,
        autoCenter: true,
        autoScroll: true,
        hideScrollbar: false,
        normalize: true, // Ensure waveform fits within the container
      });
      // for global waveform
      wavesurferRef.global = WaveSurfer.create({
        container: "#wavesurfer-recording-global",
        waveColor: "#8A2BE2",
        cursorColor: "#E2522B",
        progressColor: "#8A2BE2",
        height: 50,
        hideScrollbar: true,
        responsive: true,
        normalize: true, // Ensure waveform fits within the container
        setMuted: true,
      });
    }
    if (props.env == "workspace") {
      setWorkspaceCall("nested-recording-content");
    }
    document.querySelector(".more-optionsicon").style.display = "none";
    document.getElementById("toggleBtn").style.pointerEvents = "none";
    document.getElementById("record-duration").textContent = "00:00:000";
    isTimerRunning = false;
    isTimerPaused = false;
    elapsedTime = 0;
  }, []);

  useEffect(() => {
    if (wavesurferRef.current) {
      let icon = document.getElementById("record-play").src.split("/image")[1];
      let time = store.getState().utilsData.currentTime;
      if (icon == "/workspace/active-icons/play.png") {
        let timeToPX = convertTimeToPixelForRecording(
          time,
          wavesurferRef.current,
        );
        let container = document.querySelector(".wavesurfer-recording-div");
        let containerWidth = container.offsetWidth;
        let waveformWidth = wavesurferRef.current.drawer.width; // Get total width of waveform
        let currentScrollPosition = container.scrollLeft;

        // Calculate the visible range
        let visibleStart = currentScrollPosition;
        let visibleEnd = currentScrollPosition + containerWidth;

        // Check if the current position is outside the visible range
        if (timeToPX < visibleStart || timeToPX > visibleEnd) {
          // handleHorizontalScrollForRecordspace(timeToPX, "scroll");
        }
        // setcurtime(timeToPX);
        //
      } else {
        // setcurtime(0);
      }
    }
  }, [useSelector((state) => state.utilsData.currentTime)]);
  wavesurferRef.current?.on("play", () => {
    document.getElementById("record-play").src =
      "/image/workspace/active-icons/play.png";
  });
  wavesurferRef.current?.on("pause", () => {
    document.getElementById("record-play").src =
      "/image/workspace/controls/play.png";
    if (
      wavesurferRef.current.getCurrentTime() ==
      wavesurferRef.current.getDuration()
    ) {
      setIsPlaying((prev) => !prev);
    }
  });
  wavesurferRef.current?.on("ready", () => {
    document.querySelectorAll("wave").forEach(function (wave) {
      if (
        wave.style.position === "relative" &&
        wave.parentElement.id !== "wavesurfer-recording-global"
      ) {
        let width = 0;
        //#651- setting global waveform wave element tag to be equal to global view so that we can drag the the entire region
        let duration = wavesurferRef.current.getDuration();
        const minPxPerSec = wavesurferRef.current.params.minPxPerSec;
        width = duration * minPxPerSec;
        if (
          width <
          document.querySelector("waveurfer-recording-parentDiv").offsetWidth
        ) {
          width = document.querySelector(
            "waveurfer-recording-parentDiv",
          ).offsetWidth;
        }
        wave.style.width = width + "px";
        wave.style.overflow = "visible";
      }
    });
  });

  const globalContainer =
    workspaceCall != ""
      ? "global-waveform-container global-waveformContainer"
      : "global-waveform-container";

  /************************** useEffect condition ends here****************************/
  // for start recording (using record button)
  const handleStartRecording = () => {
    document.getElementById("wavesurfer-recording-container").style.visibility =
      "hidden";
    document.getElementById("koolio-recording-logo").style.visibility =
      "visible";
    document.getElementById("record-duration").style.visibility = " visible";
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        //const rec = new MediaRecorder(stream);
        //rec.onstart = () => {
        //    startPauseTimer();
        //    // setIsTimerRunning((prevIsTimerRunning) => !prevIsTimerRunning)
        //};
        //rec.ondataavailable = (e) => {
        //    console.log(e)
        //    if (e.data.size > 0) {
        //        audioChunksRef.current.push(e.data);
        //    }
        //};
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.onstart = () => {
          startPauseTimer();
          // setIsTimerRunning((prevIsTimerRunning) => !prevIsTimerRunning)
        };

        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          if (document.getElementById("koolio-recording-logo")) {
            document.getElementById(
              "wavesurfer-recording-container",
            ).style.visibility = "visible";
            document.getElementById("koolio-recording-logo").style.visibility =
              "hidden";
            document.getElementById("record-duration").style.visibility =
              " hidden";
          }
          const recordedBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
            name: `${fileName}`,
          });
          setAudioBlob(recordedBlob);
          initializeWaveSurfer(recordedBlob);
          // Clear the chunks for the next recording
          // audioChunksRef.current = []
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  //Load audioblob to wavesurfer instance
  const initializeWaveSurfer = (audioBlob) => {
    wavesurferRef.current.load(URL.createObjectURL(audioBlob));
    wavesurferRef.global.load(URL.createObjectURL(audioBlob));
    wavesurferRef.global.setVolume(0);
  };

  // For stopping the recording
  const handleStopRecording = async () => {
    //Pausing the timer and using its value to show the duration of audio.
    let timeDuration = startPauseTimer();
    document.getElementById("global-waveform-endTime").textContent =
      timeDuration;

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      //stopping the audio file
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  function updateCurrentTime(wavesurfeObj) {
    if (!wavesurfeObj) return;

    const time = wavesurfeObj.getCurrentTime();

    store.dispatch(utilsSliceActions.updateCurrentTime({ currentTime: time }));

    const globalViewTime = document.querySelector(".global-waveform-startTime");
    if (globalViewTime) globalViewTime.innerHTML = formatTime(time);
  }

  let currenTimeScheduler;

  function initiateGetCurrentTimeScheduler() {
    if (currenTimeScheduler) clearCurrentTimeScheduler();

    let wavesurfeObj = wavesurferRef.current;

    wavesurfeObj.un("finish"); // Clear any previous event listeners

    wavesurfeObj.on("finish", function () {
      clearCurrentTimeScheduler();
    });

    currenTimeScheduler = setInterval(() => {
      // wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;

      updateCurrentTime(wavesurfeObj);
    }, 50);
  }

  function clearCurrentTimeScheduler() {
    if (currenTimeScheduler) clearInterval(currenTimeScheduler);
  }
  // For play/pause button
  const handlePlayPause = () => {
    if (audioBlob) {
      setIsPlaying((prev) => !prev);
      let time = store.getState().utilsData.currentTime;
      let waveTime = wavesurferRef.current.getDuration();
      if (parseFloat(time.toFixed(1)) === parseFloat(waveTime.toFixed(1))) {
        wavesurferRef.current.seekTo(0);
        wavesurferRef.global.seekTo(0);

        wavesurferRef.current.playPause();
        wavesurferRef.global.playPause();

        updateCurrentTime(wavesurferRef.current);
      } else {
        wavesurferRef.current.playPause();
        wavesurferRef.global.playPause();
      }
      if (!isPlaying) {
        initiateGetCurrentTimeScheduler();
      } else {
        // document.getElementById("btn-play-icon").src =
        //   "/image/workspace/controls/play.png";
        clearCurrentTimeScheduler();
      }
    }
  };

  // For retake button
  const handleRetakeBtn = () => {
    // Stop recording if it's in progress
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      audioChunksRef.current = [];
      setIsRecording(false);
    }
    resetTimer();
    // Reset audioBlob state
    setAudioBlob(null);

    // Clear the waveform
    if (wavesurferRef.current) {
      wavesurferRef.current.empty();
      audioChunksRef.current = [];
    }
    // Start a new recording
    handleStartRecording();
  };

  // For delete/discard button
  const [showDeleteRecModal, setShowDeleteRecModal] = useState(false);
  function closeDeleteRecModal() {
    setShowDeleteRecModal(false);
  }
  function handleDeleteYes() {
    closeDeleteRecModal();
    closeKoolioWorkspace();
  }
  const closeKoolioWorkspace = async () => {
    if (props.env == "workspace") {
      dispatch(
        recordSpaceBtnActions.storeBtnClickedEnv({
          env: "recordspaceToWorkspace",
        }),
      );
      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
      );
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
      props.onClose();
    } else {
      sessionStorage.setItem("prevstate", "recordspace");
      if (document.getElementById("playlist-transcript")) {
        document.getElementById("playlist-transcript").style.display = "none";
      }
      navigate("/kooliospace", { replace: true });
    }
  };
  function handleRecord() {
    closeDeleteRecModal();
    handleRetakeBtn();
  }
  const handleDiscardBtn = () => {
    // Stop recording if it's in progress
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      handleStopRecording();
    }
    setShowDeleteRecModal(true);
    // // Reset audioBlob state
    // setAudioBlob(null);

    // // Clear the waveform
    // if (wavesurferRef.current) {
    //   wavesurferRef.current.empty();
    //   audioChunksRef.current = [];
    // }

    // document.getElementById("wavesurfer-recording-container").style.visibility =
    //   "hidden";
    // document.getElementById("koolio-recording-logo").style.visibility =
    //   "visible";
    // document.getElementById("record-duration").style.visibility = " visible";
    // resetTimer();
  };

  // For move to start button
  const handleRecordPlayingFromStart = () => {
    handleHorizontalScrollForRecordspace(0);
    wavesurferRef.current.seekTo(0);
    wavesurferRef.global.seekTo(0);
    wavesurferRef.current.play();
    wavesurferRef.global.play();
  };

  // For move to end button
  const handleRecordMoveToEnd = () => {
    wavesurferRef.current.pause();
    handleHorizontalScrollForRecordspace(
      convertTimeToPixelForRecording(
        wavesurferRef.current.getDuration(),
        wavesurferRef.current,
      ),
    );
    wavesurferRef.current.seekTo(1);
    wavesurferRef.global.seekTo(1);
  };
  // diarization
  const [showDiarizationModal, setShowDiarizationModal] = useState(false);
  const [audioDuration, setAudioduration] = useState(0);
  const [file, setFile] = useState("");
  async function handleDiarizationYes() {
    setDiarizationOption(true);
    setShowDiarizationModal(false);
    sendFileToKooliospace(file, audioDuration);
  }
  async function handleDiarizationNo() {
    setDiarizationOption(false);
    setShowDiarizationModal(false);
    sendFileToKooliospace(file, audioDuration);
  }

  function handleDiarizationClose() {
    setShowDiarizationModal(false);
  }

  function sendFileToKooliospace(file = null, audioDuration = 0) {
    navigate("/kooliospace", { replace: true });
    dispatch(
      recordActions.addRecordFileToKooliospace({
        file: {
          file,
          audioDuration,
          sendFileTo: "kooliospace",
          recorded: true,
          transcription: transcriptionOption,
          diarization: diarizationOption,
        },
      }),
    );
  }

  // For confirm button
  const handleConfirmBtn = async () => {
    if (document.getElementById("record-confirm").style.opacity !== "1") return;
    // Get the duration of the audio
    const audioDuration = wavesurferRef.current.getDuration();
    // Navigate to a new page with audio file name and duration as parameters
    let file = new File([audioBlob], `${fileName}.wav`, {
      type: audioBlob.type,
    });
    setFile(file);
    setAudioduration(audioDuration);
    if (props.env == "workspace") {
      dispatch(
        recordSpaceBtnActions.storeBtnClickedEnv({
          env: "recordspaceToWorkspace",
        }),
      );
      resetTimer();
      props.onSelect(file, audioDuration);
    } else {
      sessionStorage.setItem("prevstate", "recordspace");
      if (transcriptionOption) {
        setShowDiarizationModal(true);
      } else {
        sendFileToKooliospace(file, audioDuration);
      }
    }
  };

  function handleWavesurferClick(wavesurferObj, type) {
    const timer = setTimeout(() => {
      const clickedTime = wavesurferObj.getCurrentTime();
      updateCurrentTime(wavesurferObj);

      if (wavesurferRef.global) {
        if (type == "global") {
          let seektime = convertTimeToPixelForRecording(
            clickedTime,
            wavesurferRef.current,
          );
          handleHorizontalScrollForRecordspace(seektime);
        }
        wavesurferRef.global.seekTo(
          clickedTime / wavesurferRef.global.getDuration(),
        );
      }
      if (wavesurferRef.current) {
        wavesurferRef.current.seekTo(
          clickedTime / wavesurferRef.current.getDuration(),
        );
      }
    }, 5);
    return () => clearTimeout(timer);
  }

  return (
    <>
      <div className={`recordingSpace ${workspaceCall} `}>
        <Navbar
          env={props.env ? props.env : "recordspace"}
          // env={(props.env = "kooliospace")}
          onClose={props.onClose}
          fileName={fileName}
        />

        <article className="post col-8-mod">
          <div className="post-content" id="post-content">
            <div id="playlist-loader">
              <div
                className="spinner-container"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.4vw",
                }}
              >
                <img
                  src="/image/koolio-icon-workspace.svg"
                  style={{
                    width: "5.856515373352855vw",
                  }}
                  className="ajax-loader"
                  alt="Loading Spinner"
                />
                <p
                  className="text-white"
                  style={{
                    fontSize: "1.171303074670571vw",
                  }}
                >
                  Please wait...
                </p>
              </div>
            </div>
            <div id="workspace-closing-loader">
              <div
                className="spinner-container"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.4vw",
                }}
              >
                <img
                  src="/image/koolio-icon-workspace.svg"
                  style={{
                    width: "5.856515373352855vw",
                    marginBlockEnd: "1em",
                  }}
                  className="ajax-loader"
                  alt="Loading Spinner"
                />
                <p
                  className="text-white"
                  style={{
                    fontSize: "1.171303074670571vw",
                  }}
                >
                  Saving your workspace.
                </p>
                <p
                  className="text-white"
                  style={{
                    fontSize: "1.171303074670571vw",
                  }}
                >
                  Please wait...
                </p>
              </div>
            </div>
            <div
              id="playlist"
              style={{
                borderLeft: "double #E2522B",
                borderRight: "double #E2522B",
              }}
            ></div>
          </div>
        </article>
        <div className="recordSpace-logo-container">
          <div className="koolio-recording-logo" id="koolio-recording-logo">
            <svg
              viewBox="0 0 98 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="koolio-recording-animation"
            >
              <g id="record-icon">
                <g id="record-left-play" className="animate-play">
                  <path
                    id="Ellipse 45"
                    d="M44 23.0227C44 34.0596 34.8431 43.0449 23.5 43.0449C12.1569 43.0449 3 34.0596 3 23.0227C3 11.9858 12.1569 3.00049 23.5 3.00049C34.8431 3.00049 44 11.9858 44 23.0227Z"
                    stroke="white"
                  ></path>
                  <path
                    id="play"
                    d="M20 23.0005C20 26.3005 20.2 29.0005 20.5 29.0005C21.2 29.0005 27.8 25.6005 29.8 24.2005C30.8 23.5005 29.8 22.5005 26.1 20.1005C23.3 18.4005 20.8 17.0005 20.5 17.0005C20.2 17.0005 20 19.7005 20 23.0005Z"
                    fill="#8A2BE2"
                  ></path>
                </g>
                <g id="record-right-play" className="animate-play">
                  <path
                    id="Ellipse 45_2"
                    d="M96 23.0222C96 34.0591 86.8431 43.0444 75.5 43.0444C64.1569 43.0444 55 34.0591 55 23.0222C55 11.9853 64.1569 3 75.5 3C86.8431 3 96 11.9853 96 23.0222Z"
                    stroke="white"
                  ></path>
                  <path
                    id="play_2"
                    d="M72 23C72 26.3 72.2 29 72.5 29C73.2 29 79.8 25.6 81.8 24.2C82.8 23.5 81.8 22.5 78.1 20.1C75.3 18.4 72.8 17 72.5 17C72.2 17 72 19.7 72 23Z"
                    fill="#8A2BE2"
                  ></path>
                </g>
                <path
                  id="Vector"
                  d="M18.0002 0.899655C3.30016 5.49966 -3.19984 20.2996 3.50016 33.5996C13.1002 52.3996 40.6002 48.8996 46.0002 28.1996C47.3002 23.2996 46.1002 15.3996 43.5002 11.6996C42.7002 10.4996 42.0002 9.19966 42.0002 8.79966C42.0002 8.29966 45.4002 7.99966 49.5002 7.99966C53.6002 7.99966 57.0002 8.29966 57.0002 8.79966C57.0002 9.19966 56.3002 10.4996 55.5002 11.5996C54.7002 12.7996 53.6002 15.7996 53.0002 18.3996C51.0002 27.5996 55.3002 37.7996 63.4002 42.4996C78.8002 51.4996 98.0002 41.0996 98.0002 23.5996C98.0002 14.0996 93.9002 7.19966 85.5002 2.69966C81.7002 0.599655 80.2002 0.499655 51.0002 0.299655C34.2002 0.199655 19.4002 0.499655 18.0002 0.899655ZM30.7002 13.4996C34.5002 15.9996 36.4002 21.5996 35.1002 25.9996C33.3002 31.8996 29.8002 34.4996 23.3002 34.4996C18.4002 34.4996 17.5002 34.1996 14.9002 31.1996C11.5002 27.3996 11.0002 21.7996 13.6002 16.8996C16.5002 11.2996 24.8002 9.69966 30.7002 13.4996ZM80.6002 12.5996C89.4002 16.7996 89.0002 30.2996 80.0002 33.9996C76.1002 35.5996 70.3002 34.6996 67.4002 31.8996C61.7002 26.5996 62.5002 17.5996 68.9002 13.2996C72.9002 10.5996 76.0002 10.3996 80.6002 12.5996Z"
                  fill="#8A2BE2"
                ></path>
              </g>
            </svg>
            <br />
          </div>
          <div className="recording-duration" id="record-duration">
            00:00:000
          </div>
        </div>
        <div
          id="wavesurfer-recording-container"
          className="wavesurfer-recording-container"
        >
          <div className="wavesurfer-recording-user">
            <img
              className="ms-auto me-auto record-user-logo"
              src="https://ui-avatars.com/api/?name=speaker%201&background=BB417C&rounded=true&size=31&color=fff"
              alt=""
            />
          </div>
          <div className="waveurfer-recording-parentDiv">
            <div className="wavesurfer-recording-div">
              <div
                id="wavesurfer-recording"
                ref={wavesurferContainerRef}
                className="waveform-recording"
                onClick={() =>
                  handleWavesurferClick(wavesurferRef.current, "wav")
                }
              />
            </div>
          </div>

          <div className={`${globalContainer}`}>
            <div className="global-waveform-startTime">00:00</div>
            <div
              id="wavesurfer-recording-global"
              className="waveform-recording-global"
              onClick={() =>
                handleWavesurferClick(wavesurferRef.global, "global")
              }
            />
            <div
              className="global-waveform-endTime"
              id="global-waveform-endTime"
            ></div>
          </div>
        </div>
        <div className="recorder-options-container">
          <h3 className="record-headline">Record</h3>
          <div className="recorder-options">
            <img
              className="recordspace-options"
              title="Push to start"
              src="/image/workspace/controls/push_to_start.png"
              alt=""
              id="record-push-to-start"
              onClick={handleRecordPlayingFromStart}
            />
            <img
              className="recordspace-options"
              title="Play/Pause"
              src="/image/workspace/controls/play.png"
              alt=""
              id="record-play"
              onClick={handlePlayPause}
              disabled={!audioBlob}
            />
            <img
              className="recordspace-options"
              title="Delete"
              src="/image/workspace/controls/discard.png"
              alt=""
              id="record-discard"
              onClick={handleDiscardBtn}
            />
            <img
              className="recordspace-options"
              title="Record"
              src={
                isRecording
                  ? "/image/workspace/active-icons/record.svg"
                  : "/image/workspace/controls/record.png"
              }
              alt=""
              id="record-record"
              onClick={isRecording ? handleStopRecording : handleStartRecording}
            />
            <img
              className="recordspace-options"
              title="Retake"
              src="/image/workspace/controls/retake.png"
              alt=""
              id="record-retake"
              onClick={handleRetakeBtn}
            />
            <img
              className="recordspace-options"
              title="Push to end"
              src="/image/workspace/controls/push_to_end.png"
              alt=""
              id="record-push-to-end"
              onClick={handleRecordMoveToEnd}
            />
            <img
              className="recordspace-options"
              style={{ opacity: isRecording ? 0.3 : 1 }}
              title="Confirm"
              src="/image/workspace/controls/rec-check.png"
              alt="record-confirm"
              id="record-confirm"
              onClick={handleConfirmBtn}
            />
          </div>
        </div>
      </div>
      {showDiarizationModal && transcriptionOption && (
        <DiarizationModal
          onDiarizationYes={handleDiarizationYes}
          onClose={handleDiarizationClose}
          onDiarizationNo={handleDiarizationNo}
        ></DiarizationModal>
      )}
      {showDeleteRecModal && (
        <DeleteRecordingModal
          onClose={closeDeleteRecModal}
          onDeleteYes={handleDeleteYes}
          onRecord={handleRecord}
          msg={props.env == "workspace" ? true : false}
        ></DeleteRecordingModal>
      )}
    </>
  );
};

export default Recordingspace;
