import anime from "animejs";
import store from "../../../redux/Store";
import { exportOptionSliceActions } from "../../../redux/slice/ExportOptionsSlice";
import { uploadFileToWorkspaceActions } from "../../../redux/slice/FileSelectedForWorkspaceSlice";
import { NotificationUpdateSliceActions } from "../../../redux/slice/NotificationUpdateModalSlice";
import { notificationDetailsActions } from "../../../redux/slice/NotificationsDetailsSlice";
import { projectDataSliceActions } from "../../../redux/slice/ProjectDataSlice";
import { projectTitleSliceActions } from "../../../redux/slice/ProjectTitleSlice";
import { getKooliospace } from "../../../services/KooliospaceServices";
import { fetchMessageInfo } from "../../../services/NotificationServices";
import {
  getOperationsList,
  getResultStat,
} from "../../../services/globalServices";
import {
  initiateJobForUploadedFile,
  initiateRecordedFileInBackend,
  initiateUploadedFileInBackend,
  startTranscription,
} from "../../../services/uploadService";
import { updateProjectTitle } from "../../../utils/ProjectTitleUtils";
import { Koolioai, _config } from "../../../utils/cognitoAuth";
import {
  addAudioFile,
  addOperationsToDB,
  getArrayBufferFromSrcURL,
  retrieveAudioFileByJobname,
  storeAudioFile,
} from "../../../utils/indexedDButils";
import { create_UUID, getDuration, showStatText } from "../../../utils/utils";
import axios from "axios";

let notificationsDetails = {
  notificationsList: null,
  newNotifications: 0,
};

export const ProjectUploadStatus = function (id, uploadedFile) {
  this.id = id;
  this.progress = 0;
  this.uploadedFile = uploadedFile;
  this.uploadedFileSize = 0;
  this.status = "Uploading";
};

export let uploadProgressStatus = {
  recordUploadProgress: 0,
  uploadedProjectList: [],
  internetSpeed: 1,
  timeout: 30000,
};

export async function uploadAudio(fileList, transcriptOption, diarization) {
  const kooliojob = fileList.kooliojob;
  const resultStat = fileList.resultStat;
  const uploadedProject = fileList.uploadedProject;
  updateKSFromLocal(fileList.kooliojob);
  await storeAudioFile(
    fileList.file,
    kooliojob.jobname,
    kooliojob.created_at,
    resultStat,
  );
  // document.getElementById(kooliojob.jobname + "_view").style.opacity = 1.0;
  // document.getElementById(kooliojob.jobname + "_view").style.cursor = "pointer";
  // document.getElementById(kooliojob.jobname + "_view").disabled = false;
  //document.getElementById(kooliojob.jobname + '_view').setAttribute('onclick', document.getElementById(kooliojob.jobname + '_view').getAttribute('onclick_detail'))
  if (!transcriptOption) {
    uploadedProject.status = "completed";
    document.getElementById("landing-screen").style.display = "flex";
    // document.getElementById("workspace-loader").style.display = "none";
    hideplaylistloader();
    let duration = document.getElementById(
      kooliojob.jobname + "_projectDuration",
    );

    if (duration) {
      //duration.textContent = cueFormatters("mm:ss")(kooliojob.duration)
      duration.textContent = cueFormatters("mm:ss")(kooliojob.duration);
      duration.style.display = "flex";
      duration.style.visibility = "visible";
    }

    if (document.getElementById(kooliojob.jobname + "_view")) {
      document.getElementById(kooliojob.jobname + "_view").style.opacity = 1.0;
      document.getElementById(kooliojob.jobname + "_view").style.cursor =
        "pointer";
      document.getElementById(kooliojob.jobname + "_view").disabled = false;
    }
    if (document.getElementById(kooliojob.jobname + "_del")) {
      document.getElementById(kooliojob.jobname + "_del").style.opacity = 1.0;
      document.getElementById(kooliojob.jobname + "_del").style.cursor =
        "pointer";
      document.getElementById(kooliojob.jobname + "_del").disabled = false;
    }
  }

  uploadProgressStatus.uploadedProjectList.push(uploadedProject);
  try {
    await Koolioai.uploadObject(
      fileList.file,
      kooliojob.jobname + "." + kooliojob.format,
      uploadedProject,
    );
    if (fileList.resultStat.record_job)
      await initiateRecordedFileInBackend(kooliojob);
    else await initiateUploadedFileInBackend(kooliojob);
  } catch {
    console.error("upload failed");
  }
}

/********************Notifications panel fucntions started***************************/
function hideNotificationPanel() {
  document.getElementById("notification-div").style.display = "none";
  document
    .getElementById("notification_icon")
    .classList.remove("notification_icon_active");
  document
    .getElementById("notification_icon")
    .classList.add("notification_icon_inactive");
}

async function addShareAndCollabDataInDb(jobname) {
  store.dispatch(exportOptionSliceActions.clearData());
  showplaylistloader();
  // let fileName = uploadedFile.projectTitle ||  uploadedFile.file.name
  // let fileName = uploadedFile.file.name ;
  let arrayBuffer;
  let resultStat;
  let trimmedFileName;
  try {
    const projectData = await retrieveAudioFileByJobname(jobname);
    if (!projectData) {
      resultStat = await getResultStat(jobname, true, false);
      const kooliojob = await Koolioai.createJob({
        duration: resultStat.duration,
        filename: resultStat.project_title,
        diarization: resultStat.transcribed,
        format: resultStat.format,
      });
      updateKooliospace(
        {
          jobname: resultStat.jobname,
          updated_at: new Date(),
          duration: resultStat.duration,
          project_title: resultStat.project_title,
          format: resultStat.format,
        },
        true,
      );
      // updateKSFromLocal(kooliojob);
      trimmedFileName = resultStat.project_title.slice(0, -4);
    } else {
      resultStat = projectData.data;
    }
    if (resultStat.speakers_src.key) {
      // let key = 'uploads/76acec9a-0003-3c13-8d00-80013fae00d1/f0ec21ac-7c1a-3a72-9f09-2bb722bf261e.wav'
      let fileSrcURl = await Koolioai.getSignedObject(
        resultStat.speakers_src.key,
      );
      arrayBuffer = await getArrayBufferFromSrcURL(fileSrcURl);
      resultStat.speakers_src = arrayBuffer;
      await addAudioFile(resultStat, jobname, resultStat.created_at);
    }
    const audioBlob = new Blob([resultStat.speakers_src]);
    const blobURL = URL.createObjectURL(audioBlob);
    store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
    store.dispatch(
      projectDataSliceActions.updateProjectLoadedFromKooliospace({
        projectLoadedFromKooliospace: true,
      }),
    );
    store.dispatch(
      projectTitleSliceActions.updateProjectTitle({
        env: "Kooliospace",
        projectTitle: resultStat.project_title,
      }),
    );
    store.dispatch(
      exportOptionSliceActions.updateFileName({ fileName: trimmedFileName }),
    );
    store.dispatch(
      uploadFileToWorkspaceActions.uploadFileToWorkspace({ payload: blobURL }),
    );
    hideplaylistloader();
    // navigate(`/workspace/${jobname}`,{replace: true})
  } catch (err) {
    console.error(err);
  }
}
export async function handleResultStatDataInDb(jobname) {
  showplaylistloader();
  let arrayBuffer;
  let resultStat;
  let trimmedFileName;
  try {
    resultStat = await getResultStat(jobname, true, false);
    const kooliojob = await Koolioai.createJob({
      duration: resultStat.duration,
      filename: resultStat.project_title,
      diarization: resultStat.transcribed,
    });
    trimmedFileName = resultStat.project_title.slice(0, -4);
    if (resultStat.speakers_src.key) {
      let fileSrcURl = await Koolioai.getSignedObject(
        resultStat.speakers_src.key,
      );
      arrayBuffer = await getArrayBufferFromSrcURL(fileSrcURl);
      resultStat.speakers_src = arrayBuffer;
      await addAudioFile(resultStat, jobname, resultStat.created_at);
      hideplaylistloader();
    }
  } catch (err) {
    console.error(err);
  }
}
export async function viewNotification(url, load = false) {
  await updateNotificationsAsViewed(true, url);
  if (
    typeof url === "string" &&
    url.split("?")[1].split("=")[0] == "release_id"
  ) {
    let release_id = url.split("?")[1].split("=")[1];
    let env = url.split("?")[0];
    await getNotificationReleaseInfo(env, release_id, load);
  } else {
    let jobname = load.split("workspace/")[1];
    await addShareAndCollabDataInDb(jobname);
    window.open(load, "_self");
  }
}

export function showNotifications(event) {
  if (event) event.preventDefault();
  const notificationsDetails =
    store.getState().notificationDetails.notificationsDetails;
  if (document.getElementById("pending_notification").style.display == "block")
    document.getElementById("pending_notification").style.display = "none";
  if (document.getElementById("notification-div").style.display == "block") {
    hideNotificationPanel();
  } else {
    document
      .getElementById("notification_icon")
      .classList.remove("notification_icon_inactive");
    document
      .getElementById("notification_icon")
      .classList.add("notification_icon_active");
    document.getElementById("notification-list").innerHTML = "";
    document.getElementById("notification-div").style.display = "block";
    let ul = document.getElementById("notification-list");
    if (notificationsDetails.notificationsList.length == 0) {
      let dl = document.createElement("dl");
      dl.classList.add("notification-content");
      dl.style.textAlign = "center";
      dl.appendChild(document.createTextNode("No Notifications Available"));
      ul.appendChild(dl);
    } else {
      notificationsDetails.notificationsList.forEach((notification) => {
        let time = getNotificationCreationTime(notification.created_at);
        let li;
        if (!notification.is_viewed) li = document.createElement("li");
        else li = document.createElement("dl");
        li.classList.add("notification-content");
        li.appendChild(document.createTextNode(notification.notification_text));
        li.addEventListener("mouseover", function (event) {
          if (event) event.preventDefault();
          li.style.color = "#8A2BE2";
        });
        li.addEventListener("mouseleave", function (event) {
          if (event) event.preventDefault();
          li.style.color = "#c4c4c4";
        });
        if (notification.notification_action) {
          li.addEventListener("click", (e) => {
            viewNotification(e, notification.notification_action);
          });
          // li.setAttribute("onclick", "viewNotification(event, '" + notification.notification_action + "')");
          // li.setAttribute("onclick", "viewNotification(event, '" + notification.notification_action + "')");
          li.setAttribute("title", "Open this project in workspace");
        } else li.style.cursor = "not-allowed";
        let timeDiv = document.createElement("div");
        timeDiv.appendChild(document.createTextNode(time));
        timeDiv.classList.add("notification-time");
        li.appendChild(timeDiv);
        ul.appendChild(li);
      });
      updateNotificationsAsViewed();
    }
  }
}

async function updateNotificationsAsViewed(
  singleNotificationUpdate = false,
  url,
) {
  const notificationsDetails =
    store.getState().notificationDetails.notificationsDetails;
  if (singleNotificationUpdate) {
    for (
      let notificationIndexCount = 0;
      notificationIndexCount < notificationsDetails.notificationsList.length;
      notificationIndexCount++
    ) {
      let notification =
        notificationsDetails.notificationsList[notificationIndexCount];
      if (!notification.is_viewed && notification.notification_action == url) {
        await updateNotifications(
          notification.notification_text,
          notification.notification_action,
        );
        notificationsDetails.notificationsList[
          notificationIndexCount
        ].is_viewed = true;
        store.dispatch(
          notificationDetailsActions.updateNotificationsList({
            value: notificationsDetails.notificationsList,
          }),
        );
      }
    }
  } else {
    for (
      let notificationIndexCount = 0;
      notificationIndexCount < notificationsDetails.notificationsList.length;
      notificationIndexCount++
    ) {
      let notification =
        notificationsDetails.notificationsList[notificationIndexCount];
      if (!notification.is_viewed)
        await updateNotifications(
          notification.notification_text,
          notification.notification_action,
        );
    }
  }
}

async function updateNotifications(text, action) {
  const params = {
    insert: "update",
    username: Koolioai.username,
    notification_text: text,
    notification_action: action,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) => {
      // 2914 fix , params declaration were incorrect, now they are correct
      return axios.post(_config.api + "/api-notifications", params, {
        //2914 fix complete
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      });
    })
    .then(
      (response) =>
        new Promise((resolve) => {
          resolve(true);
        }),
    )
    .catch(
      (_err) =>
        new Promise((resolve) => {
          resolve(false);
        }),
    );
}

function getNotificationCreationTime(NotificationCreationTime) {
  let createdTime = new Date(NotificationCreationTime);
  const oneDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();

  const diffDays = Math.abs((currentDate - createdTime) / oneDay);
  if (diffDays < 1) {
    let hours = diffDays * 24;
    if (hours < 1) {
      return Math.round(hours * 60) + " minutes ago";
    } else return Math.round(hours) + " hours ago";
  } else return Math.round(diffDays) + " days ago";
}

export function setNewNotificationCount() {
  let notificationsDetails =
    store.getState().notificationDetails.notificationsDetails;
  if (notificationsDetails.notificationsList.length === 1) {
    if (!notificationsDetails.notificationsList[0].is_viewed) {
      // store.dispatch(notificationDetailsActions.updateNotificationsList({value: notifications.notifications}))
      store.dispatch(
        notificationDetailsActions.updatenewNotifications({ value: 1 }),
      );
      // notificationsDetails.newNotifications = 1
    }
  }
  if (notificationsDetails.newNotifications > 0) {
    document.getElementById("pending_notification").style.display = "block";
    document.getElementById("pending_notification").innerHTML =
      notificationsDetails.newNotifications;
  }
}

async function getNotificationReleaseInfo(env, release_id, load) {
  let param = {
    release_id,
    username: Koolioai.username,
  };
  localStorage.setItem("latest_release_id", JSON.stringify(param));
  /*We are calling the message info with the specific release id */

  await fetchMessageInfo(env, param)
    .then((res) => {
      let data = res.data.data;
      store.dispatch(
        NotificationUpdateSliceActions.updateNotificationInfo({ data: data }),
      );
      // if(!data.is_viewed){
      //     try{
      //         $('#half-modals').html(notificationModal)
      //         $('#half-modals').css('visibility', 'visible')
      //         koolioModals()
      // screenReloads = true
      // document.getElementById("annotation-modal-header").textContent = data.notification_text
      // //showing the feature updates
      // let notificationUpdatesFromBackend = res.data.data.notification_data
      // let specificUpdates = notificationUpdatesFromBackend.split('/n')
      // let list = document.getElementById("featureUpdate")
      // for (i = 0; i < specificUpdates.length; ++i) {
      //     let li = document.createElement('li')
      //     //checking for the emoji on every string so that we can highlight that list
      //     let isFirstCharacterEmojiOrNot =isFirstCharacterEmoji(specificUpdates[i])
      //     if(isFirstCharacterEmojiOrNot){
      //         let slicedString = specificUpdates[i].slice(2)
      //         li.innerText = slicedString
      //         li.style.listStyleType = 'none'
      //         li.style.margin = '5px 0'
      //         li.style.color = '#E2522B'
      //     }
      //     else{
      //         li.innerText = specificUpdates[i]
      //     }

      //     list.appendChild(li)
      // }
      // if(document.getElementById('featureUpdate').scrollHeight ==document.getElementById('featureUpdate').clientHeight){
      //     document.getElementById('scrollDown').style.visibility='hidden'
      //     document.getElementById('scrollUp').style.visibility='hidden'
      // }
      //     }
      //     catch(err){
      //     }
      //     // showing the notification modal to show the updates details

      // }
      // else if(!load){
      //     // We are showing a modal that user is already enjoying the updates
      //     $('#half-modals').html(notificationAlreadyOpenedModal)
      //     $('#half-modals').css('visibility', 'visible')
      //     koolioModals()
      // }
      return res.data.data;
    })
    .catch((err) => {
      // console.log(err)
    });
}

window.addEventListener("beforeunload", function () {
  // We are using local storage to keep count of the when screen is hard reloaded on clicking a notification
  let screenReloads = store.getState().screenReloads.screenReloads;
  localStorage.setItem("savedVariable", JSON.stringify(screenReloads));
});

window.addEventListener("load", function () {});

// Get Kooliospace code starts now, when user login for the first time we will fetch all the file which he has uploaded till now.
export function getKSFromLocal() {
  return new Promise((resolve, reject) => {
    let kooliospace = Koolioai.kooliospace;
    if (!kooliospace) {
      if (
        localStorage.getItem("kooliospace") === undefined ||
        localStorage.getItem("kooliospace") === null
      )
        reject(new Error("Key does not exist"));
      else {
        Koolioai.kooliospace = JSON.parse(localStorage.getItem("kooliospace"));
        resolve(Koolioai.kooliospace);
      }
    } else resolve(Koolioai.kooliospace);
  });
}

// when we upload a new file then we are storing it in local storage as well
export function updateKSFromLocal(kooliojob) {
  return getKooliospace().then((kooliospace) => {
    let exists = false;
    for (let i = 0; i < kooliospace.length; i++) {
      if (kooliospace[i].jobname === kooliojob.jobname) {
        for (let key in kooliojob) {
          kooliospace[i][key] = kooliojob[key];
        }
        exists = true;
        break;
      }
    }
    if (!exists) {
      kooliospace.push(kooliojob);
    }
    saveKooliospace(kooliospace);
    return Promise.resolve();
  });
}

export function updateKooliospace(kooliospace, sync = false) {
  if (!sync) {
    // ksUpdatedLocally.push(true)
  }
  Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.post(_config.api + "/kooliospace", kooliospace, {
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      }),
    )
    .catch(Koolioai.handleError);
  return updateKSFromLocal(kooliospace);
}

export function saveKooliospace(kooliospace) {
  kooliospace.forEach((kooliojob) => {
    //     if(recordedLocalResultstat && recordedLocalResultstat.jobname === kooliojob.jobname)
    //         kooliojob.resultStat = recordedLocalResultstat
  });
  kooliospace.sort((a, b) =>
    new Date(a.updated_at) > new Date(b.updated_at)
      ? -1
      : new Date(b.updated_at) > new Date(a.updated_at)
        ? 1
        : 0,
  );

  if (Koolioai.kooliospace) {
    Koolioai.kooliospace.forEach((kooliojob) => {
      if (kooliojob.resultStat) {
        const newKjob = kooliospace.find(
          (job) => job.jobname === kooliojob.jobname,
        );
        const index = kooliospace.indexOf(newKjob);
        if (index > -1) {
          kooliospace[index].resultStat = kooliojob.resultStat;
        }
      }
      if (
        kooliojob.status &&
        !kooliospace.some((job) => job.jobname === kooliojob.jobname) &&
        uploadProgressStatus.uploadedProjectList.some(
          (uploadedProject) => uploadedProject.id === kooliojob.jobname,
        )
      )
        kooliospace.push(kooliojob);
    });
  }
  Koolioai.kooliospace = kooliospace;
  localStorage.setItem("kooliospace", JSON.stringify(kooliospace));
}

export function hideplaylistloader(workspace = false) {
  let loader = workspace
    ? document.getElementById("workspace-closing-loader")
    : document.getElementById("playlist-loader");
  loader.style.display = "none";
}
export function showplaylistloader(workspace = false) {
  let loader = workspace
    ? document.getElementById("workspace-closing-loader")
    : document.getElementById("playlist-loader");
  // let viewportWidth = window.innerWidth;
  // let viewportHeight = window.innerHeight;

  // // Calculate the center position of the viewport
  // let centerX = viewportWidth / 2;
  // let centerY = viewportHeight / 2;

  // // Calculate the dimensions of the loader
  // let loaderWidth = loader.offsetWidth;
  // let loaderHeight = loader.offsetHeight;

  // // Calculate the position to center the loader
  // let loaderLeft = centerX - loaderWidth / 2;
  // let loaderTop = centerY - loaderHeight / 2;

  // // Set the loader's position
  // loader.style.left = loaderLeft + "px";
  // loader.style.top = loaderTop + "px";

  loader.style.display = "block";
}

export async function callUpdateProjectTitle(operation) {
  await updateProjectTitle(operation.operation.inputs.title);
}

export function cueFormatters(format) {
  function clockFormat(seconds, decimals, hrs = true) {
    const hours = parseInt(seconds / 3600, 10) % 24;
    const minutes = hrs
      ? parseInt(seconds / 60, 10) % 60
      : parseInt(seconds / 60, 10);
    const secs = (seconds % 60).toFixed(decimals);

    const result =
      (hrs ? (hours < 10 ? "0" + hours : hours) + ":" : "") +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (secs < 10 ? "0" + secs : secs);

    return result;
  }

  const formats = {
    seconds: function (seconds) {
      return seconds.toFixed(0);
    },
    thousandths: function (seconds) {
      return seconds.toFixed(3);
    },
    "hh:mm:ss": function (seconds) {
      return clockFormat(seconds, 0);
    },
    "hh:mm:ss.u": function (seconds) {
      return clockFormat(seconds, 1);
    },
    "hh:mm:ss.uu": function (seconds) {
      return clockFormat(seconds, 2);
    },
    "mm:ss.uu": function (seconds) {
      return clockFormat(seconds, 2, false);
    },
    "hh:mm:ss.uuu": function (seconds) {
      return clockFormat(seconds, 3);
    },
    "mm:ss.uuu": function (seconds) {
      return clockFormat(seconds, 3, false);
    },
    "mm:ss": function (seconds) {
      return clockFormat(seconds, 0, false);
    },
  };
  return formats[format];
}

let isOperationSyncedToIndexDB = true;

export async function checkForUnSyncedOperationInBackend(resultStat) {
  const kooliospace = await getKooliospace();
  const kooliojob = kooliospace.find((kj) => {
    return kj.jobname === resultStat.jobname;
  });
  if (kooliojob) {
    let latestLocalOpRefidData = JSON.parse(
      localStorage.getItem("latestLocalOpRefid"),
    );
    let refid;
    if (latestLocalOpRefidData && latestLocalOpRefidData[resultStat.jobname])
      refid = latestLocalOpRefidData[resultStat.jobname];
    else {
      refid = resultStat.refid;
    }
    const res = await getOperationsList(refid);
    if (res.status === 200) {
      return (isOperationSyncedToIndexDB = true);
    } else if (res.status === 201) {
      showplaylistloader();
      isOperationSyncedToIndexDB = false;
      await addUnsyncedOPerationsToIndexedDB(res.operations);
    } else if (res.status === 404) {
      showStatText("conflict", false);
      return Promise.resolve();
    } else {
      showStatText("unknown status", res.status);
      return Promise.resolve();
    }
  }
}

async function addUnsyncedOPerationsToIndexedDB(operations) {
  const jobname = store.getState().projectData.resultStat.jobname;
  const promises = operations.map((operation) => {
    let containsFile = false;
    let trackInfo;
    let operationData = {
      alias_id: operation.alias_id,
      inputs: operation.operation.inputs,
      operation: {
        inputs: operation.operation.inputs,
        worker: operation.operation.worker,
      },
      jobname: operation.jobname || jobname,
      parentRefid: operation.parent_refid,
      refid: operation.refid,
      worker: operation.operation.worker,
    };

    if (operation.operation.inputs.tracks) {
      trackInfo = {
        customClass: operation.operation.inputs.customClass,
        isImport: operation.operation.inputs.isImport,
      };
      containsFile = true;
    }
    return new Promise((r) =>
      r(
        addOperationsToDB(
          operationData,
          trackInfo,
          containsFile,
          "true",
          "pending",
          "false",
        ),
      ),
    );
  });
  Promise.all(promises).then(() => {
    isOperationSyncedToIndexDB = true;
    // getStatus()
  });
}

// for handling animation in kooliospace
export function mediaQueriess() {
  // if(localStorage.getItem("payingForPlan") || window.location.href.includes("subscription") || localStorage.getItem("subscribeTo")){
  //     document.getElementById('landing-screen').style.cssText = 'visibility: hidden !important'
  //     document.getElementById('blur-landing-modals').style.cssText = 'visibility: visible !important'
  // }
  // else if (jobname) {
  //     document.getElementById('landing-screen').style.cssText = 'visibility: hidden !important'
  // } else { document.getElementById('landing-screen').style.cssText = 'visibility: visible !important' }
  // //const duration = urlParams.get('loaded') && urlParams.get('loaded').toLowerCase() === 'true' ? 0 : 1
  const duration = 1;
  anime
    .timeline({
      easing: "easeOutExpo",
      duration: 750 * duration,
    })
    .add(
      {
        targets: ".landing-taglines",
        easing: "easeInExpo",
        opacity: 1,
        duration: 1000 * duration,
      },
      900 * duration,
    )
    .add(
      {
        targets: ".landing-taglines",
        easing: "easeOutBounce",
        scale: 0.5,
        duration: 2000 * duration,
      },
      "-=" + 200 * duration,
    )
    .add(
      {
        targets: ".landing-taglines",
        easing: "easeOutQuad",
        opacity: 0,
        duration: 1000 * duration,
      },
      "-=" + 900 * duration,
    )
    .add(
      {
        targets: ".koolio-logo",
        opacity: [0, 1],
      },
      "+=100",
    )
    .add({
      targets: [".landing-koolio-text", ".project-titles"],
      easing: "easeOutSine",
      opacity: [0, 1],
    })
    .add({
      targets: ".add-btn",
      easing: "easeOutExpo",
      opacity: [0, 1],
    })
    .add({
      complete: () => {
        // Your condition goes here
        if (document.querySelector(".koolio-landing")) {
          document.querySelector(".koolio-landing").classList.remove("d-none");
          document.getElementById("koolio-space").classList.remove("d-none");
        }
      },
    });
}
