import { exportHighQualityAudio } from "../utils";

const buzzsproutBaseApiUrl = "https://www.buzzsprout.com/api/";

export function publishAudioToBuzzsprout(
  showTitleOrPodcastID,
  episodeTitle,
  fileToUpload,
  options,
) {
  const publishInfo = {
    titleOrPodcastId: showTitleOrPodcastID,
    episodeTitle,
    apiProvider: "buzzsprout",
    charset: "utf-8",
  };
  return exportHighQualityAudio(options, false, true, publishInfo)
    .then((res) => Promise.resolve(res.data))
    .catch((error) => {
      // Handle the error here
      // console.error("An error occurred:", error);
      // You can choose to rethrow the error or handle it gracefully
      // throw error; // Rethrowing the error
    });
}
