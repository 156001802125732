import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../../redux/Store";
import { ACM, showStatText } from "../../../../utils/utils";
import { editTitleOperation } from "../../../../utils/ProjectTitleUtils";
import { showMessage } from "../../../../utils/koolio-workspace";

export default function ProjectTitle(props) {
  const [fileName, setFileName] = useState("New Recording");
  const projectTitle = useSelector((state) => state.projectTitle.projectTitle);
  const projectEnv = useSelector((state) => state.projectTitle.env);
  useEffect(() => {
    let newFile = props.title;
    if (newFile && projectEnv != "NestedRecording") {
      setFileName(props.title);
    } else if (newFile && projectEnv === "NestedRecording") {
      setFileName("New Recording");
    } else {
      let projectTitle = store.getState().projectData.resultStat.project_title; //! from here came from the title
      if (projectTitle) {
        setFileName(projectTitle);
      }
    }
  }, [props.title]);

  useEffect(() => {
    if (projectEnv == "NestedRecording") {
      setFileName("New Recording");
    } else if (projectTitle) {
      setFileName(projectTitle);
    }
  }, [projectTitle]);

  function editProjectname(event) {
    if (store.getState().viewEditMode.mode != "edit") {
      showStatText("operation not permitted in view mode");
      return false;
    }

    const permResponse = ACM.checkPermission("editProjectTitle");
    if (!permResponse.success) {
      return showStatText(permResponse.message);
    }

    if (event) event.preventDefault();
    const projectname = document.getElementById("projectname");
    const oldProjectName = projectname.textContent;
    projectname.contentEditable = true;
    projectname.focus();

    // Moving the cursor at the end of the title of project name
    const textNode = projectname.childNodes[0];
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(textNode, oldProjectName.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);

    projectname.addEventListener("keypress", function (e) {
      if (e.which === 13 || e.keyCode === 13) {
        e.target.blur();
        e.preventDefault();
      }
    });

    // Hide tooltip when double clicked
    projectname.setAttribute("title", "");

    projectname.removeEventListener("blur", handleBlur);
    projectname.addEventListener("blur", handleBlur);

    function handleBlur() {
      projectname.contentEditable = false;
      let newProjectName = projectname.textContent.trim();
      if (!newProjectName) {
        projectname.textContent = oldProjectName;
        showStatText("project title cannot be empty");
        return false;
      }
      if (oldProjectName !== newProjectName) {
        editTitle(newProjectName, oldProjectName);
      }
    }
  }

  function copyProjectname(event) {
    if (event) event.preventDefault();

    const projectname = document.getElementById("projectname");
    const text = projectname.textContent;

    navigator.clipboard.writeText(text);

    // showStatText("Project name copied to clipboard");
    showMessage("Text copied.", "", 2000, "success", "workspace");
  }

  function editTitle(newTitle, oldTitle) {
    editTitleOperation(newTitle, oldTitle);
  }

  return (
    <div className="text-center header-project-name">
      <span title="save project" id="save-icon" className="save-icon" hidden>
        <i className="fa fa-circle" aria-hidden="true"></i>
      </span>
      <span
        id="projectname"
        // onDoubleClick={(event) => projectnameDblClick(event)}
        className="project-name tt"
        data-bs-placement="bottom"
      >
        {fileName}
        {/* You can set the content of the project name here */}
      </span>
      <span id="projectTitle-edit-copy">
        <img
          title="projectTitle-edit"
          class="img-fluid"
          id="projectTitle-edit"
          src="/img/edit-orange.png"
          alt=""
          onClick={(event) => editProjectname(event)}
        />
        <img
          title="projectTitle-copy"
          class="img-fluid"
          id="projectTitle-copy"
          src="/img/copy-orange.png"
          alt=""
          onClick={(event) => copyProjectname(event)}
        />
      </span>
    </div>
  );
}
