import React from "react";
import MoreOption from "../../moreoptions/MoreOption";
import { createPortal } from "react-dom";
import "../../../../css/dark/MoreOptions.css";

const MoreOptions = (props) => {
  return createPortal(
    <>
      <div className="moreOptionContainer">
        <MoreOption onClose={props.onClose} />
      </div>
      ,
    </>,
    document.querySelector("body"),
  );
};

export default MoreOptions;
