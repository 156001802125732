import { createSlice } from "@reduxjs/toolkit";

const initialRestoreData = {
  isRestore: false,
  restoreProgressStatus: undefined,
  restoreResultStat: {},
  operationsToApply: [],
  currentResultStat: {},
  rejectBtnClick: false,
  fromRefId: String,
  toRefId: String,
};

const restoreSlice = createSlice({
  name: "restoreData",
  initialState: initialRestoreData,
  reducers: {
    storeFromRefId(state, action) {
      state.fromRefId = action.payload;
      // console.log(state.fromRefId);
    },
    storeToRefId(state, action) {
      state.toRefId = action.payload;
      // console.log(state.toRefId);
    },
    restoreReducerData(state, action) {
      // console.log(action);
      state.isRestore = true;
      state.restoreResultStat = action.payload.resultStat;
      state.operationsToApply = action.payload.operations;
      state.currentResultStat = action.payload.currentResultStat;
    },
    updateRestoreResultStatData(state, action) {
      state.restoreResultStat = action.payload.resultStat;
    },
    updateRestoreProgressStatus(state, action) {
      state.restoreProgressStatus = action.payload.status;
    },
    updateRestoreAnnotationsforCut(state, action) {
      state.restoreResultStat.speakers_segments[0].annotations =
        action.payload.newAnnotations;
    },
    updateRejectBtnClick(state, action) {
      state.rejectBtnClick = action.payload;
      // console.log(state.rejectBtnClick);
    },
    clearRestoreData(state) {
      state.isRestore = false;
      state.restoreProgressStatus = undefined;
      state.restoreResultStat = {};
      state.operationsToApply = [];
      state.currentResultStat = {};
    },
  },
});

export const restoreDataSliceActions = restoreSlice.actions;
export default restoreSlice.reducer;
