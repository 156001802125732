import React from "react";
import "../../../../css/dark/ShowNotesModal.css"; // Import the CSS file
import { createPortal } from "react-dom";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";

const ShowNotesModal = (props) => {
  useBootstrapTooltips();
  const handleOkay = () => {
    return props.onGenerate();
  };

  const handleClose = () => {
    // if (onClose) onClose();
    return props.onClose();
  };

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="half-modals-content" id="half-modals-content">
          <div className="half-modals-header">
            <p style={{ margin: 0 }} id="show-notes-confirmation-modal-header">
              Generate Notes
            </p>
            {/* <img title="close" className="img-fluid" onClick={handleClose} id="closeWorkspaceModalIcon" style={{ cursor: "pointer", visibility: "visible" }} src="static/img/exit-workspace.svg" alt="" width="13px" /> */}
          </div>
          <div
            className="half-modals-sub-header"
            id="show-notes-confirmation-modal-sub-header"
          >
            You can only use this once. Are you sure that this is the final
            version of your project?
          </div>
          <div className="half-modals-action">
            <div className="half-modals-actions text-center">
              <button
                type="button"
                id="btn-show-notes-okay"
                className="half-modals-action-button"
                onClick={handleOkay}
              >
                Yes
              </button>
              <button
                type="button"
                id=""
                className="half-modals-action-button"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};

export default ShowNotesModal;

//displaying the error modal when we do not receive expected show notes result in websocket

export const ShowNotesErrorModal = (props) => {
  const handleClose = () => {
    return props.onClose();
  };

  return createPortal(
    <>
      <div id="half-modals-container">
        <div className="half-modals-content" id="half-modals-content">
          <div
            className="half-modals-header"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <p
              style={{ margin: "0", fontSize: "16px" }}
              id="dissociation-confirmation-modal-header"
            >
              Unable to process your request at this moment! Please, try again
              after some time.
            </p>
            <img
              title="close"
              className="img-fluid"
              onclick=""
              id="closeWorkspaceModalIcon"
              style={{ cursor: "not-allowed", visibility: "hidden" }}
              src="/image/exit-workspace.svg"
              alt=""
              width="13px"
            />
          </div>
          <div
            className="half-modals-action "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="half-modals-actions">
              <button
                type="button"
                id=""
                className="half-modals-action-button"
                onClick={handleClose}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.querySelector("body"),
  );
};
