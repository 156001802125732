/* eslint-disable no-unused-vars */
import store from "../../redux/Store";
import { clickedRegionActions } from "../../redux/slice/ClickedRegionSlice";
import { uploadFileToWorkspaceActions } from "../../redux/slice/FileSelectedForWorkspaceSlice";
import { frontendApplySliceActions } from "../../redux/slice/FrontendApplySlice";
import { multiSelectWordActions } from "../../redux/slice/MultiSelectWordList";
import { nestedFileSliceActions } from "../../redux/slice/NestedImportSlice";
import { projectDataSliceActions } from "../../redux/slice/ProjectDataSlice";
import { projectTitleSliceActions } from "../../redux/slice/ProjectTitleSlice";
import { restoreDataSliceActions } from "../../redux/slice/restoreSlice";
import { wavesurferObjSFXActions } from "../../redux/slice/wavesurferObjSFX";
import { wavesurferObjActions } from "../../redux/slice/wavesurferobj";
import {
  convertAudioBufferToBlob,
  updateIndexedDBoperation,
  updateResultstatByJobname,
} from "../../utils/indexedDButils";
import {
  annotUIshiftSub,
  ajustAnnotandFadeAfterZoom,
  changeVolumeForRegion,
  checkAnnotisSpeakerAnnotorSfxAnnot,
  convertTimeToPixel,
  createAnnotDiv,
  create_UUID,
  loadSlickSliders,
  processAnnotations,
  insertAnnotations,
  loadTranscription,
  adjustFade,
  resetAnnotation,
  handleAnnotationShift,
  removeFade,
  renderFade,
  showStatText,
} from "../../utils/utils";
import {
  callUpdateProjectTitle,
  hideplaylistloader,
} from "../pages/kooliospace/kooliospaceUtils";
import { wordMultiSelect } from "../pages/workspace/Workspace";

function deleteannotation(annotarr) {
  if (annotarr) {
    annotarr.forEach((element) => {
      document.getElementById(element.id + "_div").remove();
    });
  }
}
function annotUIshift(resultStats, props) {
  annotUIshiftSub(resultStats, props);
}

async function applyTranscript(operation) {
  const inputs = operation.operation.inputs;
  let res = store.getState().projectData.resultStat;
  let resultStat = JSON.parse(JSON.stringify(res));
  resultStat.fades = res.fades ? res.fades : [];
  resultStat.speakers_src = res.speakers_src;
  resultStat.sfx_src = res.sfx_src;
  resultStat.refid = operation.refid;
  let id, content;
  if (operation.operation.worker == "addAnnotation") {
    createAnnotDiv(inputs.newAnnotation, inputs.newAnnotation.speaker_label);
    document.getElementById(inputs.newAnnotation.id + "_div").innerHTML =
      inputs.newAnnotation.content;
    id = inputs.newAnnotation.id;
    content = inputs.newAnnotation.content;
  } else {
    document.getElementById(inputs.annotation.id + "_div").innerHTML =
      inputs.annotation.content;
    id = inputs.annotation.id;
    content = inputs.annotation.content;
  }
  resultStat.speakers_segments.forEach((track) => {
    track.annotations.forEach((annot) => {
      if (annot.id == id) {
        annot.content = content;
      }
    });
  });
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
}
export async function applyChangeSpeaker(opereation) {
  const inputs = opereation.operation.inputs;
  const start = inputs.startTime;
  const end = inputs.endTime;
  let res = store.getState().projectData.resultStat;
  let resultStat = JSON.parse(JSON.stringify(res));
  resultStat.fades = res.fades ? res.fades : [];
  resultStat.speakers_src = res.speakers_src;
  resultStat.sfx_src = res.sfx_src;
  for (let i = 0; i < resultStat.speakers_segments.length; i++) {
    resultStat.speakers_segments[i].src = res.speakers_segments[i].src;
  }
  let topushindex = parseInt(inputs.customClass.match(/\d+$/));
  // resultStat.speakers_segments.forEach((segment, index) => {
  //   if (segment.speaker_label == inputs.customClass) {
  //     topushindex = index;
  //   }
  // });
  let changedAnnot = [];
  // Traverse all the speaker segments
  resultStat.speakers_segments.forEach((segment, j) => {
    segment.annotations = segment.annotations.filter((annot, i) => {
      let currannot = { ...annot };
      let annotationDuration = annot.end_time - annot.start_time;
      let overlapStart = Math.max(annot.start_time, start);
      let overlapEnd = Math.min(annot.end_time, end);
      let overlapDuration = overlapEnd - overlapStart;

      // Check if the annotation's start and end time falls inside the provided start and end time
      // or if the overlap duration is at least 50% of the annotation's duration
      if (
        (annot.start_time >= start && annot.end_time <= end) ||
        overlapDuration >= 0.5 * annotationDuration
      ) {
        currannot.speaker_label = `spk_${topushindex}`;
        changedAnnot.push(currannot);

        // Return false to remove this annotation from the original array
        return false;
      }

      // Return true to keep this annotation in the original array
      return true;
    });
  });
  let matchingSegment, matchingSegmentIndex;
  resultStat.speakers_segments.forEach((segment, index) => {
    if (segment.speaker_label == inputs.customClass) {
      matchingSegment = segment;
      matchingSegmentIndex = index;
    }
  });
  changedAnnot.forEach((annot) => {
    let newID = annot.id.split("_");
    let speakerNumber = inputs.customClass.split("-")[1];
    let newId = newID[0] + "_" + speakerNumber + "_" + newID[2];
    let newAnnot = { ...annot, id: newId };
    matchingSegment.annotations.push(newAnnot);
  });
  // change the annotaions in the drag and add to new changed track
  resultStat.speakers_segments[matchingSegmentIndex].annotations = [
    ...matchingSegment.annotations,
  ];
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  loadTranscription(resultStat);
}
export async function applySubVolume(operation, props) {
  const { wavesurferObj, wavesurferObjSFX } = { ...props };
  const inputs = operation.operation.inputs;
  // Ensure buffers are ready
  await ensureBuffersReady(wavesurferObj, wavesurferObjSFX);
  let resultStat = { ...store.getState().projectData.resultStat };
  let region = {
      start: inputs.startTime,
      end: inputs.endTime,
    },
    currentSegment;
  if (inputs.selectedCustomClasses[0] == "speakers-0") {
    currentSegment = wavesurferObj;
  } else {
    currentSegment = wavesurferObjSFX;
  }
  let buffer = changeVolumeForRegion(currentSegment, region, inputs.gain);
  if (inputs.selectedCustomClasses[0] == "speakers-0")
    resultStat.speakers_src = buffer;
  else resultStat.sfx_src = buffer;
  resultStat.refid = operation.refid;
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  store.dispatch(wavesurferObjActions.updateWavesurferObj(wavesurferObj));
}
export async function applyAutoLevel(operation, props) {
  let oldResultStat = store.getState().projectData.resultStat;
  const { wavesurferObj, wavesurferObjGlobal } = { ...props };
  const inputs = operation.operation.inputs;
  wavesurferObj.params.normalize = true;
  wavesurferObj.drawBuffer();
  store.dispatch(
    wavesurferObjActions.updateWavesurferObj({
      wavesurferObj: wavesurferObj,
    }),
  );
  let resultStat = { ...oldResultStat };
  resultStat.refid = operation.refid;
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
}
export async function ensureBuffersReady(wavesurferObj, wavesurferObjSFX) {
  if (!wavesurferObj.backend.buffer || !wavesurferObjSFX.backend.buffer) {
    // Wait for the buffers to be ready
    await new Promise((resolve) => {
      const handleReady = () => {
        if (wavesurferObj.backend.buffer && wavesurferObjSFX.backend.buffer) {
          resolve();
        }
      };
      wavesurferObj.once("ready", handleReady);
      wavesurferObjSFX.once("ready", handleReady);
    });
  }
}

const handleCut = async (
  operation,
  props,
  gapremoval = null,
  curr_word_list = null,
  delTrack = null,
) => {
  let currentsegment, otherSegment, globalSegment;
  await ensureBuffersReady(props.wavesurferObj, props.wavesurferObjSFX);
  const inputs = operation.operation.inputs;
  let trackType =
    operation.operation.inputs.trackType.split("-")[0] === "sfx"
      ? "sfx_segments"
      : "speakers_segments";
  let resultStat = store.getState().projectData.resultStat;
  let lastEndIndex = 0;
  globalSegment = props.wavesurferObjGlobal;
  if (trackType == "sfx_segments") {
    currentsegment = props.wavesurferObjSFX;
    otherSegment = props.wavesurferObj;
  } else {
    currentsegment = props.wavesurferObj;
    otherSegment = props.wavesurferObjSFX;
  }
  const originalBuffer = currentsegment.backend.buffer;
  const secondaryBuffer = otherSegment.backend.buffer;

  let timeList = [];
  let updatedAnnotation;
  if (curr_word_list) {
    timeList = curr_word_list;
    updatedAnnotation = handleAnnotationShift(trackType, timeList, resultStat);
  } else if (gapremoval) {
    timeList = gapremoval;
    updatedAnnotation = handleAnnotationShift(
      trackType,
      null,
      resultStat,
      false,
      timeList,
    );
  } else if (delTrack) {
    let deleteannot = [];
    resultStat.speakers_segments.forEach((track) => {
      if (track.speaker_label == delTrack) {
        let timeObject = {
          wordid: "",
          start: parseFloat(track.start_time),
          end: parseFloat(track.end_time),
          word: "",
          customClass: delTrack,
        };
        timeList.push(timeObject);
        track.annotations.forEach((annot) => deleteannot.push(annot));
      }
    });
    deleteannotation(deleteannot);
  } else {
    const region = { start: inputs.startTime, end: inputs.endTime };
    if (region) {
      await new Promise((resolve) => setTimeout(resolve, 0));
      updatedAnnotation = handleAnnotationShift(
        trackType,
        false,
        resultStat,
        region,
      );
      let timeObject = {
        start: region.start,
        end: region.end,
      };
      timeList.push(timeObject);
    } else {
      showStatText("select a region for cut");
      return;
    }
  }
  let oldfade = resultStat.fades ? resultStat.fades : [];
  resultStat = JSON.parse(JSON.stringify(resultStat));
  resultStat.fades = oldfade;
  if (delTrack) {
    delete resultStat.speakers[delTrack];
    resultStat.speakers_segments = resultStat.speakers_segments.filter(
      (track) => track.speaker_label !== delTrack,
    );
    updatedAnnotation = handleAnnotationShift(
      trackType,
      null,
      resultStat,
      false,
      timeList,
    );
  }
  timeList.sort((a, b) => a.start - b.start);
  const sampleRate = originalBuffer.sampleRate;
  const secondarySampleRate = secondaryBuffer.sampleRate;
  const numberOfChannels = originalBuffer.numberOfChannels;
  const secondaryNumberOfChannels = secondaryBuffer.numberOfChannels;
  let totalCutLength = 0;
  timeList.forEach((time) => {
    totalCutLength += Math.floor((time.end - time.start) * sampleRate);
  });
  if (trackType === "sfx_segments") {
    totalCutLength = 0;
  } else {
    let endtime = 0;
    resultStat.sfx_segments.forEach((element) => {
      element.annotations?.forEach((annot) => {
        endtime = Math.max(endtime, annot.end_time * originalBuffer.sampleRate);
      });
    });
    if (
      resultStat.duration * originalBuffer.sampleRate - totalCutLength <
      endtime
    ) {
      totalCutLength =
        resultStat.duration * originalBuffer.sampleRate - endtime;
    }
  }
  const newBufferLength = originalBuffer.length - totalCutLength;
  const newBuffer = currentsegment.backend.ac.createBuffer(
    numberOfChannels,
    newBufferLength,
    sampleRate,
  );
  const newSecondaryBuffer = otherSegment.backend.ac.createBuffer(
    secondaryBuffer.numberOfChannels,
    secondaryBuffer.length - totalCutLength,
    secondarySampleRate,
  );
  let sourceOffset = 0;
  let targetOffset = 0;

  timeList.forEach((time, index) => {
    const cutStartIndex = Math.floor(time.start * sampleRate);
    const cutEndIndex = Math.floor(time.end * sampleRate);

    // Copy the portion before the cut
    const copyLength = Math.max(
      0,
      Math.min(cutStartIndex - sourceOffset, newBufferLength - targetOffset),
    );
    if (copyLength > 0) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        newBuffer
          .getChannelData(channel)
          .set(
            originalBuffer
              .getChannelData(channel)
              .subarray(sourceOffset, sourceOffset + copyLength),
            targetOffset,
          );
      }
      targetOffset += copyLength;
    }
    // Move the source offset to after the cut
    sourceOffset = Math.min(cutEndIndex, originalBuffer.length);
  });

  // Copy any remaining audio after the last cut
  if (sourceOffset < originalBuffer.length) {
    const remainingLength = Math.min(
      originalBuffer.length - sourceOffset,
      newBufferLength - targetOffset,
    );
    for (let channel = 0; channel < numberOfChannels; channel++) {
      newBuffer
        .getChannelData(channel)
        .set(
          originalBuffer
            .getChannelData(channel)
            .subarray(sourceOffset, sourceOffset + remainingLength),
          targetOffset,
        );
    }
  }

  const newDuration = resultStat.duration - totalCutLength / sampleRate;
  let newResultStat = resultStat;
  if (trackType == "sfx_segments") {
    //const channelData = newBuffer.getChannelData(0);
    newResultStat.sfx_src = newBuffer;
    newResultStat.speakers_src = newSecondaryBuffer;
    newResultStat.sfx_segments[0].annotations = updatedAnnotation;
  } else {
    //const channelData = newSecondaryBuffer.getChannelData(0);
    newResultStat.sfx_src = newSecondaryBuffer;
    newResultStat.speakers_src = newBuffer;
    newResultStat.speakers_segments.forEach((track, index) => {
      track.annotations = [];
      track.annotations = updatedAnnotation[index];
    });
  }
  newResultStat.refid = operation.refid;
  newResultStat.duration = newDuration;
  currentsegment.loadDecodedBuffer(newBuffer);
  otherSegment.loadDecodedBuffer(newSecondaryBuffer);
  if (currentsegment == props.wavesurferObj) {
    globalSegment.loadDecodedBuffer(newBuffer);
  } else {
    globalSegment.loadDecodedBuffer(newSecondaryBuffer);
  }
  globalSegment.drawBuffer();

  const durationInSeconds = newBuffer.length / newBuffer.sampleRate;
  const newWidth = currentsegment.params.minPxPerSec * durationInSeconds;
  // if (document.getElementById("transcriptions_div"))
  //   document.getElementById("transcriptions_div").style.width =
  //     `${newWidth}px`;
  // if (document.getElementById("transcriptions_div_sfx")) {
  //   document.getElementById("transcriptions_div_sfx").style.width =
  //     `${newWidth}px`;
  // }
  resetAnnotation();
  if (newResultStat.fades)
    newResultStat = adjustFade(
      newResultStat,
      timeList,
      "cut",
      trackType === "speakers_segments" ? "speakers-0" : trackType,
    );

  //update redux for wavesurfer obj
  annotUIshift(newResultStat, props);
  store.dispatch(
    wavesurferObjActions.updateWavesurferObj({
      wavesurferObj: props.wavesurferObj,
    }),
  );
  store.dispatch(
    wavesurferObjSFXActions.updateWavesurferObjSFX({
      wavesurferObjSFX: props.wavesurferObjSFX,
    }),
  );
  store.dispatch(
    projectDataSliceActions.projectReducerData({ resultStat: newResultStat }),
  );

  return newResultStat;
};

export async function applySilence(operation, props) {
  const { wavesurferObj, wavesurferObjGlobal } = { ...props };
  const inputs = operation.operation.inputs;
  let oldResultStat = store.getState().projectData.resultStat;
  const originalBuffer = wavesurferObj.backend.buffer;
  let insertedBuffer = originalBuffer.getChannelData(0).slice();
  const start = inputs.startTime;
  const end = inputs.endTime;

  // Calculate the start index and length for inserting silence
  const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);
  const insertSilenceLength = Math.floor(
    (end - start) * originalBuffer.sampleRate,
  );

  // Insert silence into the buffer at the specified index
  insertedBuffer.fill(
    0,
    insertStartIndex,
    insertStartIndex + insertSilenceLength,
  );
  // Create a new AudioBuffer with the inserted silence
  const newBuffer = props.wavesurferObj.backend.ac.createBuffer(
    1,
    insertedBuffer.length,
    originalBuffer.sampleRate,
  );

  newBuffer.copyToChannel(insertedBuffer, 0);

  let resultStat = { ...oldResultStat };
  resultStat.speakers_segments = [];

  oldResultStat.speakers_segments.map((segment, i) => {
    let annots = [...segment.annotations];
    //let new
    resultStat.speakers_segments.push({ ...segment, annotations: annots });
    //return segment.annotations = [...oldResultStat.speakers_segments[i].annotations]
  });
  //resultStat.speakers_segments[0].annotations = [...oldResultStat.speakers_segments[0].annotations]
  resultStat.refid = operation.refid;
  resultStat.speakers_src = newBuffer;
  let currentAnnotations = resultStat.speakers_segments[0].annotations;
  let newAnnotations = [...currentAnnotations];

  //for (let i = 0; i < currentAnnotations.length; i++){
  //    if(currentAnnotations[i].end_time < start) {
  //        newAnnotations.push(currentAnnotations[i])
  //    }
  //    else if(currentAnnotations[i].start_time > end) {
  //        newAnnotations.push(currentAnnotations[i])
  //    }
  //}
  resultStat.speakers_segments[0].annotations = newAnnotations;
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  // Update the waveform display with the inserted buffer
  props.wavesurferObj.loadDecodedBuffer(newBuffer);
  props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
}

export function applyPasteAnnotations(
  startTime,
  endTime,
  copiedAnnotations,
  trackType = null,
  refid = false,
) {
  let newAnnotations = [];
  let deleteannot = [];
  const resultStat = store.getState().projectData.resultStat;
  // Remove the annotations from resultStat.speakers_segments which falls under the provided start and end time
  if (trackType == "sfx_segments") {
    resultStat.sfx_segments[0].annotations.forEach((annot) => {
      newAnnotations.push(annot);
    });
    copiedAnnotations.forEach((annot) => {
      let newAnnot = { ...annot };
      newAnnot.start_time = startTime.toFixed(2).toString();
      newAnnot.end_time = endTime.toFixed(2).toString();
      newAnnotations.push(newAnnot);
    });
  } else {
    resultStat.speakers_segments.forEach((track) => {
      let trackAnnotation = [];
      track.annotations.forEach((annotation) => {
        const annotationDuration = annotation.end_time - annotation.start_time;
        const annotationOverlapDuration =
          Math.min(annotation.end_time, endTime) -
          Math.max(annotation.start_time, startTime);

        if (!(annotationOverlapDuration >= 0.55 * annotationDuration)) {
          trackAnnotation.push(annotation);
        } else {
          deleteannot.push(annotation);
        }
      });
      if (track.start_time <= startTime && track.end_time >= startTime) {
        copiedAnnotations.forEach((annot) => {
          let newAnnot = { ...annot };
          newAnnot.start_time = parseFloat(annot.start_time)
            .toFixed(2)
            .toString();
          newAnnot.end_time = parseFloat(annot.end_time).toFixed(2).toString();
          newAnnot.speaker_label =
            "spk_" + trackType.charAt(trackType.length - 1);
          // newAnnot.id =
          //   "spk_" +
          //   trackType.charAt(trackType.length - 1) +
          //   "_" +
          //   create_UUID();
          if (annot.transcribed) {
            let file = {
              id: newAnnot.id,
              start_time: newAnnot.start_time,
              end_time: newAnnot.end_time,
              refid,
            };
            store.dispatch(nestedFileSliceActions.addNestedFiles({ file }));
          }
          trackAnnotation.push(newAnnot);
        });
      }
      newAnnotations.push(trackAnnotation);
    });
  }
  if (deleteannot) {
    deleteannotation(deleteannot);
  }
  return newAnnotations;
}

export async function applyPasteOperation(
  operation,
  isRestore,
  props,
  trackType,
) {
  const { wavesurferObj, wavesurferObjSFX, wavesurferObjGlobal } = { ...props };
  // Ensure buffers are ready
  await ensureBuffersReady(wavesurferObj, wavesurferObjSFX);
  let oldResultStat = store.getState().projectData.resultStat;
  let currentSegment =
    trackType === "sfx_segments" ? wavesurferObjSFX : wavesurferObj;
  const originalBuffer = currentSegment.backend.buffer;
  const inputs = operation.operation.inputs;
  const operationtype = inputs.pasteKey;

  let start;
  if (operationtype == "after") {
    start = inputs.endTime;
  } else if (operationtype == "center") {
    start = inputs.startTime;
  } else if (operationtype == "before") {
    start = inputs.startTime - inputs.clipboardDuration;
  }
  const end = start + inputs.clipboardDuration;
  let copiedBuffer = inputs.tracks[0].src;

  // Check if the copied buffer is in Float32Array format
  if (!(copiedBuffer instanceof Float32Array)) {
    copiedBuffer = new Float32Array(copiedBuffer);
  }
  let copiedAnnotations = inputs.tracks[0].annotations;

  // Calculate the start index for pasting
  const pasteStartIndex = Math.floor(start * originalBuffer.sampleRate);

  // Create a new buffer to store the pasted data
  let pastedBuffer = originalBuffer.getChannelData(0).slice();

  // Check if the paste operation would go out of bounds
  if (pasteStartIndex + copiedBuffer.length > pastedBuffer.length) {
    // If so, create a new buffer with the necessary length
    pastedBuffer = new Float32Array(pasteStartIndex + copiedBuffer.length);
    pastedBuffer.set(originalBuffer.getChannelData(0));
  }

  // Paste the copied data into the buffer
  pastedBuffer.set(copiedBuffer, pasteStartIndex);

  // Create a new AudioBuffer with the pasted data
  const newBuffer = currentSegment.backend.ac.createBuffer(
    1,
    pastedBuffer.length,
    originalBuffer.sampleRate,
  );
  newBuffer.copyToChannel(pastedBuffer, 0);

  const resultStat = JSON.parse(JSON.stringify(oldResultStat));
  resultStat.fades = oldResultStat.fades ? oldResultStat.fades : [];
  resultStat.speakers_src = oldResultStat.speakers_src;
  resultStat.sfx_src = oldResultStat.sfx_src;

  // Calculate the new end time after pasting
  const newEndTime = pastedBuffer.length / originalBuffer.sampleRate;

  // Extend both tracks if necessary
  if (
    newEndTime > wavesurferObj.getDuration() ||
    newEndTime > wavesurferObjSFX.getDuration()
  ) {
    // Extend speaker track
    if (trackType !== "sfx_segments") {
      resultStat.duration = newEndTime;
      resultStat.sfx_duration = newEndTime;
      let newTracks = [];
      let deleteannot = [];
      resultStat.speakers_segments.forEach((track) => {
        if (track.start_time < start && track.end_time >= start) {
          track.end_time += newEndTime - oldResultStat.duration;
          newTracks.push(track);
        } else if (track.start_time > start) {
          track.annotations.forEach((annot) => {
            deleteannot.push(annot);
          });
        } else {
          newTracks.push(track);
        }
      });
      deleteannotation(deleteannot);
      resultStat.speakers_segments = [];
      resultStat.speakers_segments = [...newTracks];
      resultStat.sfx_segments.forEach((track) => {
        track.end_time += newEndTime;
      });
      resultStat.speakers_src = newBuffer;
      wavesurferObj.loadDecodedBuffer(newBuffer);
      wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
    } else {
      // If pasting on SFX, create a new buffer for speakers with silence
      const speakerBuffer = wavesurferObj.backend.ac.createBuffer(
        1,
        pastedBuffer.length,
        originalBuffer.sampleRate,
      );
      speakerBuffer.copyToChannel(
        wavesurferObj.backend.buffer.getChannelData(0),
        0,
      );
      resultStat.speakers_src = speakerBuffer;
      wavesurferObj.loadDecodedBuffer(speakerBuffer);
      wavesurferObjGlobal.loadDecodedBuffer(speakerBuffer);
    }

    // Extend SFX track
    if (trackType === "sfx_segments") {
      resultStat.sfx_src = newBuffer;
      wavesurferObjSFX.loadDecodedBuffer(newBuffer);
    } else {
      // If pasting on speakers, create a new buffer for SFX with silence
      const sfxBuffer = wavesurferObjSFX.backend.ac.createBuffer(
        1,
        pastedBuffer.length,
        originalBuffer.sampleRate,
      );
      sfxBuffer.copyToChannel(
        wavesurferObjSFX.backend.buffer.getChannelData(0),
        0,
      );
      resultStat.sfx_src = sfxBuffer;
      wavesurferObjSFX.loadDecodedBuffer(sfxBuffer);
    }
  } else {
    // Original behavior if no extension is needed
    if (trackType === "sfx_segments") {
      resultStat.sfx_src = newBuffer;
      wavesurferObjSFX.loadDecodedBuffer(newBuffer);
    } else {
      let copiedDuration = copiedBuffer.length / originalBuffer.sampleRate;
      let changeIntrackLength = 0;
      let newtracks = [];
      let deleteannot = [];
      resultStat.speakers_segments.forEach((track, index) => {
        if (
          track.start_time <= start &&
          track.end_time > start &&
          track.end_time < start + copiedDuration
        ) {
          changeIntrackLength = start + copiedDuration - track.end_time;
          track.end_time += changeIntrackLength; // this is calculating the extra time we are adding to particular segment for multispeaker file
          newtracks.push(track);
          // now we have to iterate over next tracks to keep track of which track we want to keep and which one we have to discard
          let duration = 0;
          let trackInfo;
          for (
            let i = index + 1;
            i < resultStat.speakers_segments.length;
            i++
          ) {
            duration +=
              resultStat.speakers_segments[i].end_time -
              resultStat.speakers_segments[i].start_time;
            if (duration > changeIntrackLength) {
              trackInfo = i;
              break;
            }
          }
          //remove the tracks from index to trackInfo-1
          let deleteannot = [];
          let gap = 0;
          for (let i = index + 1; i < trackInfo; i++) {
            gap +=
              resultStat.speakers_segments[i].end_time -
              resultStat.speakers_segments[i].start_time;
            // newResultStat.speakers_segments[i].annotations.forEach(
            //   (annot) => {
            //     deleteannot.push(annot);
            //   },
            // );
          }

          let pendingGapInLastSeg = changeIntrackLength - gap; // this will take care of the pending gap in the segement where we have to only reduce a portion of track
          let pendingGapInLastSegAnnotsum = 0; // this is to store the sum of annot duration in the last track where the pasted buffer is overlapping
          let trackannot = [];
          let annotReached = false;
          let copytrack = {
            ...resultStat.speakers_segments[trackInfo],
            start_time:
              resultStat.speakers_segments[trackInfo].start_time +
              pendingGapInLastSeg,
          };
          newtracks.push(copytrack);

          deleteannotation(deleteannot);
        } else if (track.start_time <= start && track.end_time < start) {
          newtracks.push(track);
        } else if (track.start_time > start + copiedDuration) {
          newtracks.push(track);
        } else {
          newtracks.push(track);
        }
      });
      resultStat.speakers_segments = [];
      resultStat.speakers_segments = [...newtracks];
      resultStat.speakers_src = newBuffer;
      wavesurferObj.loadDecodedBuffer(newBuffer);
      wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
    }
  }
  let newannot, toInsertSegment;
  resultStat.speakers_segments.forEach((track) => {
    if (track.start_time <= start && track.end_time >= start) {
      toInsertSegment = track.speaker_label;
    } else if (track.start_time > start) {
      track.start_time += end - start;
      track.end_time += end - start;
    }
  });

  if (trackType === "speakers_segments") {
    newannot = applyPasteAnnotations(
      start,
      end,
      copiedAnnotations,
      toInsertSegment,
      operation.refid,
    );
    resultStat.speakers_segments.forEach((track, index) => {
      track.annotations = [];
      track.annotations = newannot[index];
    });
  } else if (trackType === "sfx_segments") {
    newannot = applyPasteAnnotations(start, end, copiedAnnotations, trackType);
    resultStat.sfx_segments = [];
    oldResultStat.sfx_segments.forEach((seg) => {
      resultStat.sfx_segments.push({
        ...seg,
        annotations: newannot,
      });
    });
  }

  resultStat.refid = operation.refid;

  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  annotUIshift(resultStat, props);
  return resultStat;
}

export function applyInsertAnnotations(
  affectedTrack,
  startTime,
  insertDuration,
  copiedAnnotations,
  trackType,
  refid = null,
) {
  let newAnnotations = [];
  const resultStat = store.getState().projectData.resultStat;
  // Shift all the annotations start and end time to given duration
  if (trackType == "sfx_segments") {
    resultStat.sfx_segments[0].annotations.forEach((annot) => {
      if (annot.start_time >= startTime) {
        let newAnnotation = {
          ...annot,
          start_time: (parseFloat(annot.start_time) + insertDuration)
            .toFixed(2)
            .toString(),
          end_time: (parseFloat(annot.end_time) + insertDuration)
            .toFixed(2)
            .toString(),
        };
        newAnnotations.push(newAnnotation);
      } else {
        newAnnotations.push(annot);
      }
    });
    if (affectedTrack === "sfx_segments")
      copiedAnnotations.forEach((annot) => {
        let newAnnot = { ...annot };
        newAnnot.start_time = startTime.toFixed(2).toString();
        newAnnot.end_time = (startTime + insertDuration).toFixed(2).toString();
        newAnnotations.push(newAnnot);
      });
  } else {
    resultStat.speakers_segments.forEach((track) => {
      let trackAnnotation = [];
      track.annotations.forEach((annotation) => {
        if (annotation.start_time >= startTime) {
          let newAnnotation = {
            ...annotation,
            start_time: (parseFloat(annotation.start_time) + insertDuration)
              .toFixed(2)
              .toString(),
            end_time: (parseFloat(annotation.end_time) + insertDuration)
              .toFixed(2)
              .toString(),
          };
          trackAnnotation.push(newAnnotation);
        } else {
          trackAnnotation.push(annotation);
        }
      });
      if (track.speaker_label == trackType) {
        if (
          affectedTrack !== "sfx_segments" &&
          track.start_time < startTime &&
          track.end_time > startTime
        )
          copiedAnnotations.forEach((annot) => {
            let newAnnot = { ...annot };
            newAnnot.start_time = parseFloat(annot.start_time)
              .toFixed(2)
              .toString();
            newAnnot.end_time = parseFloat(annot.end_time)
              .toFixed(2)
              .toString();
            newAnnot.speaker_label =
              "spk_" + trackType.charAt(trackType.length - 1);
            // newAnnot.id =
            //   "spk_" +
            //   trackType.charAt(trackType.length - 1) +
            //   "_" +
            //   create_UUID();
            if (newAnnot.transcribed) {
              let file = {
                id: newAnnot.id,
                start_time: newAnnot.start_time,
                end_time: newAnnot.end_time,
                refid,
              };
              store.dispatch(nestedFileSliceActions.addNestedFiles({ file }));
            }
            trackAnnotation.push(newAnnot);
          });
      }
      newAnnotations.push(trackAnnotation);
    });
  }
  return newAnnotations;
}

export async function applyInsertOperation(
  operation,
  isRestore,
  props,
  trackType,
) {
  const { wavesurferObj, wavesurferObjSFX, wavesurferObjGlobal } = { ...props };
  // Ensure buffers are ready
  await ensureBuffersReady(wavesurferObj, wavesurferObjSFX);
  let oldResultStat = store.getState().projectData.resultStat;
  let currentSegment =
    trackType === "sfx_segments" ? wavesurferObjSFX : wavesurferObj;
  const originalBuffer = currentSegment.backend.buffer;
  const inputs = operation.operation.inputs;
  const start = inputs.insertKey == "after" ? inputs.endTime : inputs.startTime;
  const end = start + inputs.clipboardDuration;
  let copiedBuffer = inputs.tracks[0].src;
  // Check if the copied buffer is in Float32Array format
  if (!(copiedBuffer instanceof Float32Array)) {
    copiedBuffer = new Float32Array(copiedBuffer);
  }
  let copiedAnnotations = inputs.tracks[0].annotations;
  console.log(copiedAnnotations);
  //const copiedBuffer = new Float32Array(
  //    (end - start) * originalBuffer.sampleRate
  //)
  // Calculate the insertion point in terms of sample index
  const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);

  // Create a new buffer to store the modified audio
  const newBuffer = currentSegment.backend.ac.createBuffer(
    1,
    originalBuffer.length + copiedBuffer.length,
    originalBuffer.sampleRate,
  );

  // Copy the audio before the insertion point
  newBuffer
    .getChannelData(0)
    .set(originalBuffer.getChannelData(0).slice(0, insertStartIndex));

  // Insert the copied data at the specified position
  newBuffer.getChannelData(0).set(copiedBuffer, insertStartIndex);

  // Copy the audio after the insertion point
  newBuffer
    .getChannelData(0)
    .set(
      originalBuffer.getChannelData(0).slice(insertStartIndex),
      insertStartIndex + copiedBuffer.length,
    );

  let resultStat = JSON.parse(JSON.stringify(oldResultStat));
  resultStat.fades = oldResultStat.fades ? oldResultStat.fades : [];
  resultStat.speakers_src = oldResultStat.speakers_src;
  resultStat.sfx_src = oldResultStat.sfx_src;
  let newannot, toInsertSegment;
  resultStat.speakers_segments.forEach((track) => {
    if (track.start_time <= start && track.end_time >= start) {
      toInsertSegment = track.speaker_label;
    } else if (track.start_time > start) {
      track.start_time += end - start;
      track.end_time += end - start;
    }
  });
  newannot = applyInsertAnnotations(
    trackType,
    start,
    copiedBuffer.length / originalBuffer.sampleRate,
    copiedAnnotations,
    toInsertSegment,
    operation.refid,
  );
  resultStat.speakers_segments.forEach((track, index) => {
    track.annotations = [];
    track.annotations = newannot[index];
  });
  // newResultStat.speakers_segments[0].annotations = newannot;
  newannot = applyInsertAnnotations(
    trackType,
    start,
    copiedBuffer.length / originalBuffer.sampleRate,
    copiedAnnotations,
    "sfx_segments",
  );
  resultStat.sfx_segments = [];
  oldResultStat.sfx_segments.forEach((seg) => {
    resultStat.sfx_segments.push({
      ...seg,
      annotations: newannot,
    });
  });

  resultStat.refid = operation.refid;

  // Update the waveform display with the new buffer
  currentSegment.loadDecodedBuffer(newBuffer);
  let newEndTime = props.wavesurferObj.getDuration();
  resultStat.duration = newEndTime;
  resultStat.sfx_duration = newEndTime;
  resultStat.speakers_segments.forEach((track) => {
    if (track.start_time < start && track.end_time >= start) {
      track.end_time += newEndTime - oldResultStat.duration;
    } else if (track.start_time > start) {
      track.end_time += newEndTime - oldResultStat.duration;
      track.start_time += newEndTime - oldResultStat.duration;
    }
  });
  resultStat.sfx_segments.forEach((track) => {
    track.end_time += newEndTime;
  });
  let otherSegment;
  if (trackType == "sfx_segments") {
    otherSegment = props.wavesurferObj; // If inserted in SFX, append silence to speaker
  } else {
    otherSegment = props.wavesurferObjSFX; // If inserted in speaker, append silence to SFX
  }
  const otherBuffer = otherSegment.backend.buffer;
  const silenceBuffer = otherSegment.backend.ac.createBuffer(
    1,
    copiedBuffer.length,
    otherBuffer.sampleRate,
  );
  const combinedBuffer = otherSegment.backend.ac.createBuffer(
    1,
    otherBuffer.length + silenceBuffer.length,
    otherBuffer.sampleRate,
  );
  combinedBuffer
    .getChannelData(0)
    .set(otherBuffer.getChannelData(0).slice(0, insertStartIndex));
  combinedBuffer
    .getChannelData(0)
    .set(silenceBuffer.getChannelData(0), insertStartIndex);
  combinedBuffer
    .getChannelData(0)
    .set(
      otherBuffer.getChannelData(0).slice(insertStartIndex),
      insertStartIndex + silenceBuffer.length,
    );
  otherSegment.loadDecodedBuffer(combinedBuffer);
  if (trackType !== "sfx_segments") {
    resultStat.speakers_src = newBuffer;
    wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
    // const channelData = combinedBuffer.getChannelData(0); // Assuming mono audio, change index for stereo
    // const float32Array = new Float32Array(channelData);
    resultStat.sfx_src = combinedBuffer;
  } else {
    resultStat.speakers_src = combinedBuffer;
    wavesurferObjGlobal.loadDecodedBuffer(combinedBuffer);
    // const channelData = newBuffer.getChannelData(0); // Assuming mono audio, change index for stereo
    // const float32Array = new Float32Array(channelData);
    resultStat.sfx_src = newBuffer;
  }
  if (resultStat.fades)
    resultStat = adjustFade(
      resultStat,
      [{ start, end }],
      "insert",
      trackType === "speakers_segments" ? "speakers-0" : trackType,
    );
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  annotUIshift(resultStat, props);
  // Update the timeline to reflect the new duration
  currentSegment.drawer.updateSize();
}

export async function applyMultiSelectSilenceOperation(operation, props) {
  const { wavesurferObj, wavesurferObjGlobal } = { ...props };
  const inputs = operation.operation.inputs;
  let oldResultStat = store.getState().projectData.resultStat;

  const originalBuffer = props.wavesurferObj.backend.buffer;
  let insertedBuffer = originalBuffer.getChannelData(0).slice();

  let resultStat = { ...oldResultStat };
  resultStat.speakers_segments = [];
  oldResultStat.speakers_segments.map((segment, i) => {
    let annots = [...segment.annotations];
    resultStat.speakers_segments.push({ ...segment, annotations: annots });
  });

  let currentAnnotations = resultStat.speakers_segments[0].annotations;
  let newAnnotations = [...currentAnnotations];

  inputs.items.forEach((word) => {
    const start = word.start;
    const end = word.end;

    // Calculate the start index and length for inserting silence
    const insertStartIndex = Math.floor(start * originalBuffer.sampleRate);
    const insertSilenceLength = Math.floor(
      (end - start) * originalBuffer.sampleRate,
    );

    // Insert silence into the buffer at the specified index
    insertedBuffer.fill(
      0,
      insertStartIndex,
      insertStartIndex + insertSilenceLength,
    );
  });

  const newBuffer = props.wavesurferObj.backend.ac.createBuffer(
    1,
    insertedBuffer.length,
    originalBuffer.sampleRate,
  );

  newBuffer.copyToChannel(insertedBuffer, 0);

  resultStat.refid = operation.refid;
  resultStat.speakers_src = newBuffer;
  resultStat.speakers_segments[0].annotations = newAnnotations;
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  // Update the waveform display with the inserted buffer
  props.wavesurferObj.loadDecodedBuffer(newBuffer);
  props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
}

export function applyFadeFunction(operation, props, trackType) {
  const { wavesurferObj, wavesurferObjSFX } = { ...props };
  let currentSegment, container;
  const canvas = document.createElement("canvas");
  canvas.classList.add("fade-canvas");
  let topoffset = 0;
  if (trackType === "sfx_segments") {
    currentSegment = wavesurferObjSFX;
    container = document.querySelector("#sfxarea");
    topoffset = 20;
  } else {
    currentSegment = wavesurferObj;
    container = document.querySelector("#waveform");
  }
  const inputs = operation.operation.inputs;
  let oldResultStat = store.getState().projectData.resultStat;
  let startTime = inputs.fadeStartTime;
  let endTime = inputs.fadeEndTime;
  const ctx = canvas.getContext("2d");

  const minPxPerSec = currentSegment.params.minPxPerSec;
  const leftOffset = startTime * minPxPerSec;
  canvas.style.cssText = `position: absolute; top: ${topoffset}vh; left: ${leftOffset}px; pointer-events: none; transition: 0.5s;`;
  container.appendChild(canvas);
  let duration = endTime - startTime;
  const totalWidth = duration * minPxPerSec;
  const containerRect = container.getBoundingClientRect();
  canvas.width = totalWidth;
  canvas.height = containerRect.height;

  const originalBuffer = currentSegment.backend.buffer;
  const sampleRate = originalBuffer.sampleRate;
  const startSample = Math.floor(startTime * sampleRate);
  const endSample = Math.floor(endTime * sampleRate);
  const regionLength = endSample - startSample;
  const regionBuffer = currentSegment.backend.ac.createBuffer(
    originalBuffer.numberOfChannels,
    regionLength,
    sampleRate,
  );
  const copiedBuffer = new Float32Array(
    (endTime - startTime) * originalBuffer.sampleRate,
  );
  originalBuffer.copyFromChannel(
    copiedBuffer,
    0,
    Math.floor(startTime * originalBuffer.sampleRate),
  );
  for (let channel = 0; channel < originalBuffer.numberOfChannels; channel++) {
    const originalData = originalBuffer.getChannelData(channel);
    const regionData = regionBuffer.getChannelData(channel);
    for (let i = 0; i < regionLength; i++) {
      regionData[i] = originalData[startSample + i];
    }
  }

  const offlineContext = new OfflineAudioContext(
    regionBuffer.numberOfChannels,
    regionLength,
    sampleRate,
  );
  const source = offlineContext.createBufferSource();
  source.buffer = regionBuffer;
  const gainNode = offlineContext.createGain();
  source.connect(gainNode);
  gainNode.connect(offlineContext.destination);
  const gain = gainNode.gain;
  gain.setValueAtTime(1, 0);

  const shape = inputs.fade[0].segmentFade.shape;
  const fadeInOut = inputs.fade[0].segmentFade.type;

  const startValue = fadeInOut === "fadein" ? 0.1 : 1;
  const endValue = fadeInOut === "fadein" ? 1 : 0.1;
  switch (shape) {
    case "linear":
      gain.setValueAtTime(startValue, 0);
      gain.linearRampToValueAtTime(endValue, duration);
      break;
    case "exponential":
      gain.setValueAtTime(startValue, 0);
      gain.exponentialRampToValueAtTime(endValue, duration);
      break;
    case "logarithmic":
      gain.setValueAtTime(startValue, 0);
      gain.exponentialRampToValueAtTime(endValue, duration);
      break;
    case "s-curve":
      for (let i = 0; i <= 1; i += 0.01) {
        const t = i * duration;
        let volume;
        if (fadeInOut === "fadein") {
          volume = 0.5 * (1 - Math.cos(Math.PI * i));
        } else {
          volume = 0.5 * (1 + Math.cos(Math.PI * i));
        }
        gain.setValueAtTime(volume, t);
      }
      break;
    default:
      console.error("Invalid fade type");
      return;
  }

  source.start(0);
  offlineContext.startRendering().then(async function (renderedBuffer) {
    const modifiedBuffer = originalBuffer.getChannelData(0).slice();
    modifiedBuffer.set(renderedBuffer.getChannelData(0), startSample);
    const newBuffer = currentSegment.backend.ac.createBuffer(
      1,
      modifiedBuffer.length,
      originalBuffer.sampleRate,
    );

    newBuffer.copyToChannel(modifiedBuffer, 0);

    let resultStat = { ...oldResultStat };
    resultStat.refid = operation.refid;
    if (trackType === "sfx_segments") {
      resultStat.sfx_src = newBuffer;
      store.dispatch(
        wavesurferObjSFXActions.updateWavesurferObjSFX({
          wavesurferObjSFX: props.wavesurferObjSFX,
        }),
      );
    } else {
      props.wavesurferObjGlobal.loadDecodedBuffer(newBuffer);
      resultStat.speakers_src = newBuffer;
      store.dispatch(
        wavesurferObjActions.updateWavesurferObj({
          wavesurferObj: props.wavesurferObj,
        }),
      );
    }
    let fades = [...(resultStat.fades ?? [])];
    let fadeData = { ...inputs.fade[0] };
    fadeData.fadeBuffer = copiedBuffer;
    if (fades.length >= 1) fades.push({ ...fadeData });
    else fades = [{ ...fadeData }];
    resultStat.fades = [...fades];
    store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
    // Update the waveform display with the inserted buffer
    currentSegment.loadDecodedBuffer(newBuffer);
  });
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.beginPath();
  switch (shape) {
    case "linear":
      if (fadeInOut === "fadein") {
        ctx.moveTo(0, canvas.height);
        ctx.lineTo(canvas.width, 0);
      } else {
        ctx.moveTo(0, 0);
        ctx.lineTo(canvas.width, canvas.height);
      }
      break;
    case "exponential":
      for (let i = 0; i <= 1; i += 0.01) {
        const x = i * canvas.width;
        let y;
        if (fadeInOut === "fadein") {
          y = canvas.height - Math.pow(i, 2) * canvas.height;
        } else {
          y = Math.pow(i, 2) * canvas.height;
        }
        ctx.lineTo(x, y);
      }
      break;
    case "logarithmic":
      for (let i = 0; i <= 1; i += 0.01) {
        const x = i * canvas.width;
        let y;
        if (fadeInOut === "fadein") {
          y =
            canvas.height -
            (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
        } else {
          y = (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
        }
        ctx.lineTo(x, y);
      }
      break;
    case "s-curve":
      for (let i = 0; i <= 1; i += 0.01) {
        const x = i * canvas.width;
        let y;
        if (fadeInOut === "fadein") {
          y = canvas.height - 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
        } else {
          y = 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
        }
        ctx.lineTo(x, y);
      }
      break;
    default:
      console.error("Invalid fade type");
      return;
  }

  ctx.strokeStyle = "#E2522B";
  ctx.stroke();
  const deleteButton = document.createElement("div");
  // deleteButton.innerHTML = "&#10006;"; // Unicode for "heavy multiplication X"
  deleteButton.innerHTML = "Delete";
  deleteButton.id = "fade_btn_" + inputs.fade[0].segmentFade.id;
  deleteButton.style.cssText = `
          top: ${topoffset}vh;
          left: ${leftOffset + (totalWidth - (window.innerWidth * 3.25) / 100) / 2}px;
      `;
  container.appendChild(deleteButton);

  deleteButton.addEventListener("mouseenter", (e) => {
    e.preventDefault();

    canvas.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
  });

  deleteButton.addEventListener("mouseleave", (e) => {
    e.preventDefault();

    canvas.style.backgroundColor = "transparent";
  });

  // Add click event listener to the delete button
  deleteButton.addEventListener("click", async (e) => {
    e.stopPropagation();

    if (store.getState().viewEditMode.mode === "view") {
      showStatText("operation not permitted in view mode");
      return;
    }

    await removeFade(inputs.fade[0].segmentFade.id); // Assuming you have a removeFade function
    container.removeChild(deleteButton);
    container.removeChild(canvas);
  });
  //if(document.getElementById('sub-menus-section').style.visibility == 'visible'){
  //    document.getElementById('sub-menus-section').style.visibility = 'hidden'
  //    document.getElementById('control-mode').classList.remove('none')
  //    document.getElementById('control-mode').style.display = 'flex'
  //}else{
  //    document.getElementById('sub-menus-section').style.visibility = 'visible'
  //    document.getElementById('control-mode').classList.remove('d-flex')
  //    document.getElementById('control-mode').style.display = 'none'
  //  }
}
export async function createcustomclass(operation, props) {
  const { wavesurferObj } = { ...props };
  let res = store.getState().projectData.resultStat;
  let resultStat = JSON.parse(JSON.stringify(res));
  resultStat.fades = res.fades ? res.fades : [];
  resultStat.speakers_src = res.speakers_src;
  resultStat.sfx_src = res.sfx_src;
  let trackNum = resultStat.speakers_segments.length;
  const inputs = operation.operation.inputs;
  let type = inputs.trackType;
  let name = inputs.segment.name;
  let newSpeaker;
  if (type === "speakers") {
    let SpeakerSeg = {
      annotations: [],
      backend_filename: "",
      cueIn: 0,
      cueOut: 0,
      end_time: 0,
      fades: [],
      filename: "",
      id: create_UUID(),
      speaker_label: `speakers-${trackNum}`,
      src: [],
      start_time: 0,
      sub_pannings: [],
      sub_volumes: [],
    };
    resultStat.speakers_segments.push(SpeakerSeg);
    newSpeaker = {
      customClass: `speakers-${Object.keys(resultStat.speakers).length}`,
      gain: 1,
      id: 0,
      img:
        "https://ui-avatars.com/api/?name=" +
        name +
        "&background=BB417C&rounded=true&size=31&color=fff",
      name,
      panning: 0,
      tracktype: type,
    };

    resultStat.speakers[`speakers-${Object.keys(resultStat.speakers).length}`] =
      newSpeaker;
  } else {
    newSpeaker = {
      annotations: [],
      cueIn: 0,
      cueOut: wavesurferObj.getDuration(),
      end_time: wavesurferObj.getDuration(),
      fades: [],
      filename: type,
      id: create_UUID(),
      speaker_label: `sfx-${resultStat.sfx_segments.length}`,
      start_time: 0,
      sub_pannings: [],
      sub_volumes: [],
    };
    resultStat.sfx_segments.push(newSpeaker);
  }
  resultStat.refid = operation.refid;
  store.dispatch(
    projectDataSliceActions.projectReducerData({
      resultStat,
    }),
  );
  loadSlickSliders(resultStat);
}
async function removeFades(operation, props) {
  let fadeid = operation.operation.inputs.fadeid;
  let startTime, endTime, trackType;
  let resultStat = store.getState().projectData.resultStat;
  let fadeToRemove;

  resultStat.fades.forEach((fade) => {
    if (fade.segmentFade.id === fadeid) {
      startTime = fade.segmentFade.start;
      endTime = fade.segmentFade.end;
      trackType = fade.segmentFade.trackType;
      fadeToRemove = fade;
    }
  });

  document.getElementById("fade_btn_" + fadeid).remove();

  let currentsegment =
    trackType === "sfx_segments" ? props.wavesurferObjSFX : props.wavesurferObj;

  const originalBuffer = currentsegment.backend.buffer;
  const sampleRate = originalBuffer.sampleRate;
  const startSample = Math.floor(startTime * sampleRate);
  const endSample = Math.floor(endTime * sampleRate);

  // Create a new buffer with the same length as the original
  const newBuffer = currentsegment.backend.ac.createBuffer(
    1,
    originalBuffer.length,
    originalBuffer.sampleRate,
  );

  // Copy the original buffer
  newBuffer.copyToChannel(originalBuffer.getChannelData(0), 0);

  // Replace the faded portion with the original (unfaded) audio from fadeBuffer
  if (fadeToRemove && fadeToRemove.fadeBuffer) {
    newBuffer.copyToChannel(fadeToRemove.fadeBuffer, 0, startSample);
  }

  let newResultStat = JSON.parse(JSON.stringify(resultStat));
  newResultStat.fades = resultStat.fades ? resultStat.fades : [];

  // Update the buffer
  if (trackType === "sfx_segments") {
    newResultStat.sfx_src = newBuffer;
    newResultStat.speakers_src = resultStat.speakers_src;
  } else {
    newResultStat.speakers_src = newBuffer;
    newResultStat.sfx_src = resultStat.sfx_src;
  }
  newResultStat.refid = operation.refid;

  // Remove the fade from the resultStat.fades array
  newResultStat.fades = resultStat.fades.filter(
    (fade) => fade.segmentFade.id !== fadeid,
  );

  // Update the waveform display
  currentsegment.loadDecodedBuffer(newBuffer);

  store.dispatch(
    projectDataSliceActions.projectReducerData({
      resultStat: newResultStat,
    }),
  );

  renderFade(newResultStat);
  return newResultStat;
}

export async function applyOperations(
  operations,
  flags,
  currentFlags = [],
  updateIndexDbOp = false,
  isRestore = false,
  props,
) {
  let jobTitle = null;
  //let restoreProjectData = store.getState().restoreData
  //let resultStat = restoreProjectData.isRestore ? restoreProjectData.restoreResultStat : store.getState().projectData.resultStat
  let resultStat = store.getState().projectData.resultStat;
  let operationsArray = [...operations];
  store.dispatch(frontendApplySliceActions.frontendApplyReducerData(true));
  if (props === undefined) {
    props = store.getState().propsSlice.props;
  }
  for (let i = 0; i < operations.length; i++) {
    const operation = operations[i];
    if (operation.frontend_apply_status === "completed") continue;
    operationsArray.shift();
    //showStatText('applying ' + operation.operation.worker)
    switch (operation.operation.worker) {
      // MAJOR OPERATIONS
      case "cut": {
        let result;
        result = await handleCut(operation, props);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "silence": {
        let result = null;
        //masterTrackType = operation.operation.inputs.customClass.split('-')[0]
        //masterTracks[masterTrackType] = operation.operation.inputs.customClass
        //updateMasterTracks()
        if (operation.operation.inputs.customClass.split("-")[0] === "sfx") {
          //result = await applySilenceSfx(operation.operation.inputs)
        } else {
          result = await applySilence(operation, props);
        }
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        //if (OPStack.includes(operation.alias_id)) { playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: [result], applyParams: operation }) }
        break;
      }
      case "changeSpeaker": {
        await applyChangeSpeaker(operation);
        //const result = await playlist.applyChangeSpeaker(operation.operation.inputs)
        //if (OPStack.includes(operation.alias_id)) { playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: result, applyParams: operation }) }
        break;
      }
      case "paste": {
        let result;
        let trackType;
        if (operation.operation.inputs.customClass.split("-")[0] === "sfx") {
          trackType = "sfx_segments";
          //result = "await playlist.applySfxCut(operation.operation.inputs)"
        } else {
          //const originalBuffer = props.wavesurferObj.backend.buffer;
          trackType = "speakers_segments";
          //result = await applyPasteOperation(operation, isRestore, props)
        }
        result = await applyPasteOperation(
          operation,
          isRestore,
          props,
          trackType,
        );
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "pasteSFX": {
        //if (operation.operation.inputs.trackType && operation.operation.inputs.segment) {
        //    await playlist.creatCustomClass(operation.operation.inputs.trackType)
        //    await playlist.loadSegments([operation.operation.inputs.segment])
        //    await updateSlickSliders()
        //    const trackIndex = await playlist.getTrackIndex(operation.operation.inputs.segment.id)
        //    playlist.setActiveTrack(playlist.tracks[trackIndex])
        //    masterTrackType = operation.operation.inputs.trackType
        //    masterTracks[masterTrackType] = operation.operation.inputs.segment.speaker_label
        //    updateMasterTracks()
        //}
        //await playlist.setupClipBoard(operation.operation.inputs.tracks, true)
        //masterTrackType = 'sfx'
        //masterTracks[masterTrackType] = operation.operation.inputs.customClass
        //updateMasterTracks()
        //const activeTrack = playlist.getTrack(operation.operation.inputs.customClass)
        //await playlist.setActiveTrack(activeTrack)
        //const result = await playlist.applyPaste({ ...operation.operation.inputs, adjustFade: true })
        //if (OPStack.includes(operation.alias_id)) { playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: result, applyParams: operation }) }
        //await playlist.clipboard.clear()
        break;
      }
      case "insert": {
        let result;
        let trackType;
        if (operation.operation.inputs.customClass.split("-")[0] === "sfx") {
          //result = "await playlist.applySfxCut(operation.operation.inputs)"
          trackType = "sfx_segments";
        } else {
          //const originalBuffer = props.wavesurferObj.backend.buffer;
          //result = await applyInsertOperation(operation, isRestore, props)
          trackType = "speakers_segments";
        }
        result = await applyInsertOperation(
          operation,
          isRestore,
          props,
          trackType,
        );
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "resizeSfx": {
        //const affectedTracks = []
        //for (let j = 0; j < playlist.tracks.length; j++) {
        //    if (playlist.tracks[j].customClass === operation.operation.inputs.customClass &&
        //            playlist.tracks[j].startTime === operation.operation.inputs.startTime &&
        //            playlist.tracks[j].endTime === operation.operation.inputs.endTime) {
        //        const affectedKeys = ['cueIn', 'cueOut', 'annotCueIn', 'annotCueOut', 'annotations', 'buffer', 'fades']
        //        const affectedTrack = playlist.createEmptyTrack(playlist.tracks[j], affectedKeys)
        //        affectedTracks.push({ affectedTrack, affectedKeys, affectedTrackId: playlist.tracks[j].id, trackRemoved: false, trackInserted: false })
        //        playlist.tracks[j].setCues(operation.operation.inputs.cueIn, operation.operation.inputs.cueOut)
        //        playlist.tracks[j].setAnnotCues(operation.operation.inputs.cueIn, operation.operation.inputs.cueOut)
        //        playlist.tracks[j].annotations[0].start_time = operation.operation.inputs.startTime + operation.operation.inputs.cueIn
        //        playlist.tracks[j].annotations[0].end_time = operation.operation.inputs.startTime + operation.operation.inputs.cueOut
        //        playlist.tracks[j].setStringAnnotations(playlist.tracks[j].annotations)
        //        playlist.tracks[j].fades = playlist.tracks[j].fades.filter(fade => {
        //            if(fade.start >= playlist.tracks[j].cueIn
        //                && fade.end <= playlist.tracks[j].cueOut)
        //                return true
        //            else
        //                return false
        //        })

        //        playlist.tracks[j].calculatePeaks(playlist.samplesPerPixel, playlist.sampleRate)
        //        playlist.renderAnnotations()
        //    }
        //}
        //if (OPStack.includes(operation.alias_id)) {
        //    const undoStack = { operation: 'resizeSfx', tracks: affectedTracks, id: create_UUID() }
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: [undoStack], applyParams: operation })
        //}
        break;
      }
      case "resizeSpeakerAnnotation": {
        //const affectedTracks = []
        //for (let j = 0; j < playlist.tracks.length; j++) {
        //    if (playlist.tracks[j].customClass === operation.operation.inputs.customClass &&
        //            playlist.tracks[j].startTime === operation.operation.inputs.startTime &&
        //            playlist.tracks[j].endTime === operation.operation.inputs.endTime) {

        //        let resizedAnnot = operation.operation.inputs.tracks[0].annotations[0]
        //        let resizedAnnotIndex = playlist.tracks[j].annotations.findIndex(
        //            annot => (annot.id === resizedAnnot.id ||
        //                    (annot.content === resizedAnnot.content &&
        //                        (annot.start_time === resizedAnnot.start_time || annot.end_time === resizedAnnot.end_time) &&
        //                    annot.confidence == resizedAnnot.confidence)))
        //        const affectedKeys = ['cueIn', 'cueOut', 'annotCueIn', 'annotCueOut', 'annotations', 'buffer']
        //        const affectedTrack = playlist.createEmptyTrack(playlist.tracks[j], affectedKeys)
        //        affectedTracks.push({
        //            affectedTrack,
        //            affectedKeys,
        //            affectedTrackId: playlist.tracks[j].id,
        //            trackRemoved: false,
        //            trackInserted: false
        //        })
        //        if (resizedAnnotIndex > -1) {
        //            playlist.tracks[j].annotations[resizedAnnotIndex].start_time = resizedAnnot.start_time
        //            playlist.tracks[j].annotations[resizedAnnotIndex].end_time = resizedAnnot.end_time
        //            playlist.tracks[j].setStringAnnotations(playlist.tracks[j].annotations)
        //            playlist.tracks[j].calculatePeaks(playlist.samplesPerPixel, playlist.sampleRate)
        //            playlist.renderAnnotations()
        //        }
        //    }
        //}
        //if (OPStack.includes(operation.alias_id)) {
        //    const undoStack = { operation: 'resizeSfx', tracks: affectedTracks, id: create_UUID() }
        //    playlist.undoStack.push({
        //        refid: operation.refid,
        //        parentRefid: playlist.getJobHead(),
        //        operations: [undoStack],
        //        applyParams: operation
        //    })
        //}
        break;
      }
      // MINOR OPERATIONS˚–
      case "createCustomClass": {
        await createcustomclass(operation, props);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "volumeChange": {
        let resultsStat = { ...store.getState().projectData.resultStat };
        if (operation.operation.inputs.customClass == "sfx") {
          props.wavesurferObjSFX.setVolume(operation.operation.inputs.value);
        } else {
          props.wavesurferObj.setVolume(operation.operation.inputs.value);
        }
        resultsStat.refid = operation.refid;
        store.dispatch(
          projectDataSliceActions.projectReducerData({ resultStat }),
        );
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        //const oldValue = playlist.getTrackKeyValue(String(operation.operation.inputs.customClass), 'gain')
        //const undo = [{ operation: 'volumeChange', customClass: { customClass: String(operation.operation.inputs.customClass), affectedKey: 'gain', value: oldValue }, id: create_UUID() }]
        //await playlist.updateTrackKeyValue(operation.operation.inputs.customClass, 'gain', operation.operation.inputs.value)
        //if (masterTracks[operation.operation.inputs.customClass.split('-')[0]] === operation.operation.inputs.customClass) { $('.' + operation.operation.inputs.customClass.split('-')[0] + '-vol').text((operation.operation.inputs.value * 10).toFixed(1)) }
        //await playlist.updateCustomClass(operation.operation.inputs.customClass, 'gain', operation.operation.inputs.value)
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: undo, applyParams: operation })
        //}
        break;
      }
      case "subVolume": {
        await applySubVolume(operation, props);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "subPanning": {
        //const res = await playlist.applySubPanning(operation.operation.inputs)
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: res, applyParams: operation })
        //}
        break;
      }
      case "stereoPanning": {
        //const oldValue = playlist.getTrackKeyValue(String(operation.operation.inputs.customClass), 'panning')
        //const undo = [{ operation: 'stereoPanning', customClass: { customClass: String(operation.operation.inputs.customClass), affectedKey: 'stereoPan', value: oldValue }, id: create_UUID() }]
        //await playlist.updateTrackKeyValue(operation.operation.inputs.customClass, 'stereoPan', operation.operation.inputs.value)
        //await playlist.updateCustomClass(operation.operation.inputs.customClass, 'panning', operation.operation.inputs.value)
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: undo, applyParams: operation })
        //}
        break;
      }
      case "deleteCustomClass": {
        await handleCut(
          operation,
          props,
          null,
          null,
          operation.operation.inputs.customClass,
        );
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "cutDeleteCustomClass": {
        //await applyCutDeleteCustomClassOperation (operation, operation.operation.inputs)
        break;
      }
      case "updateTranscript": {
        await applyTranscript(operation);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "addAnnotation": {
        await applyTranscript(operation);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
        //const undo = [{
        //    affectedTrack: playlist.createEmptyTrack(playlist.tracks[operation.operation.inputs.trackIndex], ['annotations']),
        //    affectedKeys: ['annotations'],
        //    affectedTrackId: playlist.tracks[operation.operation.inputs.trackIndex].id,
        //    trackRemoved: false,
        //    trackInserted: false
        //}]
        //await playlist.addAnnotation(playlist.tracks[operation.operation.inputs.trackIndex].id, operation.operation.inputs.newAnnotIndex, operation.operation.inputs.newAnnotation)
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: [{ operation: 'addAnnotation', tracks: undo, id: create_UUID() }], applyParams: operation })
        //}
        break;
      }
      case "editTitle": {
        let result = await applyEditTitleForFrontendApply(operation);
        //const oldTitle = $('#projectname').text()
        //await updateProjectTitle(operation.operation.inputs.title)
        ////$('#projectname').text(operation.operation.inputs.title)   //commented due to implementation of #2351
        //jobTitle=operation.operation.inputs.title
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.refid, parentRefid: playlist.getJobHead(), operations: [{ operation: 'projectName', name: oldTitle }], applyParams: operation })
        //}
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "editTrackInfo": {
        let inputs = operation.operation.inputs;
        let resultStat = { ...store.getState().projectData.resultStat };
        let key = inputs.customClass;
        if (inputs.customClass.split("-")[0] === "speakers")
          resultStat = {
            ...resultStat,
            speakers: {
              ...resultStat.speakers,
              [key]: {
                ...resultStat.speakers[key],
                name: inputs.name,
              },
            },
          };
        else
          resultStat = {
            ...resultStat,
            sfx: {
              ...resultStat.sfx,
              [key]: {
                ...resultStat.sfx[key],
                name: inputs.name,
              },
            },
          };
        resultStat.refid = operation.refid;
        loadSlickSliders(resultStat);
        store.dispatch(
          projectDataSliceActions.projectReducerData({ resultStat }),
        );
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "fades": {
        let trackType;
        if (operation.operation.inputs.customClass === "sfx_segments") {
          trackType = "sfx_segments";
        } else {
          trackType = "speakers_segments";
        }
        const res = await applyFadeFunction(operation, props, trackType);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "removeFades": {
        await removeFades(operation, props);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "replaceAnnot": {
        await applyReplaceAnnot(operation, props);

        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "restore": {
        // const refid = operation.operation.inputs.refid;
        // if (refid === playlist.jobhead) break;

        // const res = await checkForRestorePoint(refid);
        // if (res) {
        //   await jumpToRestorePoint(refid);
        //   showStatText("restored");
        // } else {
        //   await restoreHistory(refid, true);
        // }
        break;
      }
      // MULTISELECT OPERATIONS
      case "multiselect-cut": {
        let result;
        if (operation.operation.inputs.trackType === "sfx") {
          result = "await playlist.applySfxCut(operation.operation.inputs)";
        } else {
          //const originalBuffer = props.wavesurferObj.backend.buffer;
          result = await handleCut(
            operation,
            props,
            null,
            operation.operation.inputs.items,
          );
        }
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "long-gap-removal": {
        await handleCut(operation, props, operation.operation.inputs.items);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "multiselect-silence": {
        let result;
        if (operation.operation.inputs.trackType === "sfx") {
          result = "await playlist.applySfxCut(operation.operation.inputs)";
        } else {
          result = await applyMultiSelectSilenceOperation(operation, props);
        }
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
      }
      case "multiselect-changeSpeaker": {
        //const items = operation.operation.inputs.items
        //const undoOperations = []
        //for (let j = 0; j < items.length; j++) {
        //    const inputs = {
        //        startTime: items[j].start,
        //        endTime: items[j].end,
        //        selectedCustomClasses: operation.operation.inputs.selectedCustomClasses,
        //        customClass: operation.operation.inputs.customClass
        //    }
        //    const res = await playlist.applyChangeSpeaker(inputs)
        //    undoOperations.unshift(res)
        //}
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.ref_id, parentRefid: playlist.getJobHead(), operations: undoOperations, applyParams: operation })
        //}
        break;
      }
      case "multiselect-replace": {
        applyMultiselectReplace(operation, props);

        //const undoOperations = []
        //for (let j = 0; j < items.length; j++) {
        //    const inputs = {
        //        startTime: items[j].start,
        //        endTime: items[j].end,
        //        searchWord: operation.operation.inputs.searchWord,
        //        replaceWord: operation.operation.inputs.replaceWord,
        //        selectedCustomClasses: operation.operation.inputs.selectedCustomClasses
        //    }
        //    const res = await playlist.applyTranscriptReplace(inputs)
        //    undoOperations.unshift(res)
        //}
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.ref_id, parentRefid: playlist.getJobHead(), operations: undoOperations, applyParams: operation })
        //}
        break;
      }
      case "multiVolumeChange": {
        await applyAutoLevel(operation, props);
        await updateIndexedDBoperation(
          operation.refid,
          "frontend_apply_status",
          "completed",
        );
        break;
        //const items = operation.operation.inputs.items
        //const undoOperations = []
        //items.forEach(async item => {
        //    const value = item.value
        //    if(item.customClass) {
        //        // it's gain change
        //        const customClass = item.customClass
        //        const oldGain = playlist.getTrackKeyValue(item.customClass, 'gain')
        //        await playlist.updateTrackKeyValue(customClass, 'gain', value)
        //        // what is masterTracks ??
        //        if (masterTracks[customClass.split('-')[0]] === customClass) {
        //            updateVolumeText(customClass.split('-')[0], value)
        //        }
        //        await playlist.updateCustomClass(customClass, 'gain', value)
        //        undoOperations.unshift({
        //            operation: 'volumeChange',
        //            customClass: {
        //                customClass: customClass,
        //                affectedKey: 'gain',
        //                value: oldGain
        //            },
        //            id: create_UUID()
        //        })
        //    }
        //    else {
        //        // it's gain reference change
        //        const oldGainRef = playlist.gainReference
        //        playlist.gainReference = value
        //        // UI Stuffs, these could be separated
        //        if(playlist.activeTrack && playlist.activeTrack.customClass) {
        //            updateVolumeText(playlist.activeTrack.customClass.split('-')[0], playlist.activeTrack.gain)
        //        }
        //        undoOperations.unshift({
        //            operation: 'gainReferenceChange',
        //            customClass: {
        //                affectedKey: 'gainReference',
        //                value: oldGainRef
        //            },
        //            id: create_UUID()
        //        })
        //    }
        //})
        //// below portion would remain same as others?
        //if (OPStack.includes(operation.alias_id)) {
        //    playlist.undoStack.push({ refid: operation.ref_id, parentRefid: playlist.getJobHead(), operations: undoOperations, applyParams: operation })
        //}
        break;
      }
      case "dragAndDropSFX": {
        //isSFXDraggedAndDropped = true
        //await applyCutDeleteCustomClassOperation (operation, operation.operation.inputs.items[0])
        //await applyPasteOperation(operation, operation.operation.inputs.items[1], isRestore)
        //isSFXDraggedAndDropped = false

        //const mergedCutPasteApplyOperations = [...pasteSFXUndoOperations, ...cutSFXUndoOperations]
        //if(OPStack.includes(operation.alias_id)) { playlist.undoStack.push({refid: operation.refid, parentRefid: playlist.getJobHead(), operations: mergedCutPasteApplyOperations, applyParams: operation})}
        break;
      }
      default:
        break;
    }
    if (updateIndexDbOp && operationsArray.length === 0) {
      try {
        await updateResultstatByJobname(resultStat.jobname, operation.refid);
        // await updateIndexedDBoperation(operation.refid, "frontend_apply_status", "completed")
      } catch {
        //console.error("frontend apply indexedDB update Failed")
      }
    }
  }
  //if(jobTitle){$('#projectname').text(jobTitle)}
  //else {$('#projectname').text(Koolioai.kooliospace[0].project_title)}
  //playlist.adjustDuration()
  //updateOperationSpace()
  //playlist.clearSelection()
  //playlist.draw(playlist.render())
  //playlist.renderTranscript()
  //drawGlobalView()
  //highlightAnnotWorker.postMessage(playlist.getAnnotations())
  hideplaylistloader();
  return Promise.resolve();
}

export async function applyEditTitleForFrontendApply(operation) {
  const oldTitle = document.getElementById("projectname").textContent;
  // await callUpdateProjectTitle(operation);
  // await updateProjectTitle(operation.operation.inputs.title);
  store.dispatch(
    projectTitleSliceActions.updateProjectTitle({
      env: "workspace",
      projectTitle: operation.operation.inputs.title,
    }),
  ); //changing the title name after resultstat is changed
  let resultStat = store.getState().projectData.resultStat;
  let newResultStat = JSON.parse(JSON.stringify(resultStat));
  newResultStat.fades = resultStat.fades ? resultStat.fades : [];
  newResultStat.speakers_src = resultStat.speakers_src;
  newResultStat.sfx_src = resultStat.sfx_src;
  newResultStat.project_title = operation.operation.inputs.title;
  newResultStat.refid = operation.refid;
  store.dispatch(
    projectDataSliceActions.projectReducerData({ resultStat: newResultStat }),
  );
  // document.getElementById('projectname').textContent = operation.operation.inputs.title; // commented due to implementation of #2351
  // jobTitle = operation.operation.inputs.title
  return;
}
async function applyReplaceAnnot(operation, props) {
  let resultStat = store.getState().projectData.resultStat;
  const newResultStat = JSON.parse(JSON.stringify(resultStat));
  newResultStat.fades = resultStat.fades ? resultStat.fades : [];
  let newannot, toInsertSegment, nestedFileAnnot;
  toInsertSegment = operation.operation.inputs.toInsertSegment;
  let transcriptions = operation.operation.inputs.transcript;
  let currentStartTime = operation.operation.inputs.currentStartTime;
  let nestedFileId = store.getState().nestedFile.nestedFiles[0].id;
  [newannot, nestedFileAnnot] = insertAnnotations(
    toInsertSegment,
    // We are having one annotation in this implementation
    currentStartTime,
    0,
    toInsertSegment,
    false,
    transcriptions,
    nestedFileId,
  );
  if (newResultStat.speakers_segments) {
    newResultStat.speakers_segments.forEach((track, index) => {
      track.annotations = newannot[index];
    });
  }
  newResultStat.refid = operation.refid;
  newResultStat.speakers_src = resultStat.speakers_src;
  newResultStat.sfx_src = resultStat.sfx_src;
  // data.annottrackstack.push(nestedFileAnnot);
  if (document.getElementById(nestedFileAnnot[0].id + "_div")) {
    document.getElementById(nestedFileAnnot[0].id + "_div").remove();
  }

  annotUIshift(newResultStat, props);
  store.dispatch(
    projectDataSliceActions.projectReducerData({ resultStat: newResultStat }),
  );
}

async function applyMultiselectReplace(operation, props) {
  const items = operation.operation.inputs.items;
  const searchWord = operation.operation.inputs.searchWord;
  const replaceText = operation.operation.inputs.replaceWord;
  let resultStat = store.getState().projectData.resultStat;
  let newResultStat = JSON.parse(JSON.stringify(resultStat));
  items.map((word, ind) => {
    let indexID = resultStat.speakers_segments.findIndex((segment) => {
      return segment.annotations.some((annot) => annot.id === word.wordid);
    });

    //change the content in newResultstat
    newResultStat.speakers_segments[indexID].annotations.forEach(
      (annot, index) => {
        if (annot.id === word.wordid) {
          let newAnnot = { ...annot, content: replaceText };
          if (document.getElementById(annot.id + "_div")) {
            document.getElementById(annot.id + "_div").textContent =
              replaceText;
          }
          if (document.getElementById(annot.id + "_span")) {
            document.getElementById(annot.id + "_span").textContent =
              replaceText;
          }
          if (document.getElementById(annot.id + "_tspan")) {
            document.getElementById(annot.id + "_tspan").textContent =
              replaceText;
          }
          newResultStat.speakers_segments[indexID].annotations[index] =
            newAnnot;
        }
      },
    );
  });
  newResultStat.sfx_src = resultStat.sfx_src;
  newResultStat.speakers_src = resultStat.speakers_src;
  annotUIshiftSub(newResultStat, props);
  store.dispatch(
    projectDataSliceActions.projectReducerData({ resultStat: newResultStat }),
  );
}
